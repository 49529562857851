var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgot container" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.resetPassword($event)
          }
        }
      },
      [
        _c("h1", { staticClass: "mb-sm" }, [
          _vm._v("\n      Passwort zurücksetzen\n    ")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Hier kannst du ein neues Passwort festlegen. Am besten gleich notieren!\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "fieldset mt",
            class: { "fieldset-error": _vm.$v.newPass.$error }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.newPass.$model,
                  expression: "$v.newPass.$model"
                }
              ],
              attrs: {
                type: "password",
                autocomplete: "new-password",
                name: "password",
                placeholder: "Neues Passwort"
              },
              domProps: { value: _vm.$v.newPass.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.$v.newPass, "$model", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "password-instructions" }, [
              !_vm.$v.newPass.lower ||
              !_vm.$v.newPass.upper ||
              !_vm.$v.newPass.digit ||
              !_vm.$v.newPass.minLength
                ? _c("span", { staticClass: "text" }, [_vm._v("Mindestens")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.newPass.lower
                ? _c("span", [_vm._v("1 Kleinbuchstabe")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.newPass.upper
                ? _c("span", [_vm._v("1 Großbuchstabe")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.newPass.digit
                ? _c("span", [_vm._v("1 Ziffer")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.newPass.minLength
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.$v.newPass.$params.minLength.min) + " Zeichen"
                    )
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "fieldset",
            class: { "fieldset-error": _vm.$v.passConfirmation.$error }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.passConfirmation.$model,
                  expression: "$v.passConfirmation.$model"
                }
              ],
              attrs: {
                type: "password",
                name: "password-confirmation",
                placeholder: "Passwort wiederholen"
              },
              domProps: { value: _vm.$v.passConfirmation.$model },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.$v.passConfirmation,
                    "$model",
                    $event.target.value
                  )
                }
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("SubmitBtn", {
          attrs: {
            label: "Passwort ändern",
            submitting: _vm.submitting,
            inline: ""
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }