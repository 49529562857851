var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && _vm.user
    ? _c(
        "div",
        { staticClass: "user container" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: _vm.$route.params.route ? _vm.$route.params.route : "/users"
              }
            },
            [_vm._v("\n    Zurück\n  ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page-head mb-lg" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.user.firstName) +
                  " " +
                  _vm._s(_vm.user.lastName) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "status" }, [
              _vm.user.active
                ? _c("span", { staticClass: "active" }, [_vm._v("Aktiviert")])
                : _c("span", [_vm._v("Inaktiv")]),
              _vm._v(" "),
              _c("span", { class: { online: _vm.user.status === "Online" } }, [
                _vm._v(_vm._s(_vm.user.status))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("UserProfile", {
            attrs: { user: _vm.user },
            on: { fetchUser: _vm.fetchUser }
          }),
          _vm._v(" "),
          _vm.isAdmin ? _c("UserBoxes") : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }