<template>
  <div class="servers container">
    <div class="page-head mb">
      <h1 class="title"> 
        Rechnungen
        <span v-if="bills.length">{{ bills.length }}</span>
      </h1>   
      <button
        class="btn"
        @click.prevent="openModal"
      >
        <span class="plus">+ Rechnung erstellen </span>
      </button> 
    </div>
  

    <transition
      name="fade"
      mode="out-in"
    >
      <BillList :bills="bills" />
    </transition>



    <Modal ref="modal">
      <template #content>
        <h2 class="mb">
          Rechnung anlegen
        </h2>
        <form @submit.prevent="addBill">
          <div class="fieldset">
            <label for="name">Rechnungname</label>
            <input
              v-model="form.name"
              type="text"
              name="name"
              placeholder="Name der Rechnung"
            >
          </div>

          <div class="fieldset">
            <label for="capacity">Benutzer</label>
            <v-select
              v-if="users.length" 
              v-model="chosenUser"
              label="firstName" 
              placeholder="Benutzer wählen..."
              :options="users"
              @input="userChosen"
            >
              <template #selected-option="user">
                {{ user.lastName }}, {{ user.firstName }}
                <!-- {{ user.email }} -->
              </template>
              <template #option="user">
                {{ user.lastName }}, {{ user.firstName }}
                <!-- {{ user.email }} -->
              </template>
            </v-select>
          </div>
        
          <div class="fieldset">
            <label for="status">Status</label>
            <input
              v-model="form.status"
              type="text"
              name="status"
              placeholder="Status der Rechnung"
            >
          </div>

          <div v-if="possibleBills">
            <span class="box-title"> Boxen: </span>
            <ul>
              <li 
                v-for="product in possibleBills.products"
                :key="product.boxName"
                class="box-item"
              >
                <span class="box-name">{{ product.boxName }}</span>
                <span class="box-price">{{ convertPrice(product.price) }}</span>

                

                <ul
                  v-if="product.domains.length > 0"
                  class="domain-container"
                >
                  <span class="domain-title">Domains: </span>
                  <li 
                    v-for="domain in product.domains"
                    :key="domain.id"
                    class="domain-item"
                  >
                    <span class="domain-name">{{ domain.name }}</span>
                    <span class="domain-price">{{ convertPrice(domain.price) }}</span>
                  </li>
                </ul>
              </li>
            </ul>
            <span class="box-titel"> Kompletter Preis: {{ convertPrice(possibleBills.totalPrice) }}  </span>
          </div>

          <SubmitBtn
            label="Rechnung anlegen"
            :submitting="submitting"
          />
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'


export default {
  name: 'Bills',
  components: {
    BillList: () => import('../components/BillList'),
    Modal: () => import('../components/Modal'),
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  data() {
    return {
      bills: [],
      loading: false,
      submitting: false,
      loadingBoxes: false,
      chosenUser: '',
      possibleBills : {},
      users: [],
      form: {
        name: '',
        capacity: '',
        ip: '',
        status: 'open',
        token: '',
        url: ''
      }
    }
  },

  computed: {
    ...mapGetters([
      'isAdmin',
      'isCustomerAdmin',
      'box',
      'accountType',
      'id'
    ])
  },

  async created() {
    await this.loadUsers()
    await this.loadBills()
    console.log('[DEBUG]', 'THIS', this)
  },

  mounted() {

  },

  beforeDestroy() {
  },

  methods: {

    async loadActiveProducts () {
      this.loadingBills = true;
      const possibleBills = await this.$axios.get(`/api/payment/read/${this.chosenUser.id}`)
      console.log('[DEBUG]', 'POSSIBLE BILL', possibleBills.data)
      this.possibleBills = possibleBills.data
      this.loadingBills = false;
    },
    
    async loadUsers () {
      const users = await this.$axios.get(`/api/users`)
      this.users = users.data
      console.log('[DEBUG]', '[BILLS]', this.users)

    },

    async loadBills () {
      const bills = await this.$axios.get('/api/payment/list')
      this.bills = bills.data
      this.$notify.success('Rechnungen geladen')
      console.log('[DEBUG]', 'BILLS',  bills.data)
    },

    openModal() {
      this.$refs.modal.open()
    },

    userChosen(user) {
      console.log(user)
      this.chosenUser = user
      this.loadActiveProducts()
    },
    
    closeModal() {
      this.$refs.modal.close()
    },

    convertPrice(price){
      if (!price){
        return price
      }
      return price.toLocaleString('de-DE', {
        style: 'currency', 
        currency: 'EUR', 
        minimumFractionDigits: 2}) 
    },

    addBill(){
      this.$axios.post('/api/payment', {
        name: this.form.name,
        content: this.possibleBills.products,
        amount: this.possibleBills.totalPrice,
        user: this.chosenUser.id,
        status: this.form.status
      }).then((res) => {
        console.log('[DEBUG]', 'ADD BILL', res)
        this.closeModal()
        this.loadBills()
        this.$notify.success('Rechnung angelegt')
      }).catch((error) => {
        console.log(error.response)
      })

    }
  }
}
</script>

<style lang="scss" scoped>


.box-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
}

.box-item{
  // display: flex;
  margin-top:5px;
}

.box-name {
  width: 70%;
}

.domain-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.domain-container{
  margin-top: 10px;
  margin-left: 3px;
}

.domain-item{
  display: flex;
}

.domain-name {
  width: 70%;
}

</style>