<template>
  <div
    v-if="!loading && organisation"
    class="organisation container"
  >
    <router-link
      v-if="isAdmin"
      to="/organisations"
    >
      Zurück
    </router-link>
    <div class="page-head mb-sm">
      <h1 class="title">
        {{ organisation.name }}
      </h1>
      <div class="status">
        <span
          v-if="organisation.active"
          class="active"
        >Aktiviert</span>
        <span v-else>Inaktiv</span>
        <span :class="{'online' : organisation.status === 'Online'}">{{ organisation.status }}</span>
      </div>
    </div>
    <!--<div class="mb-lg access-code">
      <span>Beitritts-Schlüssel:</span> {{ organisation.accessCode }}
    </div>-->
    <OrganisationProfile
      :organisation="organisation"
      @fetchOrganisation="fetchOrganisation"
    />
    <!--<div class="organisation-users">
      <div class="page-head mb-lg">
        <h3 class="title">
          Benutzer
        </h3>
        <div class="actions">
          <SubmitBtn
            label="Neuer Benutzer"
            :submitting="creatingUser"
            @click.native.prevent="openModal"
          />
        </div>
      </div>
      <form
        class="mb-lg"
        @submit.prevent="addUser"
      >
        <div class="fieldset">
          <input
            v-model="form.users"
            type="text"
            placeholder="user1@email.tld, user2@email.tld..."
          >
          <legend class="mt-sm">
            E-Mail-Adresse des Benutzers. Mehrere Adressen durch Komma trennen.
          </legend>
        </div>
        <SubmitBtn
          label="Benutzer hinzufügen"
          :submitting="addingUser"
          :disabled="!form.users"
          inline
          @click.native.prevent="addUser"
        />
      </form>
      <UserList
        v-if="organisation.users && organisation.users.length"
        :users="organisation.users"
      />
      <div
        v-else
        class="info"
      >
        Noch keine Benutzer für dieses Unternehmen vorhanden. Füge bereits registrierte Benutzer hinzu oder erstelle einen Neuen.
      </div>
    </div>-->
    <!-- <UserBoxes /> -->
    <!--<Modal ref="modal">
      <template #content>
        <AddUserForm
          :organisationid="organisation.id"
          @userAdded="userAdded"
        />
      </template>
    </Modal>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Organisation',

  components: {
    OrganisationProfile: () => import('../components/organisations/OrganisationProfile'),
    //UserList: () => import('../components/users/UserList'),
    //SubmitBtn: () => import('../components/SubmitBtn'),
    //Modal: () => import('../components/Modal'),
    //AddUserForm: () => import('../components/users/AddUserForm')
    // UserBoxes: () => import('../components/users/UserBoxes')
  },

  data() {
    return {
      loading: false,
      organisation: {},

      form: {
        users: null
      },

      addingUser: false,
      creatingUser: false
    }
  },

  computed: {
    ...mapGetters([
      'isAdmin',
      'isCustomerAdmin'
    ])
  },

  async created() {
    this.loading = true
    this.fetchOrganisation()
  },

  methods: {
    async fetchOrganisation() {
      const organisation = await this.$axios.get(`api/organisation/${this.$route.params.id}`)
      this.organisation = organisation.data
      this.loading = false
    },

    async addUser() {
      let formData = { addUsers: this.form.users.length ? this.form.users.split(',') : ['this.form.users'] }
      this.addingUser = true
      this.$axios.put(`/api/organisation/${this.$route.params.id}`, formData).then(() => {
        this.fetchOrganisation()
        this.addingUser = false
        this.$notify.success('Benutzer hinzugefügt')
      }).catch(error => {
        console.log(error.response)
        this.addingUser = false
      })
    },

    openModal() {
      this.$refs.modal.open()
    },

    closeModal() {
      this.$refs.modal.close()
    },

    userAdded() {
      this.fetchOrganisation()
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  font-size: 12px;
  display: flex;

  > span {
    padding-bottom: $spacer-xs;
    margin-left: $spacer;
    border-bottom: 2px solid $color-error;
    transition: all .3s;

    &.active,
    &.online {
      border-bottom: 2px solid $color-success;
    }
  }
}

.access-code {
	font-weight: $medium;
	span {
		margin-right: $spacer-xs;
		color: $color-gray;
	}
}
</style>