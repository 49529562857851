var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mail container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Postfächer\n      "),
          _vm.mailboxes && _vm.mailboxes.length && _vm.box
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.mailboxes.length) +
                    "/" +
                    _vm._s(_vm.box.product.mailboxes)
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.accountType === "user" ||
        (_vm.accountType === "organisation" && _vm.isCustomerAdmin) ||
        _vm.isAdmin
          ? _c("div", { staticClass: "actions" }, [
              _vm.box
                ? _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled:
                          _vm.mailboxes.length >= _vm.box.product.mailboxes
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openModal($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "plus" }, [_vm._v("+")]),
                      _vm._v(" Neues Konto\n      ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.box
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: {
                        href: "https://" + _vm.box.server.url + "/webmail",
                        target: "_blank"
                      }
                    },
                    [_vm._v("\n        Webmail\n      ")]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search mb-lg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchQuery,
              expression: "searchQuery"
            }
          ],
          attrs: { type: "text", placeholder: "Mailkonten filtern" },
          domProps: { value: _vm.searchQuery },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchQuery = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.searchQuery
          ? _c(
              "a",
              {
                staticClass: "icon clear",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clearFilter($event)
                  }
                }
              },
              [_c("span"), _c("span")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [
          _vm.loading && !_vm.mailboxes.length
            ? _c("div", { key: "loading", staticClass: "skeleton info" }, [
                _vm._v("\n      Mailkonten werden geladen...\n    ")
              ])
            : _vm.mailboxes.length
            ? _c(
                "div",
                { key: "table", staticClass: "mailboxes-table table" },
                [
                  _c(
                    "div",
                    {
                      key: "head",
                      staticClass: "row head",
                      class: _vm.currentSortDir
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.sort("account")
                            }
                          }
                        },
                        [
                          _vm._v("\n          Konto\n          "),
                          _c("span", { staticClass: "icon sort" }, [
                            _c("svg", { attrs: { viewBox: "0 0 20 10" } }, [
                              _c("path", { attrs: { d: "M1,9 L10,1 L19,9" } })
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.sortedMails, function(mailbox) {
                    return _c("Mailbox", {
                      key: mailbox.id,
                      attrs: { mailbox: mailbox },
                      on: { edit: _vm.edit }
                    })
                  }),
                  _vm._v(" "),
                  _vm.mailboxes.length && _vm.sortedMails < 1
                    ? _c(
                        "div",
                        { key: "no-results", staticClass: "row no-results" },
                        [
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              "\n          Keine Mailkonten gefunden\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _c(
                "div",
                { key: "noresults", staticClass: "info" },
                [
                  _vm.isAdmin || _vm.isCustomerAdmin
                    ? [
                        _vm._v(
                          '\n        Es wurden noch keine Mailkonten für diese Box angelegt. Klicke auf "Hinzufügen", um ein erstes Mailkonto zu erstellen.\n      '
                        )
                      ]
                    : [
                        _vm._v(
                          "\n        Es wurden noch keine Mailkonten für diese Box angelegt.\n      "
                        )
                      ]
                ],
                2
              )
        ]
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.createMailbox($event)
                      }
                    }
                  },
                  [
                    _c("h2", { staticClass: "mb" }, [
                      _vm._v("\n          Mailkonto erstellen\n        ")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldsetgroup address" }, [
                      _c(
                        "div",
                        {
                          staticClass: "fieldset account",
                          class: {
                            "fieldset-error": _vm.$v.form.account.$error
                          }
                        },
                        [
                          _c("label", { attrs: { for: "account" } }, [
                            _vm._v("Account")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.form.account.$model,
                                expression: "$v.form.account.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            attrs: { type: "text", name: "account" },
                            domProps: { value: _vm.$v.form.account.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.form.account,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset at" }, [
                        _vm._v("\n            @\n          ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "fieldset domain",
                          class: {
                            "fieldset-error": _vm.$v.form.mailDomain.$error
                          }
                        },
                        [
                          _c("label", { attrs: { for: "domain" } }, [
                            _vm._v("Domain")
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              name: "domain",
                              label: "name",
                              options: _vm.availableDomains,
                              placeholder: "Domain wählen...",
                              clearable: false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function() {
                                  return [
                                    _c(
                                      "legend",
                                      [
                                        _vm._v(
                                          "\n                  Keine Domains gefunden"
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          { attrs: { to: "/domains" } },
                                          [
                                            _vm._v(
                                              "\n                    Neue Domain anlegen\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.$v.form.mailDomain.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.form.mailDomain,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.mailDomain.$model"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset alias" }, [
                      _c("label", { attrs: { for: "alias" } }, [
                        _vm._v("Aliase hinzufügen (optional)")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim.lazy",
                            value: _vm.form.alias,
                            expression: "form.alias",
                            modifiers: { trim: true, lazy: true }
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "alias",
                          placeholder: "alias1, alias2, alias3"
                        },
                        domProps: { value: _vm.form.alias },
                        on: {
                          change: function($event) {
                            _vm.$set(
                              _vm.form,
                              "alias",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset margin-bot" }, [
                      _c(
                        "label",
                        { attrs: { for: "forward" } },
                        [
                          _vm._v("Weiterleitungen "),
                          !_vm.form.forwardOnly
                            ? [_vm._v("(Optional)")]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.forward,
                            expression: "form.forward",
                            modifiers: { trim: true }
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "forward",
                          autocomplete: "off",
                          placeholder: "mail@domain1.de, mail@domain2.de, usw."
                        },
                        domProps: { value: _vm.form.forward },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "forward",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.form.forwardOnly
                        ? _c("legend", { staticClass: "mt-sm" }, [
                            _vm._v(
                              "\n            An welche Adresse(n) sollen Nachrichten weitergeleitet werden.\n          "
                            )
                          ])
                        : _c("legend", { staticClass: "mt-sm" }, [
                            _vm._v(
                              "\n            Legen Sie hier fest, an welche Adresse(n) die empfangenen Nachrichten dieses Postfachs zusätzlich gesendet werden sollen.\n          "
                            )
                          ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset password",
                        class: { "fieldset-error": _vm.$v.form.password.$error }
                      },
                      [
                        _c("label", { attrs: { for: "password" } }, [
                          _vm._v("Passwort")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.form.password.$model,
                              expression: "$v.form.password.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "password",
                            autocomplete: "new-password",
                            name: "password",
                            placeholder: "Passwort"
                          },
                          domProps: { value: _vm.$v.form.password.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.password,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "password-instructions" }, [
                          !_vm.$v.form.password.lower ||
                          !_vm.$v.form.password.upper ||
                          !_vm.$v.form.password.digit ||
                          !_vm.$v.form.password.minLength
                            ? _c("span", { staticClass: "text" }, [
                                _vm._v("Mindestens")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.lower
                            ? _c("span", [_vm._v("1 Kleinbuchstabe")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.upper
                            ? _c("span", [_vm._v("1 Großbuchstabe")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.digit
                            ? _c("span", [_vm._v("1 Ziffer")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.minLength
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$v.form.password.$params.minLength.min
                                  ) + " Zeichen"
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset margin-bot",
                        class: {
                          "fieldset-error": _vm.$v.form.repeatPassword.$error
                        }
                      },
                      [
                        _c("label", { attrs: { for: "repeat-password" } }, [
                          _vm._v("Passwort wiederholen")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.form.repeatPassword.$model,
                              expression: "$v.form.repeatPassword.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "password",
                            autocomplete: "new-password",
                            name: "repeat-password",
                            placeholder: "Passwort wiederholen"
                          },
                          domProps: {
                            value: _vm.$v.form.repeatPassword.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.repeatPassword,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("span", [_vm._v("Nur Weiterleitung")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.forwardOnly,
                              expression: "form.forwardOnly"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.forwardOnly)
                              ? _vm._i(_vm.form.forwardOnly, null) > -1
                              : _vm.form.forwardOnly
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.forwardOnly,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "forwardOnly",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "forwardOnly",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "forwardOnly", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("span", [_vm._v("Automatische Antwort")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.isAutoreply,
                              expression: "form.isAutoreply"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.isAutoreply)
                              ? _vm._i(_vm.form.isAutoreply, null) > -1
                              : _vm.form.isAutoreply
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.isAutoreply,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "isAutoreply",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "isAutoreply",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "isAutoreply", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.isAutoreply,
                            expression: "form.isAutoreply"
                          }
                        ]
                      },
                      [
                        _c("editor", {
                          attrs: {
                            "api-key":
                              "0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd",
                            init: {
                              menubar: false,
                              branding: false,
                              content_style: "body { font-size: 13px; }",
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount"
                              ],
                              toolbar:
                                "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright | fullscreen | link image |  bullist numlist outdent indent | removeformat | help | code"
                            }
                          },
                          model: {
                            value: _vm.form.autoreply,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "autoreply", $$v)
                            },
                            expression: "form.autoreply"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      staticClass: "mt",
                      attrs: {
                        label:
                          _vm.form.type === "Weiterleitung"
                            ? "Weiterleitung anlegen"
                            : "Postfach anlegen",
                        submitting: _vm.submitting,
                        disabled: _vm.$v.form.$invalid
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createMailbox($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Modal", {
        ref: "edit",
        on: { modalClosed: _vm.modalClosed },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "form",
                  {
                    staticClass: "update",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.updateMailbox($event)
                      }
                    }
                  },
                  [
                    _c("h2", [_vm._v("Mail-Konto bearbeiten")]),
                    _vm._v(" "),
                    _c("h3", { staticClass: "mb" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.currentMailbox.name) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset alias" }, [
                      _c("label", { attrs: { for: "alias" } }, [
                        _vm._v("Aliase")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.update.alias,
                            expression: "update.alias",
                            modifiers: { trim: true }
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "alias",
                          placeholder: "alias1, alias2, alias3"
                        },
                        domProps: { value: _vm.update.alias },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.update,
                              "alias",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset margin-bot" }, [
                      _c("label", { attrs: { for: "forward" } }, [
                        _vm._v("Weiterleitungen")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.update.forward,
                            expression: "update.forward",
                            modifiers: { trim: true }
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "forward",
                          placeholder: "mail@domain1.de, mail@domain2.de, usw."
                        },
                        domProps: { value: _vm.update.forward },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.update,
                              "forward",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.update.forwardOnly
                        ? _c("legend", { staticClass: "mt-sm" }, [
                            _vm._v(
                              "\n            An welche Adresse(n) sollen empfangene Nachrichten weitergeleitet werden. (E-Mails werden nicht im eigenen Postfach gespeichert.)\n          "
                            )
                          ])
                        : _c("legend", { staticClass: "mt-sm" }, [
                            _vm._v(
                              "\n            Legen Sie hier fest, an welche Adresse(n) die empfangenen Nachrichten dieses Postfachs zusätzlich gesendet werden sollen.\n          "
                            )
                          ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset password",
                        class: {
                          "fieldset-error": _vm.$v.update.password.$error
                        }
                      },
                      [
                        _c("label", { attrs: { for: "password" } }, [
                          _vm._v("Passwort ändern")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.update.password.$model,
                              expression: "$v.update.password.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "password",
                            autocomplete: "new-password",
                            name: "password",
                            placeholder: "Neues Passwort"
                          },
                          domProps: { value: _vm.$v.update.password.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.update.password,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "password-instructions" }, [
                          !_vm.$v.update.password.lower ||
                          !_vm.$v.update.password.upper ||
                          !_vm.$v.update.password.digit ||
                          !_vm.$v.update.password.minLength
                            ? _c("span", { staticClass: "text" }, [
                                _vm._v("Mindestens")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.update.password.lower
                            ? _c("span", [_vm._v("1 Kleinbuchstabe")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.update.password.upper
                            ? _c("span", [_vm._v("1 Großbuchstabe")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.update.password.digit
                            ? _c("span", [_vm._v("1 Ziffer")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.update.password.minLength
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$v.update.password.$params.minLength.min
                                  ) + " Zeichen"
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset margin-bot",
                        class: {
                          "fieldset-error": _vm.$v.update.repeatPassword.$error
                        }
                      },
                      [
                        _c("label", { attrs: { for: "repeat-password" } }, [
                          _vm._v("Passwort wiederholen")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.update.repeatPassword.$model,
                              expression: "$v.update.repeatPassword.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "password",
                            autocomplete: "new-password",
                            name: "repeat-password",
                            placeholder: "Passwort wiederholen"
                          },
                          domProps: {
                            value: _vm.$v.update.repeatPassword.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.update.repeatPassword,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("span", [_vm._v("Nur Weiterleitung")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.update.forwardOnly,
                              expression: "update.forwardOnly"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.update.forwardOnly)
                              ? _vm._i(_vm.update.forwardOnly, null) > -1
                              : _vm.update.forwardOnly
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.update.forwardOnly,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.update,
                                      "forwardOnly",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.update,
                                      "forwardOnly",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.update, "forwardOnly", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("span", [_vm._v("Automatische Antwort")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.update.isAutoreply,
                              expression: "update.isAutoreply"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.update.isAutoreply)
                              ? _vm._i(_vm.update.isAutoreply, null) > -1
                              : _vm.update.isAutoreply
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.update.isAutoreply,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.update,
                                      "isAutoreply",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.update,
                                      "isAutoreply",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.update, "isAutoreply", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.update.isAutoreply,
                            expression: "update.isAutoreply"
                          }
                        ]
                      },
                      [
                        _c("editor", {
                          attrs: {
                            "api-key":
                              "0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd",
                            init: {
                              menubar: false,
                              branding: false,
                              content_style: "body { font-size: 13px; }",
                              block_formats:
                                "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount"
                              ],
                              toolbar:
                                "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright | fullscreen | link image |  bullist numlist outdent indent | removeformat | help | code"
                            }
                          },
                          model: {
                            value: _vm.update.autoreply,
                            callback: function($$v) {
                              _vm.$set(_vm.update, "autoreply", $$v)
                            },
                            expression: "update.autoreply"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      staticClass: "mt",
                      attrs: {
                        label: "Änderungen speichern",
                        submitting: _vm.submitting
                      }
                    }),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      staticClass: "mt-sm",
                      attrs: {
                        red: "",
                        label: "Postfach löschen",
                        submitting: _vm.deleting
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteMailbox($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Dialog", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }