<template>
  <div class="users container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Benutzer
      </h1>
      <div class="actions">
        <button
          class="btn"
          @click.prevent="openModal"
        >
          <span class="plus">+</span> Hinzufügen
        </button>
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="loading && !users.length"
        class="skeleton info"
      >
        Benutzer werden geladen...
      </div>
      <UserList
        v-else-if="users.length"
        :users="users"
      />
      <div
        v-else
        class="info"
      >
        Noch keine Benutzer vorhanden. Klicke auf "Hinzufügen", um einen ersten Benutzer zu erstellen.
      </div>
    </transition>

    <Modal ref="modal">
      <template #content>
        <AddUserForm @userAdded="userAdded" />
      </template>
    </Modal>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
export default {
  name: 'Users',

  components: {
    Modal: () => import('../components/Modal'),
    Dialog: () => import('../components/Dialog'),
    UserList: () => import('../components/users/UserList'),
    AddUserForm: () => import('../components/users/AddUserForm')
  },

  data() {
    return {
      loading: false,
      users: []
    }
  },

  created() {
    this.loadUsers()
  },

  methods: {
    async loadUsers() {
      this.loading = true
      try{
        const users = await this.$axios.get('/api/users')
        this.users = users.data
      }catch(error){
        this.$notify.error('Benutzer konnten nicht geladen werden ' + error)
      }
      this.loading = false
    },

    openModal() {
      this.$refs.modal.open()
    },

    closeModal() {
      this.$refs.modal.close()
    },

    userAdded() {
      this.loadUsers()
      this.closeModal()
    }
  }
}
</script>