var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "support-container" }, [
    _c("h1", { staticClass: "support-container-title" }, [
      _vm._v("\n    Ticketübersicht\n  ")
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "support-ticket-creator",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.createTicket()
          }
        }
      },
      [
        _c("label", { attrs: { for: "ticketTitle" } }, [_vm._v("Betreff")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.title,
              expression: "form.title"
            }
          ],
          staticClass: "support-ticket-creator-title",
          attrs: { placeholder: "Betreff angeben", type: "text" },
          domProps: { value: _vm.form.title },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "title", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "ticketBody" } }, [_vm._v("Inhalt")]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.body,
              expression: "form.body"
            }
          ],
          staticClass: "support-ticket-creator-body",
          attrs: {
            rows: "10",
            placeholder: "Bitte hier das Problem schildern",
            type: "text"
          },
          domProps: { value: _vm.form.body },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "body", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "contactChannel" } }, [
          _vm._v("Kontaktweg")
        ]),
        _vm._v(" "),
        _c("v-select", {
          staticClass: "support-ticket-creator-select",
          attrs: {
            options: _vm.contactChannels,
            placeholder: "Kontaktweg auswählen",
            clearable: false
          },
          model: {
            value: _vm.form.communicationChannel,
            callback: function($$v) {
              _vm.$set(_vm.form, "communicationChannel", $$v)
            },
            expression: "form.communicationChannel"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "support-ticket-creator-button" },
          [
            _c("SubmitBtn", {
              staticClass: "mb-sm",
              attrs: { label: "Ticket absenden ", submitting: _vm.submitting },
              nativeOn: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.createTicket($event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "support-ticket-container" },
      [
        _c("h2", { staticClass: "support-ticket-title" }, [
          _vm._v("\n      Offene Tickets\n    ")
        ]),
        _vm._v(" "),
        _vm._l(_vm.openTickets, function(openTicket) {
          return _c("ul", { key: openTicket.id }, [
            _c("li", { staticClass: "support-ticket-item" }, [
              _c("span", { staticClass: "support-ticket-item-title" }, [
                _vm._v(_vm._s(openTicket.Title))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "support-ticket-item-body" }, [
                _vm._v(_vm._s(openTicket.body))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "support-ticket-item-link",
                  attrs: {
                    href:
                      "https://ks4.ciab.io/otobo/index.pl?Action=AgentTicketZoom;TicketID=" +
                      openTicket.TicketID
                  }
                },
                [_vm._v(" Bring mich zu Otobo")]
              )
            ])
          ])
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "support-ticket-container" },
      [
        _c("h2", { staticClass: "support-ticket-title" }, [
          _vm._v("\n      Geschlossene Tickets\n    ")
        ]),
        _vm._v(" "),
        _vm._l(_vm.closedTickets, function(closedTicket) {
          return _c("ul", { key: closedTicket.id }, [
            _c("li", { staticClass: "support-ticket-item" }, [
              _c("span", { staticClass: "support-ticket-item-title" }, [
                _vm._v(_vm._s(closedTicket.Title))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "support-ticket-item-body" }, [
                _vm._v(_vm._s(closedTicket.body))
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "support-ticket-item-link",
                  attrs: {
                    href:
                      "https://ks4.ciab.io/otobo/customer.pl?Action=AgentTicketZoom;TicketID=" +
                      closedTicket.TicketID
                  }
                },
                [_vm._v(" Bring mich zu Otobo ")]
              )
            ])
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }