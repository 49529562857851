var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgot container" }, [
    !_vm.success
      ? _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.forgotPassword($event)
              }
            }
          },
          [
            _c("h1", { staticClass: "mb-sm" }, [
              _vm._v("\n      Passwort vergessen?\n    ")
            ]),
            _vm._v(" "),
            _c(
              "p",
              [
                _vm._v("\n      Doch wieder eingefallen? "),
                _c("router-link", { attrs: { to: "/login" } }, [
                  _vm._v("\n        Zurück zur Anmeldung\n      ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "fieldset mt-lg",
                class: { "fieldset-error": _vm.$v.email.$error }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.email.$model,
                      expression: "$v.email.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: {
                    type: "email",
                    autocomplete: "username",
                    name: "email",
                    placeholder: "E-Mail-Adresse"
                  },
                  domProps: { value: _vm.$v.email.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.email,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("SubmitBtn", {
              attrs: {
                label: "Passwort anfordern",
                submitting: _vm.submitting,
                inline: ""
              }
            })
          ],
          1
        )
      : _c("div", { staticClass: "success" }, [
          _c("h2", { staticClass: "mb" }, [
            _vm._v("\n      Du hast Post...\n    ")
          ]),
          _vm._v(" "),
          _c(
            "p",
            [
              _vm._v("\n      Wir haben eine E-Mail an "),
              _c("strong", [_vm._v(_vm._s(_vm.email))]),
              _vm._v(
                " gesendet. In dieser E-Mail findest du Instruktionen zum Zurücksetzten deines Passworts."
              ),
              _c("br"),
              _c("br"),
              _vm._v("\n      Bei Problemen helfen wir dir gerne unter "),
              _c(
                "a",
                {
                  attrs: {
                    title: "Support per Mail kontaktieren",
                    href: "mailto:" + _vm.companyMail
                  }
                },
                [_vm._v(_vm._s(_vm.companyMail))]
              ),
              _vm._v(" oder per Telefon unter "),
              _c(
                "a",
                {
                  attrs: {
                    title: "Support per Telefon kontaktieren",
                    href: "tel:" + _vm.companyPhone
                  }
                },
                [_vm._v(_vm._s(_vm.companyPhone))]
              ),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "link", attrs: { to: "/login" } },
                [_vm._v("\n        Zurück zum Login\n      ")]
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }