var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "backups container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Backups\n      "),
          _vm.backups.length && _vm.box
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.backups.length) +
                    "/" +
                    _vm._s(_vm.box.product.backups)
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.addBackup($event)
                }
              }
            },
            [
              _c("span", { staticClass: "plus" }, [_vm._v("+")]),
              _vm._v(" Erstellen\n      ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.loading
            ? _c("div", { staticClass: "skeleton info" }, [
                _vm._v("\n      Backups werden geladen...\n    ")
              ])
            : _c("BackupList", {
                attrs: { backups: _vm.backups },
                on: { fetch: _vm.fetchBackups }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }