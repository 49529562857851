var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organisations container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Unternehmen\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openModal($event)
                }
              }
            },
            [
              _c("span", { staticClass: "plus" }, [_vm._v("+")]),
              _vm._v(" Hinzufügen\n      ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search mb-lg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchQuery,
              expression: "searchQuery"
            }
          ],
          attrs: { type: "text", placeholder: "Unternehmen filtern" },
          domProps: { value: _vm.searchQuery },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchQuery = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.searchQuery
          ? _c(
              "a",
              {
                staticClass: "icon clear",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clearFilter($event)
                  }
                }
              },
              [_c("span"), _c("span")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.loading && !_vm.organisations.length
          ? _c("div", { staticClass: "skeleton info" }, [
              _vm._v("\n      Unternehmen werden geladen...\n    ")
            ])
          : _vm.organisations.length
          ? _c(
              "div",
              { staticClass: "organisationslist table" },
              [
                _c(
                  "div",
                  {
                    key: "head",
                    staticClass: "row head",
                    class: _vm.currentSortDir
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "cell",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.sort("name")
                          }
                        }
                      },
                      [
                        _vm._v("\n          Unternehmen\n          "),
                        _c("span", { staticClass: "icon sort" }, [
                          _c("svg", { attrs: { viewBox: "0 0 20 10" } }, [
                            _c("path", { attrs: { d: "M1,9 L10,1 L19,9" } })
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cell",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.sort("email")
                          }
                        }
                      },
                      [
                        _vm._v("\n          E-Mail\n          "),
                        _c("span", { staticClass: "icon sort" }, [
                          _c("svg", { attrs: { viewBox: "0 0 20 10" } }, [
                            _c("path", { attrs: { d: "M1,9 L10,1 L19,9" } })
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "cell align-right" }, [
                      _vm._v("\n          Status\n        ")
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.sortedOrganisations, function(organisation) {
                  return _c(
                    "router-link",
                    {
                      key: organisation.id,
                      staticClass: "row organisation",
                      attrs: {
                        to: {
                          name: "organisations-id",
                          params: { id: organisation.id }
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "cell name overflow" }, [
                        _c("span", { staticClass: "id" }, [
                          _vm._v("ID " + _vm._s(organisation.id))
                        ]),
                        _c("span", [_vm._v(_vm._s(organisation.name))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cell email overflow" }, [
                        _c("span", [_vm._v(_vm._s(organisation.email))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cell status align-right" }, [
                        organisation.active
                          ? _c("span", [_vm._v("Aktiv")])
                          : _c("span", [_vm._v("Inaktiv")])
                      ])
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.sortedOrganisations < 1
                  ? _c(
                      "div",
                      { key: "no-results", staticClass: "row no-results" },
                      [
                        _c("div", { staticClass: "cell" }, [
                          _vm._v(
                            "\n          Keine Unternehmen gefunden\n        "
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              2
            )
          : _c("div", { staticClass: "info" }, [
              _vm._v(
                '\n      Noch keine Unternehmen vorhanden. Klicke auf "Hinzufügen", um ein erstes Unternehmen zu erstellen.\n    '
              )
            ])
      ]),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("h2", { staticClass: "mb" }, [
                  _vm._v("\n        Unternehmen hinzufügen\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "grid",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addOrganisation($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "fieldset col-12" }, [
                      _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "name",
                          placeholder: "Name",
                          required: ""
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "name", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-6" }, [
                      _c("label", { attrs: { for: "street" } }, [
                        _vm._v("Straße & Hausnummer")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.street,
                            expression: "form.street"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "street",
                          placeholder: "Strasse",
                          required: ""
                        },
                        domProps: { value: _vm.form.street },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "street", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-6" }, [
                      _c("label", { attrs: { for: "zip" } }, [_vm._v("PLZ")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.zip,
                            expression: "form.zip"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "zip",
                          placeholder: "PLZ",
                          required: ""
                        },
                        domProps: { value: _vm.form.zip },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "zip", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-6" }, [
                      _c("label", { attrs: { for: "city" } }, [_vm._v("Ort")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.city,
                            expression: "form.city"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "city",
                          placeholder: "Stadt",
                          required: ""
                        },
                        domProps: { value: _vm.form.city },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "city", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset col-6" },
                      [
                        _c("label", { attrs: { for: "country" } }, [
                          _vm._v("Land")
                        ]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            options: _vm.countries,
                            placeholder: "Land",
                            clearable: false
                          },
                          model: {
                            value: _vm.form.country,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "country", $$v)
                            },
                            expression: "form.country"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-12" }, [
                      _c("label", { attrs: { for: "phone" } }, [
                        _vm._v("Telefon")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phone,
                            expression: "form.phone"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "phone",
                          placeholder: "Telefon",
                          required: ""
                        },
                        domProps: { value: _vm.form.phone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "phone", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-12" }, [
                      _c("label", { attrs: { for: "email" } }, [
                        _vm._v("E-Mail")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "email",
                          placeholder: "E-Mail",
                          required: ""
                        },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "email", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-6" }, [
                      _c("label", { attrs: { for: "credit" } }, [
                        _vm._v("Credit")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.credit,
                            expression: "form.credit"
                          }
                        ],
                        attrs: {
                          type: "number",
                          name: "credit",
                          placeholder: "Credit"
                        },
                        domProps: { value: _vm.form.credit },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "credit", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-6" }, [
                      _c("label", [_vm._v("Account")]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        !_vm.form.active
                          ? _c("span", [_vm._v("Deaktiviert")])
                          : _c("span", [_vm._v("Aktiviert")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.active,
                              expression: "form.active"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.active)
                              ? _vm._i(_vm.form.active, null) > -1
                              : _vm.form.active
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.active,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "active",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "active",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "active", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-12" }, [
                      _c("div", { staticClass: "fieldset" }, [
                        _c("label", { attrs: { for: "users" } }, [
                          _vm._v("Benutzer")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.users,
                              expression: "form.users"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "users",
                            placeholder: "Benutzer hinzufügen..."
                          },
                          domProps: { value: _vm.form.users },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "users", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("legend", { staticClass: "mt-sm" }, [
                          _vm._v(
                            "\n              E-Mail-Adresse des Benutzers. Mehrere Adressen mit ',' trennen.\n            "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("SubmitBtn", {
                          attrs: {
                            label: "Unternehmen anlegen",
                            submitting: _vm.submitting
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Dialog", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }