var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      !_vm.$route.meta.guest ? _c("Sidebar") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "transition",
            {
              attrs: { name: "page", mode: "out-in", css: false },
              on: {
                enter: _vm.enter,
                afterEnter: _vm.afterEnter,
                leave: _vm.leave,
                afterLeave: _vm.afterLeave
              }
            },
            [
              _c(
                "keep-alive",
                { attrs: { include: "instances" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }