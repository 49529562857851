<template>
  <div class="forgot container">
    <form
      v-if="!success"
      @submit.prevent="forgotPassword"
    >
      <h1 class="mb-sm">
        Passwort vergessen?
      </h1>
      <p>
        Doch wieder eingefallen? <router-link to="/login">
          Zurück zur Anmeldung
        </router-link>
      </p>
      <div
        class="fieldset mt-lg"
        :class="{'fieldset-error' : $v.email.$error}"
      >
        <input
          v-model.trim="$v.email.$model"
          type="email"
          autocomplete="username"
          name="email"
          placeholder="E-Mail-Adresse"
        >
      </div>
      <SubmitBtn
        label="Passwort anfordern"
        :submitting="submitting"
        inline
      />
    </form>
    <div
      v-else
      class="success"
    >
      <h2 class="mb">
        Du hast Post...
      </h2>
      <p>
        Wir haben eine E-Mail an <strong>{{ email }}</strong> gesendet. In dieser E-Mail findest du Instruktionen zum Zurücksetzten deines Passworts.<br><br>
        Bei Problemen helfen wir dir gerne unter <a
          title="Support per Mail kontaktieren"
          :href="`mailto:${companyMail}`"
        >{{ companyMail }}</a> oder per Telefon unter <a
          title="Support per Telefon kontaktieren"
          :href="`tel:${companyPhone}`"
        >{{ companyPhone }}</a>
        <br><br>
        <router-link
          class="link"
          to="/login"
        >
          Zurück zum Login
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Forgot',

  components: {
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  // head: {
  //   title: 'CIAB.IO Passwort vergessen',
  //   meta: [{
  //     hid: 'description',
  //     name: 'description',
  //     content: 'Sie haben Ihr ciab.io Passwort verlegt? Kann ja mal passieren. Aber keine Panik. Hier können Sie Ihr Passwort zurücksetzen und ein neues festlegen.'
  //   }]
  // },

  data() {
    return {
      email: '',
      submitting: false,
      success: false
    }
  },

  validations: {
    email: { required, email }
  },

  computed: {
    ...mapGetters([
      'config'
    ]),

    companyMail() {
      return this.config.email
    },
    companyPhone() {
      return this.config.phone
    }
  },

  // async mounted() {
  //   this.$gsap.fromTo('.reveal', {y: 20, opacity: 0}, {duration: 0.5, delay: 0.2, ease: "power1.out", y: 0, opacity: 1, stagger: 0.2})
  // },

  methods: {
    async forgotPassword() {
      this.$v.$touch()
      if (!this.$v.email.required) {
        this.$notify.error('E-Mail benötigt')
      } else if (!this.$v.email.email) {
        this.$notify.error('Ungültige E-Mail')
      } else {
        this.submitting = true
        this.$axios.post('/auth/reset', {
          email: this.email,
        }).then(() => {
          this.success = true
          this.submitting = false
        }).catch(error => {
          if(error.response.data.error === 'can not find user or otp wrong') {
            this.$notify.error('Adresse nicht vorhanden')
          } else {
            this.$notify.error('Fehlgeschlagen')
          }
          setTimeout(() => {
            this.submitting = false
          }, 1000)
          console.log(error)
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>