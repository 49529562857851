<template>
  <div class="servers container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Server-Übersicht
        <span v-if="servers.length">{{ servers.length }}</span>
      </h1>
      <div class="actions">
        <button
          class="btn"
          @click.prevent="openModal"
        >
          <span class="plus">+</span> Hinzufügen
        </button>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="loading && !servers.length"
        class="skeleton info"
      >
        Server werden geladen...
      </div>
      <ServerList
        v-else-if="servers.length"
        :servers="servers"
        :server-health="serverHealth"
        @openModal="openModal"
      />
      <div
        v-else
        class="info"
      >
        Noch keine Server vorhanden.
      </div>
    </transition>
      
    

    <Modal ref="modal">
      <template #content>
        <h2 class="mb">
          Server hinzufügen
        </h2>
        <form @submit.prevent="addServer">
          <div class="fieldset">
            <label for="name">Servername</label>
            <input
              v-model="form.name"
              type="text"
              name="name"
              placeholder="Name des Servers"
            >
          </div>
          <div class="fieldset">
            <label for="capacity">Benutzer</label>
            <input
              v-model="form.capacity"
              type="number"
              name="capacity"
              placeholder="Max. erlaubte Benutzer"
            >
          </div>
          <div class="fieldset">
            <label for="ip">Server-IP</label>
            <input
              v-model="form.ip"
              type="text"
              name="ip"
              placeholder="IP-Adresse"
            >
          </div>
          <div class="fieldset">
            <label for="url">Server-Url</label>
            <input
              v-model="form.url"
              type="text"
              name="url"
              placeholder="Server-Url"
            >
          </div>
          <div class="fieldset">
            <label for="name">API-Key</label>
            <input
              v-model="form.token"
              type="text"
              name="token"
              placeholder="16-Stelliger Token"
            >
          </div>
          <SubmitBtn
            label="Server anlegen"
            :submitting="submitting"
          />
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'Admin',
  components: {
    ServerList: () => import('../components/servers/ServerList'),
    Modal: () => import('../components/Modal'),
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  data() {
    return {
      loading: false,
      submitting: false,

      servers: [],
      serverHealth: [],
      interval: null,
      form: {
        name: '',
        capacity: '',
        ip: '',
        status: 'Maintenance',
        token: '',
        url: ''
      }
    }
  },

  // computed: {
  //   splittedServerHealth() {
  //     return this.groupBy(this.serverHealth, 'server')
  //   }
  // },

  async created() {
    this.loading = true
    this.fetchServers()
    this.fetchHealth()
  },

  mounted() {
    this.interval = setInterval(async () => {
      this.fetchHealth()
    },90000)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    openModal() {
      this.$refs.modal.open()
    },

    closeModal() {
      this.$refs.modal.close()
    },

    async fetchServers() {
      const servers = await this.$axios.get('/api/servers')
      this.servers = servers.data
      this.loading = false
    },

    async fetchHealth() {
      const serverHealth = await this.$axios.get('/api/servers/health')
      this.serverHealth = serverHealth.data
    },

    async addServer() {
      this.$axios.post('/api/server', {
        name: this.form.name,
        capacity: this.form.capacity,
        ip: this.form.ip,
        status: this.form.status,
        token: this.form.token,
        url: this.form.url
      }).then(() => {
        this.closeModal()
        this.fetchServers()
        this.$notify.success('Server erstellt')
        // this.fetchHealth()
      }).catch((error) => {
        console.log(error.response)
      })
    }
  }
}
</script>