var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instances container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n        Instanzen\n        "),
          _vm.instances && _vm.instances.length
            ? _c("span", [_vm._v(_vm._s(_vm.instances.length))])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.accountType === "user" ||
        (_vm.accountType === "organisation" && _vm.isCustomerAdmin) ||
        _vm.isAdmin
          ? _c(
              "div",
              { staticClass: "actions" },
              [
                _c("SubmitBtn", {
                  attrs: { label: "Hinzufügen", submitting: _vm.submitting },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.openModal($event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [
          _vm.loading && !_vm.instances.length
            ? _c("div", { key: "loading", staticClass: "skeleton" }, [
                _vm._v("\n        Instanzen werden geladen...\n      ")
              ])
            : _vm.instances.length
            ? _c("InstanceList", {
                key: "instances",
                attrs: {
                  box: _vm.box,
                  instances: _vm.instances,
                  domains: _vm.domains
                },
                on: { fetch: _vm.fetchInstances }
              })
            : _c(
                "div",
                { key: "noresults", staticClass: "info" },
                [
                  _vm.isAdmin || _vm.isCustomerAdmin
                    ? [
                        _vm._v(
                          '\n          Noch keine Instanzen für diese Box vorhanden. Klicke auf "Hinzufügen", um eine erste Instanz zu erstellen.\n        '
                        )
                      ]
                    : [
                        _vm._v(
                          "\n          Es wurden noch keine Instanzen für diese Box erstellt.\n        "
                        )
                      ]
                ],
                2
              )
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("h2", { staticClass: "mb" }, [
                  _vm._v("\n          Instanz hinzufügen\n        ")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addInstance($event)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "fieldset",
                        class: { "fieldset-error": _vm.$v.form.name.$error }
                      },
                      [
                        _c("label", { attrs: { for: "name" } }, [
                          _vm._v("Instanzname")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.form.name.$model,
                              expression: "$v.form.name.$model"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "name",
                            placeholder: "Z.b. Wordpress 1"
                          },
                          domProps: { value: _vm.$v.form.name.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.name,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("label", [_vm._v("Haupt-Domain")]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            options: _vm.availableDomains,
                            placeholder: "Domain wählen",
                            clearable: true,
                            label: "name",
                            autocomplete: "off"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n                Keine Domain(s) gefunden. "
                                  ),
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/domains" } },
                                    [
                                      _vm._v(
                                        "\n                  Domain anlegen\n                "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.form.domain,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "domain", $$v)
                            },
                            expression: "form.domain"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("label", [_vm._v("Weitere Domains")]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            options: _vm.availableAliases,
                            placeholder: "Domain(s) wählen",
                            reduce: function(domain) {
                              return domain.name
                            },
                            multiple: true,
                            clearable: true,
                            label: "name",
                            autocomplete: "off",
                            disabled: !_vm.form.domain
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n                Keine Domain(s) gefunden. "
                                  ),
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/domains" } },
                                    [
                                      _vm._v(
                                        "\n                  Domain anlegen\n                "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.form.aliases,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "aliases", $$v)
                            },
                            expression: "form.aliases"
                          }
                        }),
                        _vm._v(" "),
                        _c("legend", { staticClass: "mt-sm" }, [
                          _vm._v(
                            "\n              Alle weiteren Domains leiten automatisch auf die Haupt-Domain dieser Instanz.\n            "
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("label", [_vm._v("PHP Version")]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            options: _vm.webTemplates,
                            placeholder: "PHP-Version wählen",
                            autocomplete: "off"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n                Keine PHP-Versionen gefunden.\n              "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.form.webTemplate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "webTemplate", $$v)
                            },
                            expression: "form.webTemplate"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", [_vm._v("mod-Pagespeed")]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _vm.form.pagespeed
                          ? _c("span", [_vm._v("Aktiviert")])
                          : _c("span", [_vm._v("Deaktiviert")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.pagespeed,
                              expression: "form.pagespeed"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.pagespeed)
                              ? _vm._i(_vm.form.pagespeed, null) > -1
                              : _vm.form.pagespeed
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.pagespeed,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "pagespeed",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "pagespeed",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "pagespeed", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", [_vm._v("Caching")]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _vm.form.caching
                          ? _c("span", [_vm._v("Aktiviert")])
                          : _c("span", [_vm._v("Deaktiviert")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.caching,
                              expression: "form.caching"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.caching)
                              ? _vm._i(_vm.form.caching, null) > -1
                              : _vm.form.caching
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.caching,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "caching",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "caching",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "caching", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "homedir" } }, [
                        _vm._v("Hauptverzeichnis")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.homeDir,
                            expression: "form.homeDir"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "homedir",
                          placeholder: "/"
                        },
                        domProps: { value: _vm.form.homeDir },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "homeDir", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", [_vm._v("SSL-Verschlüsselung")]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _vm.form.ssl
                          ? _c("span", [_vm._v("Aktiviert")])
                          : _c("span", [_vm._v("Deaktiviert")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.ssl,
                              expression: "form.ssl"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.ssl)
                              ? _vm._i(_vm.form.ssl, null) > -1
                              : _vm.form.ssl
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.ssl,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(_vm.form, "ssl", $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "ssl",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "ssl", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", [_vm._v("Software")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "software mt" },
                        _vm._l(_vm.software, function(sw) {
                          return _c(
                            "li",
                            {
                              key: sw,
                              staticClass: "card sw",
                              class: { selected: _vm.form.preInstall === sw },
                              on: {
                                click: function($event) {
                                  _vm.form.preInstall =
                                    _vm.form.preInstall === sw ? "" : sw
                                }
                              }
                            },
                            [
                              sw === "Shopware"
                                ? [
                                    _c(
                                      "svg",
                                      { attrs: { viewBox: "0 0 99.17 99.1" } },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M79.1,88.29C74.78,85.05,68.47,82.61,61.71,80c-8-3.06-17-6.58-23.87-12-7.75-6.22-11.44-14-11.44-23.79A26.68,26.68,0,0,1,36.94,22.52c7.75-6.12,19.46-9.28,34-9.28A91.28,91.28,0,0,1,82.25,14c.27,0,.63-.09.72-.36a.69.69,0,0,0-.18-.81A49.54,49.54,0,0,0,0,49.55,49.51,49.51,0,0,0,49.55,99.1,49.42,49.42,0,0,0,79.1,89.37a.64.64,0,0,0,.27-.54A.78.78,0,0,0,79.1,88.29Z"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M98.92,45.05a.52.52,0,0,0-.27-.46C88.29,36,80,32.52,69.73,32.52c-5.41,0-9.55,1.08-12.34,3.25a9.09,9.09,0,0,0-3.7,7.47c0,8.47,10.36,12.35,22.44,16.85C82.34,62.43,88.74,64.78,94.6,68c.09.09.18.09.36.09h.27a.74.74,0,0,0,.45-.45,49.3,49.3,0,0,0,3.42-18A19.62,19.62,0,0,0,98.92,45.05Z"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Shopware")])
                                  ]
                                : sw === "Typo3"
                                ? [
                                    _c(
                                      "svg",
                                      {
                                        attrs: { viewBox: "0 0 99.81 101.12" }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M75.52,69.69a14,14,0,0,1-4.26.59c-12.84,0-31.7-44.87-31.7-59.8,0-5.5,1.31-7.34,3.14-8.91C27,3.41,8.12,9.17,2.1,16.51.79,18.34,0,21.22,0,24.89c0,23.32,24.89,76.23,42.44,76.23,8.12,0,21.82-13.36,33.08-31.43"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M67.33,0C83.57,0,99.81,2.62,99.81,11.79,99.81,30.39,88,52.92,82,52.92c-10.74,0-24.1-29.86-24.1-44.8C57.9,1.31,60.52,0,67.33,0"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Typo3")])
                                  ]
                                : sw === "Wordpress"
                                ? [
                                    _c(
                                      "svg",
                                      { attrs: { viewBox: "0 0 100 100" } },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M7.11,50A42.88,42.88,0,0,0,31.28,88.6L10.82,32.54A42.76,42.76,0,0,0,7.11,50Z"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M79,47.83A22.62,22.62,0,0,0,75.42,36c-2.17-3.53-4.21-6.52-4.21-10a7.43,7.43,0,0,1,7.2-7.62c.19,0,.37,0,.56,0a42.89,42.89,0,0,0-64.81,8.07c1,0,2,0,2.76,0,4.49,0,11.43-.54,11.43-.54a1.77,1.77,0,0,1,.28,3.53s-2.32.27-4.91.41L39.34,76.35,48.73,48.2,42,29.89c-2.31-.14-4.49-.41-4.49-.41-2.32-.14-2-3.67.27-3.53,0,0,7.08.54,11.29.54,4.49,0,11.44-.54,11.44-.54a1.77,1.77,0,0,1,.27,3.53s-2.33.27-4.91.41L71.41,76l4.28-14.3C77.55,55.77,79,51.51,79,47.83Z"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M50.75,53.75,37.88,91.15a43,43,0,0,0,26.36-.69,3.63,3.63,0,0,1-.3-.59Z"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M87.64,29.42a33.66,33.66,0,0,1,.29,4.41,40.43,40.43,0,0,1-3.27,15.36L71.56,87.08A42.92,42.92,0,0,0,87.64,29.42Z"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M50,0a50,50,0,1,0,50,50A50.06,50.06,0,0,0,50,0Zm0,97.71A47.71,47.71,0,1,1,97.71,50,47.77,47.77,0,0,1,50,97.71Z"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Wordpress")])
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _vm.form.preInstall
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "fieldset",
                              class: {
                                "fieldset-error":
                                  _vm.$v.form.softwareUser.$error
                              }
                            },
                            [
                              _c("label", { attrs: { for: "softwareUser" } }, [
                                _vm._v("Software-Benutzer")
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.form.softwareUser.$model,
                                    expression: "$v.form.softwareUser.$model"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  name: "softwareUser",
                                  placeholder: "Benutzername"
                                },
                                domProps: {
                                  value: _vm.$v.form.softwareUser.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.form.softwareUser,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "fieldset",
                              class: {
                                "fieldset-error":
                                  _vm.$v.form.softwareEmail.$error
                              }
                            },
                            [
                              _c("label", { attrs: { for: "softwareEmail" } }, [
                                _vm._v("E-Mail")
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.form.softwareEmail.$model,
                                    expression: "$v.form.softwareEmail.$model"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  name: "softwareEmail",
                                  placeholder: "E-Mail-Adresse"
                                },
                                domProps: {
                                  value: _vm.$v.form.softwareEmail.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.form.softwareEmail,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "fieldset",
                              class: {
                                "fieldset-error": _vm.$v.form.softwarePw.$error
                              }
                            },
                            [
                              _c("label", { attrs: { for: "softwarePw" } }, [
                                _vm._v("Passwort")
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.form.softwarePw.$model,
                                    expression: "$v.form.softwarePw.$model"
                                  }
                                ],
                                attrs: {
                                  type: "password",
                                  name: "softwarePw",
                                  placeholder: "Passwort"
                                },
                                domProps: {
                                  value: _vm.$v.form.softwarePw.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.form.softwarePw,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "password-instructions" },
                                [
                                  !_vm.$v.form.softwarePw.lower ||
                                  !_vm.$v.form.softwarePw.upper ||
                                  !_vm.$v.form.softwarePw.digit ||
                                  !_vm.$v.form.softwarePw.minLength
                                    ? _c("span", { staticClass: "text" }, [
                                        _vm._v("Mindestens")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.form.softwarePw.lower
                                    ? _c("span", [_vm._v("1 Kleinbuchstabe")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.form.softwarePw.upper
                                    ? _c("span", [_vm._v("1 Großbuchstabe")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.form.softwarePw.digit
                                    ? _c("span", [_vm._v("1 Ziffer")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.form.softwarePw.minLength
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$v.form.softwarePw.$params
                                              .minLength.min
                                          ) + " Zeichen"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "fieldset",
                              class: {
                                "fieldset-error": _vm.$v.form.repeatPass.$error
                              }
                            },
                            [
                              _c("label", { attrs: { for: "repeat-pass" } }, [
                                _vm._v("Passwort wiederholen")
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.form.repeatPass.$model,
                                    expression: "$v.form.repeatPass.$model"
                                  }
                                ],
                                attrs: {
                                  type: "password",
                                  name: "repeat-pass",
                                  placeholder: "Passwort wiederholen"
                                },
                                domProps: {
                                  value: _vm.$v.form.repeatPass.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.form.repeatPass,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      attrs: {
                        label: "Instanz anlegen",
                        submitting: _vm.submitting
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }