var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cronjobs container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n        Cronjobs\n        "),
          _vm.cronjobs.length && _vm.box
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.sortedCronjobs.length) +
                    "/" +
                    _vm._s(_vm.box.product.cronjobs)
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.accountType === "user" ||
        (_vm.accountType === "organisation" && _vm.isCustomerAdmin) ||
        _vm.isAdmin
          ? _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.newCronjob($event)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "plus" }, [_vm._v("+")]),
                  _vm._v(" Erstellen\n        ")
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [
          _vm.loading && !_vm.cronjobs.length
            ? _c("div", { key: "loading", staticClass: "skeleton info" }, [
                _vm._v("\n        Cronjobs werden geladen...\n      ")
              ])
            : _vm.cronjobs.length
            ? _c(
                "div",
                { key: "table", staticClass: "cronjobs-table table" },
                [
                  _c(
                    "div",
                    {
                      key: "head",
                      staticClass: "row head",
                      class: _vm.currentSortDir
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.sort("name")
                            }
                          }
                        },
                        [
                          _vm._v("\n            Jobbezeichnung\n            "),
                          _c("span", { staticClass: "icon sort" }, [
                            _c("svg", { attrs: { viewBox: "0 0 20 10" } }, [
                              _c("path", { attrs: { d: "M1,9 L10,1 L19,9" } })
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "cell" }, [
                        _vm._v("\n            Ausführung\n          ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.sort("active")
                            }
                          }
                        },
                        [
                          _vm._v("\n            Status\n            "),
                          _c("span", { staticClass: "icon sort" }, [
                            _c("svg", { attrs: { viewBox: "0 0 20 10" } }, [
                              _c("path", { attrs: { d: "M1,9 L10,1 L19,9" } })
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.sortedCronjobs, function(cronjob) {
                    return _c("Cronjob", {
                      key: cronjob.id,
                      attrs: { cronjob: cronjob },
                      on: {
                        fetchCronjobs: _vm.fetchCronjobs,
                        edit: _vm.editCronjob
                      }
                    })
                  }),
                  _vm._v(" "),
                  _vm.cronjobs.length && _vm.sortedCronjobs < 1
                    ? _c(
                        "div",
                        { key: "no-results", staticClass: "row no-results" },
                        [
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              "\n            Keine Cronjobs gefunden\n          "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _c(
                "div",
                { key: "noresults", staticClass: "info" },
                [
                  _vm.isAdmin || _vm.isCustomerAdmin
                    ? [
                        _vm._v(
                          '\n          Es wurden noch keine Cronjobs für diese Box angelegt. Klicke auf "Hinzufügen", um einen ersten Cronjob zu erstellen.\n        '
                        )
                      ]
                    : [
                        _vm._v(
                          "\n          Es wurden noch keine Cronjobs für diese Box angelegt.\n        "
                        )
                      ]
                ],
                2
              )
        ]
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                !_vm.currentCronjob
                  ? _c("h2", { staticClass: "mb" }, [
                      _vm._v("\n          Cronjob erstellen\n        ")
                    ])
                  : _c("h2", { staticClass: "mb" }, [
                      _vm._v("\n          Cronjob bearbeiten\n        ")
                    ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addCronjob($event)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("label", [_vm._v("Jobtyp")]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            options: ["webhook", "Serverseitiger Aufruf"],
                            clearable: false
                          },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        }),
                        _vm._v(" "),
                        _vm.form.type === "webhook"
                          ? _c("legend", { staticClass: "mt-sm" }, [
                              _vm._v(
                                "\n              Lege eine URL fest, die automatisiert aufgerufen wird.\n            "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset mt-lg" }, [
                      _c("label", { staticClass: "switch" }, [
                        _vm.form.active
                          ? _c("span", [_vm._v("Cronjob aktiv")])
                          : _c("span", [_vm._v("Cronjob inaktiv")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.active,
                              expression: "form.active"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.form.active)
                              ? _vm._i(_vm.form.active, null) > -1
                              : _vm.form.active
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.active,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "active",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "active",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.form, "active", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset",
                        class: { "fieldset-error": _vm.$v.form.name.$error }
                      },
                      [
                        _c("label", { attrs: { for: "name" } }, [
                          _vm._v("Bezeichnung")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.form.name.$model,
                              expression: "$v.form.name.$model"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "name",
                            placeholder: "Jobname"
                          },
                          domProps: { value: _vm.$v.form.name.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.name,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset",
                        class: { "fieldset-error": _vm.$v.form.cmd.$error }
                      },
                      [
                        _vm.form.type === "webhook"
                          ? _c("label", { attrs: { for: "cmd" } }, [
                              _vm._v("URL")
                            ])
                          : _c("label", { attrs: { for: "cmd" } }, [
                              _vm._v("Kommando(s)")
                            ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.form.cmd.$model,
                              expression: "$v.form.cmd.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "cmd",
                            placeholder:
                              _vm.form.type === "webhook"
                                ? "domain.ltd/script"
                                : "Kommando"
                          },
                          domProps: { value: _vm.$v.form.cmd.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.cmd,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset",
                        class: { "fieldset-error": _vm.$v.form.interval.$error }
                      },
                      [
                        _c("label", [_vm._v("Intervall")]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            options: _vm.expressions,
                            label: "label",
                            placeholder: "Intervall wählen...",
                            searchable: false,
                            clearable: false,
                            "append-to-body": "",
                            "calculate-position": _vm.withPopper
                          },
                          model: {
                            value: _vm.$v.form.interval.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.form.interval,
                                "$model",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "$v.form.interval.$model"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.form.interval &&
                    _vm.form.interval.label === "Benutzerdefiniert"
                      ? _c(
                          "div",
                          {
                            staticClass: "fieldset",
                            class: {
                              "fieldset-error":
                                _vm.$v.form.customExpression.$error
                            }
                          },
                          [
                            _c(
                              "label",
                              { attrs: { for: "customExpression" } },
                              [_vm._v("Cron-Expression")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.form.customExpression.$model,
                                  expression: "$v.form.customExpression.$model",
                                  modifiers: { trim: true }
                                }
                              ],
                              attrs: {
                                type: "text",
                                name: "customExpression",
                                autocomplete: "false"
                              },
                              domProps: {
                                value: _vm.$v.form.customExpression.$model
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.form.customExpression,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  function($event) {
                                    return _vm.parse()
                                  }
                                ],
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("legend", { staticClass: "parsed-exp mt-sm" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.parsedExpression) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.currentCronjob
                      ? _c("SubmitBtn", {
                          attrs: {
                            label: "Cronjob erstellen",
                            submitting: _vm.submitting,
                            disabled: _vm.$v.form.$invalid
                          }
                        })
                      : _c("SubmitBtn", {
                          attrs: {
                            label: "Cronjob aktualisieren",
                            submitting: _vm.submitting,
                            disabled: _vm.$v.form.$invalid
                          }
                        }),
                    _vm._v(" "),
                    _vm.currentCronjob
                      ? _c("SubmitBtn", {
                          staticClass: "mt-sm",
                          attrs: {
                            label: "Cronjob löschen",
                            submitting: _vm.deleting,
                            red: ""
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.deleteCronjob($event)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Dialog", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }