<template>
  <div class="organisations container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Unternehmen
      </h1>
      <div class="actions">
        <button
          class="btn"
          @click.prevent="openModal"
        >
          <span class="plus">+</span> Hinzufügen
        </button>
      </div>
    </div>

    <div class="search mb-lg">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Unternehmen filtern"
      >
      <a
        v-if="searchQuery"
        href="#"
        class="icon clear"
        @click.prevent="clearFilter"
      ><span /><span /></a>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="loading && !organisations.length"
        class="skeleton info"
      >
        Unternehmen werden geladen...
      </div>
      <div
        v-else-if="organisations.length"
        class="organisationslist table"
      >
        <!-- Head -->
        <div
          key="head"
          class="row head"
          :class="currentSortDir"
        >
          <div
            class="cell"
            @click.prevent="sort('name')"
          >
            Unternehmen
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>
          <div
            class="cell"
            @click.prevent="sort('email')"
          >
            E-Mail
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>
          <!-- <div
            class="cell"
            @click.prevent="sort('users')"
          >
            User
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div> -->
          <div
            class="cell align-right"
          >
            Status
          </div>
        </div>
        <!-- Body -->
        <router-link
          v-for="organisation in sortedOrganisations"
          :key="organisation.id"
          :to="{name: 'organisations-id', params: {id: organisation.id}}"
          class="row organisation"
        >
          <div class="cell name overflow">
            <span class="id">ID {{ organisation.id }}</span><span>{{ organisation.name }}</span>
          </div>
          <div class="cell email overflow">
            <span>{{ organisation.email }}</span>
          </div>
          <!-- <div class="cell users">
            {{ organisation.users }}
          </div> -->
          <div class="cell status align-right">
            <span v-if="organisation.active">Aktiv</span><span v-else>Inaktiv</span>
          </div>
        </router-link>
        <div
          v-if="sortedOrganisations < 1"
          key="no-results"
          class="row no-results"
        >
          <div class="cell">
            Keine Unternehmen gefunden
          </div>
        </div>
      </div>
      <div
        v-else
        class="info"
      >
        Noch keine Unternehmen vorhanden. Klicke auf "Hinzufügen", um ein erstes Unternehmen zu erstellen.
      </div>
    </transition>

    <Modal ref="modal">
      <template #content>
        <h2 class="mb">
          Unternehmen hinzufügen
        </h2>
        <form
          class="grid"
          @submit.prevent="addOrganisation"
        >
          <div class="fieldset col-12">
            <label for="name">Name</label>
            <input
              v-model="form.name"
              type="text"
              name="name"
              placeholder="Name"
              required
            >
          </div>
          <div class="fieldset col-6">
            <label for="street">Straße &amp; Hausnummer</label>
            <input
              v-model="form.street"
              type="text"
              name="street"
              placeholder="Strasse"
              required
            >
          </div>
          <div class="fieldset col-6">
            <label for="zip">PLZ</label>
            <input
              v-model="form.zip"
              type="text"
              name="zip"
              placeholder="PLZ"
              required
            >
          </div>
          <div class="fieldset col-6">
            <label for="city">Ort</label>
            <input
              v-model="form.city"
              type="text"
              name="city"
              placeholder="Stadt"
              required
            >
          </div>
          <div
            class="fieldset col-6"
          >
            <label for="country">Land</label>
            <v-select
              v-model="form.country"
              :options="countries"
              placeholder="Land"
              :clearable="false"
            />
          </div>
          <div class="fieldset col-12">
            <label for="phone">Telefon</label>
            <input
              v-model="form.phone"
              type="text"
              name="phone"
              placeholder="Telefon"
              required
            >
          </div>
          <div class="fieldset col-12">
            <label for="email">E-Mail</label>
            <input
              v-model="form.email"
              type="text"
              name="email"
              placeholder="E-Mail"
              required
            >
          </div>
          <div class="fieldset col-6">
            <label for="credit">Credit</label>
            <input
              v-model="form.credit"
              type="number"
              name="credit"
              placeholder="Credit"
            >
          </div>
          <div class="fieldset col-6">
            <label>Account</label>
            <label class="switch">
              <span v-if="!form.active">Deaktiviert</span>
              <span v-else>Aktiviert</span>
              <input
                v-model="form.active"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>
          <div
            class="fieldset col-12"
          >
            <div class="fieldset">
              <label for="users">Benutzer</label>
              <input
                v-model="form.users"
                type="text"
                name="users"
                placeholder="Benutzer hinzufügen..."
              >
              <legend class="mt-sm">
                E-Mail-Adresse des Benutzers. Mehrere Adressen mit ',' trennen.
              </legend>
            </div>
            <!-- <label for="users">Benutzer</label>
            <v-select
              v-model="form.users"
              :options="users"
              placeholder="Benutzer hinzufügen"
              :clearable="true"
              :multiple="true"
              label="email"
              :reduce="user => user.id"
            /> -->
          </div>
          <div class="col-12">
            <SubmitBtn
              label="Unternehmen anlegen"
              :submitting="submitting"
            />
          </div>
        </form>
      </template>
    </Modal>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Organisations',

  components: {
    Modal: () => import('../components/Modal'),
    Dialog: () => import('../components/Dialog'),
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  data() {
    return {
      loading: false,
      organisations: [],
      form: {
        name: '',
        email: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        credit: 0,
        active: false,
        users: []
      },
      countries: [
        'Deutschland',
        'Österreich',
        'Schweiz'
      ],
      submitting: false,
      users: [],

      currentSort: 'name',
      currentSortDir: 'asc',
      searchQuery: '',
    }
  },

  computed: {
    sortedOrganisations() {
      var filteredOrganisations = this.organisations.filter(organisation => {
        if(organisation) {
          return organisation.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
          organisation.id == this.searchQuery ||
          organisation.email.toLowerCase().includes(this.searchQuery.toLowerCase())
        } else {
          return null
        }
      })
      return _.orderBy(filteredOrganisations, this.currentSort, this.currentSortDir) 
    }
  },

  created() {
    this.fetch()
  },

  methods: {
    async fetch() {
      this.loading = true
      const organisations = await this.$axios.get('/api/organisations')
      this.organisations = organisations.data
      this.loading = false
    },

    async fetchUsers() {
      const users = await this.$axios.get('/api/users')
      this.users = users.data
    },

    openModal() {
      this.fetchUsers()
      this.$refs.modal.open()
    },

    closeModal() {
      this.$refs.modal.close()
    },

    async addOrganisation() {
      const formData = {
        name: this.form.name,
        email: this.form.email,
        street: this.form.street,
        zip: this.form.zip,
        city: this.form.city,
        country: this.form.country,
        phone: this.form.phone,
        credit: this.form.credit,
        active: this.form.active,
        users: this.form.users ? typeof this.form.users === "string" ? this.form.users.split(',') : []: []
      }
      this.submitting = true
      this.$axios.post('/api/organisation', formData).then(async() => {
        this.closeModal()
        await this.fetch()
        this.submitting = false
        this.$notify.success('Unternehmen erstellt')
      }).catch(error => {
        console.log(error.response)
        this.submitting = false
      })
    },

    sort(s) {
      if(s === this.currentSort) this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      this.currentSort = s
    },

    clearFilter() {
      this.searchQuery = ''
      this.currentSort = 'name'
      this.currentSortDir = 'asc'
    }
  }
}
</script>

<style lang="scss" scoped>
.organisationslist {
  .row {
    grid-template-columns: 1fr 1fr 100px;

    .name {
      .id {
        font-size: $fontsize-sm;
        font-weight: $medium;
        color: lighten($color-primary, 25%);
        margin-right: $spacer-xs;
        flex-shrink: 0;
      }
    }

		.roles {
			span {
				background: lighten($color-primary, 25%);
				padding: $spacer-xs;
				border-radius: 4px;
				font-size: $fontsize-sm;
				color: $color-bg;
				font-weight: 500;
				
				&:not(:last-child) {
					margin-right: $spacer-xs;
				}
			}
		}
  }
}
</style>