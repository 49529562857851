import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    customer: null,
    box: null,
    accountType: null,
    config: null
  },

  mutations: {
    setUser(state, data) {
      state.user = data
      // if(data && data.roles.indexOf('admin') < 0) {
      //state.accountType = data && data.company ? 'organisation' : 'user'
      //data && data.company ? localStorage.setItem('type', 'organisation') : localStorage.setItem('type', 'user')
      state.accountType = 'user'
      localStorage.setItem('type', 'user')
      // }
    },
    setCustomer(state, data) {
      state.customer = data
      //state.accountType = data && data.company ? 'organisation' : 'user'
      //data && data.company ? localStorage.setItem('type', 'organisation') : localStorage.setItem('type', 'user')
      state.accountType = 'user'
      localStorage.setItem('type', 'user')
      if (data) Cookies.set('currentCustomer', data)
    },
    setBox(state, data) {
      state.box = data
    },
    setAccountType(state, data) {
      state.accountType = data
    },
    setConfig(state, data) {
      state.config = data
    }
  },

  getters: {
    user: state => state.user,
    customer: state => state.customer,
    isAuthenticated: state => !!state.user,
    isAdmin: state => state.user && state.user.roles.indexOf('admin') >= 0,
    isCustomerAdmin: state => state.user && state.user.roles.indexOf('customer_admin') >= 0,
    box: state => state.box,
    accountType: state => state.accountType,
    id: state => {
      if (state.customer) {
        if (state.accountType === 'organisation') {
          return state.customer.company.id
        } else {
          return state.customer.id
        }
      } else if (state.user) {
        if (state.accountType === 'organisation') {
          return state.user.company
        } else if (state.user) {
          return state.user.id
        }
      }
    },
    config: state => state.config,
    //ftpUsers: state => state.user.ftpUser
  }
})