<template>
  <div class="support-container">
    <h1 class="support-container-title">
      Ticketübersicht
    </h1>
    <form
      class="support-ticket-creator"
      @submit.prevent="createTicket()"
    >
      <label for="ticketTitle">Betreff</label>
      <input
        v-model="form.title"
        class="support-ticket-creator-title"
        placeholder="Betreff angeben"
        type="text"
      >
      <label for="ticketBody">Inhalt</label>
      <textarea
        v-model="form.body"
        rows="10"
        class="support-ticket-creator-body"
        placeholder="Bitte hier das Problem schildern"
        type="text"
      />
      <label for="contactChannel">Kontaktweg</label>
      <v-select
        v-model="form.communicationChannel"
        class="support-ticket-creator-select"
        :options="contactChannels"
        placeholder="Kontaktweg auswählen"
        :clearable="false"
      />

      <div class="support-ticket-creator-button">
        <SubmitBtn
          label="Ticket absenden "
          :submitting="submitting"
          class="mb-sm"
          @click.native.prevent="createTicket"
        />
      </div>
    </form>

    <div class="support-ticket-container">
      <h2 class="support-ticket-title">
        Offene Tickets
      </h2>
      <ul
        v-for="openTicket in openTickets"
        :key="openTicket.id"
      >
        <li class="support-ticket-item">
          <span class="support-ticket-item-title">{{ openTicket.Title }}</span>
          <span class="support-ticket-item-body">{{ openTicket.body }}</span>
          <a
            class="support-ticket-item-link"
            :href="'https://ks4.ciab.io/otobo/index.pl?Action=AgentTicketZoom;TicketID=' + openTicket.TicketID"
          > Bring mich zu Otobo</a>
        </li>
      </ul>
    </div>
    <div class="support-ticket-container">
      <h2 class="support-ticket-title">
        Geschlossene Tickets
      </h2>
      <ul
        v-for="closedTicket in closedTickets"
        :key="closedTicket.id"
      >
        <li class="support-ticket-item">
          <span class="support-ticket-item-title">{{ closedTicket.Title }}</span>
          <span class="support-ticket-item-body">{{ closedTicket.body }}</span>
          <a
            class="support-ticket-item-link"
            :href="'https://ks4.ciab.io/otobo/customer.pl?Action=AgentTicketZoom;TicketID=' + closedTicket.TicketID"
          > Bring mich zu Otobo </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Support',
  components: {
    // ServerList: () => import('../components/servers/ServerList'),
    // Modal: () => import('../components/Modal'),
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  data() {
    return {
      openTickets : [],
      closedTickets : [],
      contactChannels: [
        {label : 'Telefon', value : 'phone'},
        {label : 'Videochat', value : 'videochat'},
        {label : 'E-Mail', value : 'Email'},
      ],
      form: {
        title: '',
        body: '',
        communicationChannel : ''
      },
      submitting: false,
      loading: false
    }
  },

  // computed: {

  // },

  async created() {
    // FETCH TICKETS 
    await this.loadTickets()

  },

  mounted() {

  },

  methods: {
    openModal() {
      this.$refs.modal.open()
    },

    closeModal() {
      this.$refs.modal.close()
    },

    async createTicket(){

      if (!this.form.title){
        this.$notify.error('Betreff benötigt')
        return;

      }else if(!this.form.body){
        this.$notify.error('Inhalt  benötigt')
        return;
            
      }else if (!this.form.communicationChannel){
        this.$notify.error('Kontaktweg benötigt')
        return;

      }else{
        try{
          let apiResponse = await this.$axios.post('api/tickets', this.form)
          console.log('CREATE TICKET ' ,apiResponse)
          this.$notify.success('Ticket wurde erstellt')
        }catch(error){
          console.error(error)
          this.$notify.error('Ticket konnte nicht erstellt werden ' + error)

        }
      }
    },

    async loadTickets(){
      this.loading = true;
      try{
        let ticketApiResponse = await this.$axios.get('/api/tickets');
        console.log(ticketApiResponse.data)
        for (let ticket in ticketApiResponse.data){
          console.log((ticketApiResponse.data[ticket]))
          if (ticketApiResponse.data[ticket].StateType === 'closed')this.closedTickets.push(ticketApiResponse.data[ticket])
          else this.openTickets.push(ticketApiResponse.data[ticket])
        } 
        this.$notify.success('Tickets geladen')
      }catch(error){
        this.$notify.error('Tickets konnten nicht geladen werden ' +  error)
        console.error(error)
      }

      console.log(this)
      this.loading = false;

    }
  }
}
</script>

<style lang="scss" scoped>
textarea { 
    height: auto;
    resize: none;
    }
.support-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.support-container-title{
    margin-top: 5%;
}

.support-ticket-creator{
    margin-bottom:4%;

    width: 80%;
    box-shadow: 2.8px 2.8px 2.2px rgb(0 0 0 / 2%), 6.7px 6.7px 5.3px rgb(0 0 0 / 3%), 12.5px 12.5px 10px rgb(0 0 0 / 4%), 22.3px 22.3px 17.9px rgb(0 0 0 / 4%), 41.8px 41.8px 33.4px rgb(0 0 0 / 5%), 100px 100px 80px rgb(0 0 0 / 7%);
    border-bottom-right-radius: 3rem;
    border-bottom-left-radius: 3rem;
    margin-top: 4%;
}

.support-ticket-container{
    
    width: 80%;
}

.support-ticket-container{
    width: 80%;
}

.support-ticket-title{
    margin-top: 3%;
    margin-left: 3%;
    border-bottom: 2px solid #b5a0c7;
    margin-right: 3%;

}

.support-ticket-item{
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    box-shadow: 2.8px 2.8px 2.2px rgb(0 0 0 / 2%), 6.7px 6.7px 5.3px rgb(0 0 0 / 3%), 12.5px 12.5px 10px rgb(0 0 0 / 4%), 22.3px 22.3px 17.9px rgb(0 0 0 / 4%), 41.8px 41.8px 33.4px rgb(0 0 0 / 5%), 100px 100px 80px rgb(0 0 0 / 7%);
    width: 60%;
    min-height: 5rem;
    border-bottom-right-radius: 2rem;
      border: 2px solid #e6dde9;
    transition: border-color 0.3s;
}

.support-ticket-item-title{
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 1rem;
    margin-left: 1rem;
}

.support-ticket-item-body{

}

.support-ticket-item-link{
    border: 2px solid #765691;
    width: 30%;
    border-bottom-right-radius: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 66%;
}


.support-ticket-creator-button{
    margin-bottom: 4rem;
    width: 80%;
    margin-left: 10%;}
.support-ticket-creator-title{
    margin-bottom: 2rem;

}
.support-ticket-creator-body{
    margin-bottom: 2rem;
    height: 10rem;

}
.support-ticket-creator-select{
    margin-bottom: 2rem;
}
</style>