import Vue from 'vue'

import axios from 'axios'
import store from './store'
import router from './router'
import gsap from 'gsap'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import VueNotification from '@mathieustan/vue-notification';

import App from './App'

import './styles/base.scss'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.BASE_URL

const token = localStorage.getItem('user-token')
const renewToken = localStorage.getItem('renew-token')
const fp = localStorage.getItem('fingerprint')

if(token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  axios.defaults.headers.common['Fingerprint'] = fp
}

if(renewToken) {
  axios.post('/auth/renew', {renewToken: renewToken}).then((response) => {
    localStorage.setItem('user-token', response.data.token)
    localStorage.setItem('renew-token', response.data.renewToken)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
    axios.defaults.headers.common['Fingerprint'] = fp
    store.commit('setUser', response.data.user)
  }).catch(() => {
    localStorage.removeItem('user-token')
    localStorage.removeItem('fingerprint')
    localStorage.removeItem('renew-token')
    delete axios.defaults.headers.common['Authorization']
    delete axios.defaults.headers.common['Fingerprint']
    store.commit('setUser', null)
    router.push('/login')
  })
}

axios.interceptors.response.use(response => {
  return response
}, async function(error) {
  const originalRequest = error.config
  if ((error.response.status === 401 || (error.response.status === 400 && error.response.data && error.response.data.error === 'Expired token') && !originalRequest._retry)) {
    if(error.config.url == '/auth/renew') {
      localStorage.removeItem('user-token')
      localStorage.removeItem('renew-token')
      delete axios.defaults.headers.common['Authorization']
      delete axios.defaults.headers.common['Fingerprint']
      store.commit('setUser', null)
      // router.push('/login')
      return
    }
    originalRequest._retry = true
    const renewToken = localStorage.getItem('renew-token')
    const fp = localStorage.getItem('fingerprint')
    // console.log('retry with new token')
    try {
      const response = await axios.post('/auth/renew', { renewToken: renewToken })
      localStorage.setItem('user-token', response.data.token)
      localStorage.setItem('renew-token', response.data.renewToken)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
      originalRequest.headers['Authorization'] = 'Bearer ' + response.data.token
      axios.defaults.headers.common['Fingerprint'] = fp
      // store.commit('setUser', response.data.user)
      // console.log('token renewed by interceptor')
      return await axios(originalRequest)
    } catch(error) {
      localStorage.removeItem('user-token')
      localStorage.removeItem('renew-token')
      delete axios.defaults.headers.common['Authorization']
      delete axios.defaults.headers.common['Fingerprint']
      store.commit('setUser', null)
      router.push('/login')
    }
  }
  throw error
  // return Promise.reject(error);
})

Vue.mixin({
  created: function () {
    this.$gsap = gsap
  }
})

Vue.prototype.$axios = axios
Vue.prototype.$fingerprint = FingerprintJS
Vue.prototype.$eventBus = new Vue()
Vue.prototype.$devMode = false
Vue.prototype.$CiabV2Version = 'V2.1.8'

Vue.use(Vuelidate)
Vue.use(VueNotification, {
  theme: {
    colors: {
      success: '#3c9938',
      darkenSuccess: '#3c9938',
      info: '#3074b9',
      darkenInfo: '#3074b9',
      warning: '#ec7200',
      darkenWarning: '#ec7200',
      error: '#b62e2e',
      darkenError: '#b62e2e',
      offline: '#000',
      darkenOffline: '#000',
    },
    // boxShadow: '10px 5px 5px red',
  },
  breakpoints: {
    0: {
      bottom: true,
    },
    480: {
      bottom: true,
      right: true,
    },
  },
})
Vue.component('VSelect', vSelect)

Vue.config.productionTip = false
new Vue({
  components: { App },
  template: '<App/>',
  router,
  store
}).$mount('#app')