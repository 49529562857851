<template>
  <div class="profile">
    <div class="page-head container">
      <div class="title">
        <h4 v-if="user">
          {{ user.firstName }} {{ user.lastName }} / <span v-if="user.company">{{ user.company.name }}</span>
        </h4>
        <h1>Mein Profil</h1>
      </div>
      <!--<div
        v-if="user && !isAdmin"
        class="credit"
      >
        <span class="label">Guthaben</span>
        <span
          class="value h1"
          :class="{'minus' : user.credit < 0}"
        >{{ user.credit }}€</span>
      </div>-->
    </div>

    <div class="container">
      <h3 class="mb-lg">
        Adress- &amp; Kontaktdaten
      </h3>
      <form
        class="mb-lg"
        @submit.prevent="updateUser"
      >
        <div class="fieldsetgroup">
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.street.$error}"
          >
            <label for="street">Straße &amp; Hausnummer</label>
            <input
              v-model="$v.form.street.$model"
              type="text"
              name="street"
              placeholder="Musterstrasse 1a"
            >
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.country.$error}"
          >
            <label for="country">Land</label>
            <v-select
              v-model="$v.form.country.$model"
              :options="countries"
              placeholder="Land"
              :clearable="false"
            />
          </div>
        </div>
        <div class="fieldsetgroup">
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.zip.$error}"
          >
            <label for="zip">PLZ</label>
            <input
              v-model="$v.form.zip.$model"
              type="text"
              name="zip"
              placeholder="PLZ"
            >
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.city.$error}"
          >
            <label for="city">Stadt / Ort</label>
            <input
              v-model="$v.form.city.$model"
              type="text"
              name="city"
              placeholder="Stadt"
            >
          </div>
        </div>
        <div class="fieldsetgroup">
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.phone.$error}"
          >
            <label for="phone">Telefon</label>
            <input
              v-model="$v.form.phone.$model"
              type="text"
              name="phone"
              placeholder="Telefon"
            >
          </div>
        </div>
        <SubmitBtn
          label="Profil aktualisieren"
          :submitting="updatingUser"
          inline
        />
      </form>
    </div>

    <div class="subhead">
      <div class="container">
        <h3 class="mb-sm">
          E-Mail-Adresse ändern
        </h3>
        <legend>
          Neuanmeldung nach Aktualisierung erforderlich.
        </legend>
      </div>
    </div>

    <div class="container">
      <form
        class="mb-lg"
        @submit.prevent="updateMail"
      >
        <div
          class="fieldset"
          :class="{'fieldset-error' : $v.email.$error}"
        >
          <label for="email">E-Mail</label>
          <input
            v-model.trim="$v.email.$model"
            type="email"
            autocomplete="email"
            name="email"
            placeholder="E-Mail"
          >
        </div>
        <SubmitBtn
          label="E-Mail aktualisieren"
          :submitting="updatingMail"
          inline
        />
      </form>
    </div>

    <div class="subhead">
      <div class="container">
        <h3 class="title">
          Passwort ändern
        </h3>
      </div>
    </div>

    <div class="container">
      <form
        class="mb-lg"
        @submit.prevent="updatePassword"
      >
        <div
          class="fieldset"
          :class="{'fieldset-error' : $v.pwForm.oldPass.$error}"
        >
          <label for="current">Aktuelles Passwort</label>
          <input
            v-model.trim="$v.pwForm.oldPass.$model"
            type="password"
            autocomplete="new-password"
            name="current"
            placeholder="Aktuelles Passwort"
          >
        </div>
        <div
          class="fieldset"
          :class="{'fieldset-error' : $v.pwForm.newPass.$error}"
        >
          <label for="password">Neues Passwort</label>
          <input
            v-model.trim="$v.pwForm.newPass.$model"
            type="password"
            autocomplete="new-password"
            name="password"
            placeholder="Neues Passwort"
          >
          <div class="password-instructions">
            <span
              v-if="!$v.pwForm.newPass.lower || !$v.pwForm.newPass.upper || !$v.pwForm.newPass.digit || !$v.pwForm.newPass.minLength"
              class="text"
            >Mindestens</span>
            <span v-if="!$v.pwForm.newPass.lower">1 Kleinbuchstabe</span>
            <span v-if="!$v.pwForm.newPass.upper">1 Großbuchstabe</span>
            <span v-if="!$v.pwForm.newPass.digit">1 Ziffer</span>
            <span v-if="!$v.pwForm.newPass.minLength">{{ $v.pwForm.newPass.$params.minLength.min }} Zeichen</span>
          </div>
        </div>
        <div
          class="fieldset"
          :class="{'fieldset-error' : $v.pwForm.repeatPassword.$error}"
        >
          <label for="repeat-password">Passwort wiederholen</label>
          <input
            v-model.trim="$v.pwForm.repeatPassword.$model"
            type="password"
            autocomplete="new-password"
            name="repeat-password"
            placeholder="Passwort wiederholen"
          >
        </div>
        <SubmitBtn
          label="Passwort aktualisieren"
          :submitting="updatingPw"
          inline
        />
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import { required, email, minLength, sameAs, helpers } from 'vuelidate/lib/validators'
// Custom Validators
const lower = helpers.regex('lower', /[a-z]+/)
const upper = helpers.regex('upper', /[A-Z]+/)
const digit = helpers.regex('digit', /[0-9]+/)

export default {
  name: 'Profile',
  components: {
    // Dialog: () => import('../Dialog'),
    SubmitBtn: () => import('../components/SubmitBtn')
  },

  data() {
    return {
      updatingUser: false,
      updatingMail: false,
      updatingPw: false,

      form: {
        street: '',
        zip: '',
        city: '',
        phone: '',
        country: '',
      },
      email: '',
      pwForm: {
        oldPass: '',
        newPass: '',
        repeatPassword: ''
      },

      countries: [
        'Deutschland',
        'Österreich',
        'Schweiz'
      ],
    }
  },

  validations: {
    form: {
      street: {required},
      zip: {required},
      city: {required},
      phone: {required},
      country: {required},
    },
    email: {required, email},
    pwForm: {
      oldPass: {required},
      newPass: {required, digit, lower, upper, minLength: minLength(8)},
      repeatPassword: {required, sameAsPassword: sameAs('newPass')}
    },
  },

  computed: {
    ...mapGetters([
      'user',
      'isAdmin'
    ])
  },

  watch: {
    'user': {
      immediate: true,
      handler() {
        if(this.user) {
          this.email = this.user.email
          this.form.street = this.user.street
          this.form.zip = this.user.zip
          this.form.city = this.user.city
          this.form.country = this.user.country
          this.form.phone = this.user.phone
        }
      }
    }
  },

  methods: {
    ...mapMutations({
      setUser: 'setUser'
    }),

    async fetchUser() {
      this.$axios.post(`/auth/renew`, {renewToken: localStorage.getItem('renew-token')}).then(response => {
        this.setUser(response.data.user)
      })
    },

    async updateUser() {
      this.$v.form.$touch()
      if(!this.$v.form.country.required) {
        this.$notify.error(`Land benötigt`)
      } else if(!this.$v.form.street.required) {
        this.$notify.error(`Strasse benötigt`)
      } else if(!this.$v.form.zip.required) {
        this.$notify.error(`PLZ benötigt`)
      } else if(!this.$v.form.city.required) {
        this.$notify.error(`Ort benötigt`)
      } else if(!this.$v.form.phone.required) {
        this.$notify.error(`Telefon benötigt`)
      } else {
        this.updatingUser = true
        this.$axios.put(`/api/user/${this.user.id}`, this.form).then(async () => {
          await this.fetchUser()
          this.updatingUser = false
          this.$notify.success('Benutzer aktualisiert')
        }).catch(error => {
          console.log(error.response)
          this.updatingUser = false
        })
      }
    },

    async updateMail() {
      this.$v.email.$touch()
      if(!this.$v.email.required) {
        this.$notify.error(`E-Mail benötigt`)
      } else if(!this.$v.email.email) {
        this.$notify.error(`E-Mail ungültig`)
      } else {
        this.updatingMail = true
        this.$axios.put(`/api/user/${this.user.id}`, {
          email: this.email
        }).then(async () => {
          this.updatingMail = false
          this.$notify.success('E-Mail aktualisiert, bitte erneut anmelden')
          this.$axios.post('auth/logout')
          localStorage.removeItem('user-token')
          localStorage.removeItem('fingerprint')
          localStorage.removeItem('renew-token')
          delete this.$axios.defaults.headers.common['Authorization']
          delete this.$axios.defaults.headers.common['Fingerprint']
          this.setUser(null)
          this.$router.push('/login')
        }).catch(error => {
          console.log(error.response)
          this.updatingMail = false
        })
      }
    },

    async updatePassword() {
      this.$v.pwForm.$touch()
      if(!this.$v.pwForm.oldPass.required) {
        this.$notify.error('Aktuelles Passwort benötigt')
      } else if(!this.$v.pwForm.newPass.required) {
        this.$notify.error('Neues Passwort benötigt')
      } else if(!this.$v.pwForm.repeatPassword.required) {
        this.$notify.error('Neues Passwort bestätigen')
      } else if(!this.$v.pwForm.repeatPassword.sameAsPassword) {
        this.$notify.error('Passwörter stimmen nicht überein')
      } else {
        this.updatingPw = true
        this.$axios.put(`/api/user/${this.user.id}`, {
          password: this.pwForm.newPass,
        }).then(async () => {
          await this.fetchUser()
          this.updatingPw = false
          this.$notify.success('Passwort aktualisiert')
        }).catch(error => {
          console.log(error)
          this.updatingPw = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
	h4 {
		color: lighten($color-primary, 25%);
	}
}

.credit {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	line-height: 1;

	.label {
		color: lighten($color-primary, 25%);
		font-weight: $medium;
		margin-bottom: $spacer-xs;
	}

	.value {
		font-weight: $regular;

		&.minus {
			color: $color-error;
		}
	}
}
</style>