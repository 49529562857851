<template>
  <div class="forgot container">
    <form @submit.prevent="resetPassword">
      <h1 class="mb-sm">
        Passwort zurücksetzen
      </h1>
      <p>
        Hier kannst du ein neues Passwort festlegen. Am besten gleich notieren!
      </p>
      <div
        class="fieldset mt"
        :class="{'fieldset-error' : $v.newPass.$error}"
      >
        <input
          v-model="$v.newPass.$model"
          type="password"
          autocomplete="new-password"
          name="password"
          placeholder="Neues Passwort"
        >
        <div class="password-instructions">
          <span
            v-if="!$v.newPass.lower || !$v.newPass.upper || !$v.newPass.digit || !$v.newPass.minLength"
            class="text"
          >Mindestens</span>
          <span v-if="!$v.newPass.lower">1 Kleinbuchstabe</span>
          <span v-if="!$v.newPass.upper">1 Großbuchstabe</span>
          <span v-if="!$v.newPass.digit">1 Ziffer</span>
          <span v-if="!$v.newPass.minLength">{{ $v.newPass.$params.minLength.min }} Zeichen</span>
        </div>
      </div>
      <div
        class="fieldset"
        :class="{'fieldset-error' : $v.passConfirmation.$error}"
      >
        <input
          v-model="$v.passConfirmation.$model"
          type="password"
          name="password-confirmation"
          placeholder="Passwort wiederholen"
        >
      </div>
      <SubmitBtn
        label="Passwort ändern"
        :submitting="submitting"
        inline
      />
    </form>
  </div>
</template>

<script>
import { required, sameAs, minLength, helpers } from 'vuelidate/lib/validators'

// Custom Validators
const lower = helpers.regex('lower', /[a-z]+/)
const upper = helpers.regex('upper', /[A-Z]+/)
const digit = helpers.regex('digit', /[0-9]+/)

export default {
  name: 'Reset',

  components: {
    SubmitBtn: () => import('../components/SubmitBtn'),
  },
  
  data() {
    return {
      newPass: '',
      passConfirmation: '',
      submitting: false
    }
  },

  validations: {
    newPass: { required, digit, lower, upper, minLength: minLength(8) },
    passConfirmation: { required, sameAsPassword: sameAs('newPass') }
  },

  methods: {
    async resetPassword() {
      this.$v.$touch()
      if (this.$v.newPass.$error) {
        this.$notify.error('Ungültiges Passwort')
      } else if (!this.$v.passConfirmation.sameAsPassword) {
        this.$notify.error('Passwort stimmt nicht überein')
      } else {
        this.submitting = true
        this.$axios.put('/auth/reset', {
          email: this.$route.query.email,
          otp: this.$route.query.otp,
          password: this.newPass,
        }).then(() => {
          this.$notify.success('Passwort geändert')
          this.$router.push('/login')
          this.submitting = false
        }).catch(error => {
          this.submitting = false
          console.log('error:', error.response)
          this.$notify.error('Fehlgeschlagen')
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>