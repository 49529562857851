<template>
  <div class="databases container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Datenbanken
        <span v-if="dbs && dbs.length && box">{{ dbs.length }}/{{ box.product.mysqlDatabases }}</span>
      </h1>
      <div
        v-if="accountType === 'user' || (accountType === 'organisation' && isCustomerAdmin) || isAdmin"
        class="actions"
      >
        <button
          class="btn"
          :disabled="dbs.length >= box.product.mysqlDatabases"
          @click.prevent="add"
        >
          <span class="plus">+</span> Hinzufügen
        </button>
      </div>
    </div>

    <div class="search mb-lg">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Datenbanken filtern"
      >
      <a
        v-if="searchQuery"
        href="#"
        class="icon clear"
        @click.prevent="clearFilter"
      ><span /><span /></a>
    </div>

    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <div
        v-if="loading && !dbs.length"
        key="loading"
        class="skeleton"
      >
        Datenbanken werden geladen
      </div>
      <div
        v-else-if="dbs.length"
        key="dbtable"
        class="databases-table table"
      >
        <!-- Head -->
        <div
          key="head"
          class="row head"
          :class="currentSortDir"
        >
          <div
            class="cell"
            @click.prevent="sort('name')"
          >
            Name
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>
          <div
            class="cell"
            @click.prevent="sort('dbUser')"
          >
            User
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>
          <!-- <div
            class="cell"
            @click.prevent="sort('dbUser')"
          >
            Instanz
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>-->
        </div> 
        <!-- Body -->
        <Database
          v-for="db in sortedDbs"
          :key="db.id"
          :db="db"
          :prefix="dbPrefix"
          @update="update"
        />
        <div
          v-if="sortedDbs < 1"
          key="no-results"
          class="row no-results"
        >
          <div class="cell">
            Keine Datenbanken gefunden
          </div>
        </div>
      </div>
      <div
        v-else
        key="noresults"
        class="info"
      >
        <template v-if="isAdmin || isCustomerAdmin">
          Es wurden noch keine Datenbanken für diese Box angelegt. Klicke auf "Hinzufügen", um eine erste Datenbanken zu erstellen.
        </template>
        <template v-else>
          Es wurden noch keine Datenbanken für diese Box angelegt.
        </template>
      </div>
    </transition>

    <Modal ref="modal">
      <template #content>
        <h2
          v-if="!currentDb"
          class="mb"
        >
          Datenbank hinzufügen
        </h2>
        <h2
          v-else
          class="mb"
        >
          Datenbank bearbeiten
        </h2>
        <form>
          <div
            v-if="!currentDb"
            class="fieldset"
            :class="{'fieldset-error' : $v.form.name.$error}"
          >
            <label for="name">Name</label>
            <input
              v-model="$v.form.name.$model"
              type="text"
              name="name"
              placeholder="Name"
            >
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.dbUser.$error}"
          >
            <label for="user">
              User
              <span
                v-if="charsLeft(user.id, box.id) > 0"
                class="counter"
              >
                (Noch {{ charsLeft(user.id, box.id) }} Zeichen)
              </span>
            </label>
            <span
              ref="prefix"
              class="prefix"
            >
              {{ prefix(user.id, box.id) }}
            </span>
            <input
              v-model="$v.form.dbUser.$model"
              :maxlength="16 - `U${user.id}B${box.id}_`.length"
              class="has-prefix"
              type="text"
              name="user"
              placeholder="Name"
            >
          </div>
          <div
            class="fieldset password"
            :class="{'fieldset-error' : $v.form.password.$error}"
          >
            <label for="password">Passwort</label>
            <input
              v-model.trim="$v.form.password.$model"
              type="password"
              autocomplete="new-password"
              name="password"
              placeholder="Passwort"
            >
            <div class="password-instructions">
              <span
                v-if="!$v.form.password.lower || !$v.form.password.upper || !$v.form.password.digit || !$v.form.password.minLength"
                class="text"
              >Mindestens</span>
              <span v-if="!$v.form.password.lower">1 Kleinbuchstabe</span>
              <span v-if="!$v.form.password.upper">1 Großbuchstabe</span>
              <span v-if="!$v.form.password.digit">1 Ziffer</span>
              <span v-if="!$v.form.password.minLength">{{ $v.form.password.$params.minLength.min }} Zeichen</span>
            </div>
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.repeatPassword.$error}"
          >
            <label for="repeat-password">Passwort wiederholen</label>
            <input
              v-model="$v.form.repeatPassword.$model"
              type="password"
              autocomplete="new-password"
              name="repeat-password"
              placeholder="Passwort wiederholen"
            >
          </div>
          <SubmitBtn
            v-if="!currentDb"
            label="Datenbank anlegen"
            :submitting="submitting"
            :disabled="$v.form.$invalid"
            @click.native.prevent="addDb"
          />
          <SubmitBtn
            v-else
            label="Datenbank aktualisieren"
            :submitting="submitting"
            :disabled="$v.form.$invalid"
            @click.native.prevent="updateDb"
          />
          <a
            v-if="currentDb"
            :href="`https://${currentDb.host.url}/phpmyadmin`"
            target="_blank"
            class="btn mt-sm"
          >
            phpMyAdmin
          </a>
          <SubmitBtn
            v-if="currentDb"
            class="mt-sm"
            label="Datenbank löschen"
            :submitting="deleting"
            red
            @click.native.prevent="deleteDb"
          />
        </form>
      </template>
    </Modal>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { required, requiredIf, minLength, sameAs, helpers } from 'vuelidate/lib/validators'

// Custom Validators
const lower = helpers.regex('lower', /[a-z]+/)
const upper = helpers.regex('upper', /[A-Z]+/)
const digit = helpers.regex('digit', /[0-9]+/)

export default {
  name: 'Databases',

  components: {
    Modal: () => import('../components/Modal'),
    Dialog: () => import('../components/Dialog'),
    SubmitBtn: () => import('../components/SubmitBtn'),
    Database: () => import('../components/databases/Database'),
  },

  data() {
    return {
      loading: false,
      submitting: false,
      deleting: false,

      dbs: [],
      form: {
        name: '',
        dbUser: '',
        password: '',
        repeatPassword: ''
      },
      currentDb: null,

      currentSort: 'name',
      currentSortDir: 'asc',
      searchQuery: '',
    }
  },

  validations: {
    form: {
      name: {
        required: requiredIf(function () {
          return !this.currentDb
        })
      },
      dbUser: { required },
      password: { required, digit, lower, upper, minLength: minLength(8) },
      repeatPassword: { required, sameAsPassword: sameAs('password') }
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'customer',
      'box',
      'accountType',
      'id',
      'isAdmin',
      'isCustomerAdmin'
    ]),

    sortedDbs() {
      var filteredDbs = this.dbs.filter(db => {
        if (db) {
          return db.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                            db.dbUser.toLowerCase().includes(this.searchQuery.toLowerCase())

        } else {
          return null
        }
      })
      return _.orderBy(filteredDbs, this.currentSort, this.currentSortDir)
    },

    accountTypeLetter() {
      if (this.box.identifier) {
        return this.box.identifier;
      }
      else {
        return this.accountType === 'organisation' ? 'C' : 'U'
      }
    },

    dbPrefix() {
      return `${this.accountTypeLetter}${this.customer ? this.customer.id : this.user.id}B${this.box.id}`
    }
  },

  watch: {
    'box': {
      immediate: true,
      handler() {
        this.fetch()
      }
    }
  },

  created() {
    this.loading = true
  },

  methods: {
    async fetch() {
      if (this.user && this.box) {
        this.loading = true
        const dbs = await this.$axios.get(`/api/${this.accountType}/${this.id}/box/${this.box.id}/database`)
        this.dbs = dbs.data
        console.log(this.dbs)
        this.loading = false
      }
    },

    add() {
      if (this.currentDb) {
        this.currentDb = null
        this.clearForm()
      }
      this.openModal()
    },

    update(db) {
      this.currentDb = db
      this.form.name = db.name
      this.form.dbUser = db.dbUser
      this.openModal()
    },

    openModal() {
      if (this.$refs && this.$refs.modal) {
        this.$refs.modal.open()
      }
    },

    closeModal() {
      this.$refs.modal.close()
    },

    clearForm() {
      this.form.name = ''
      this.form.dbUser = ''
      this.form.dbPassword = ''
      this.$v.$reset()
    },

    prefix(userid, boxid) {
      if (this.box.identifier) {
        return `${this.box.identifier + userid}B${boxid}_`;
      }
      return `U${userid}B${boxid}_`
    },

    charsLeft(userid, boxid) {
      const string = `U${userid}B${boxid}_`
      return 16 - string.length - this.form.dbUser.length
    },

    async addDb() {
      this.$v.$touch()
      if (!this.$v.form.name.required) {
        this.$notify.error(`Name benötigt`)
      } else if (!this.$v.form.dbUser.required) {
        this.$notify.error(`Datenbankbenutzer benötigt`)
      } else if (!this.$v.form.password.required) {
        this.$notify.error(`Passwort benötigt`)
      } else if (this.$v.form.password.$error) {
        this.$notify.error(`Ungültiges Passwort`)
      } else if (!this.$v.form.repeatPassword.required) {
        this.$notify.error(`Passwort bestätigen`)
      } else if (!this.$v.form.repeatPassword.sameAsPassword) {
        this.$notify.error(`Passwörter stimmen nicht überein`)
      } else {
        this.submitting = true
        this.$axios.post(`/api/${this.accountType}/${this.id}/box/${this.box.id}/database`, {
          name: this.form.name,
          dbUser: this.form.dbUser,
          dbPassword: this.form.password
        }).then(async () => {
          this.closeModal()
          await this.fetch()
          this.submitting = false
          this.$notify.success('Datenbank erstellt')
        }).catch(error => {
          console.log(error.response)
          // if(error.response.data.error.includes('characters')) {
          //   this.$notify(`Maximal erlaubte Zeichen: 12`)
          // }
          if (error.response.data.error.includes('limit')) {
            this.$notify(`Datenbank-Limit für diese Box erreicht.`)
          }
          this.submitting = false
        })
      }
    },

    async updateDb() {
      this.submitting = true
      this.$axios.put(`/api/${this.accountType}/${this.id}/box/${this.box.id}/database/${this.currentDb.id}`, {
        dbUser: this.form.dbUser,
        dbPassword: this.form.password
      }).then(async () => {
        await this.fetch()
        this.submitting = false
        this.$notify.success('Datenbank aktualisiert')
      }).catch(error => {
        this.submitting = false
        console.log(error.response)
      })
    },

    async deleteDb() {
      const confirmation = await this.$refs.dialog.open(`
                <h3 class="mb">Datenbank löschen</h3>
                <p>Soll die Datenbank <strong>${this.currentDb.name}</strong> wirklich gelöscht werden?</p>
            `)
      if (confirmation === 'ok') {
        this.deleting = true
        this.$axios.delete(`/api/${this.accountType}/${this.id}/box/${this.box.id}/database/${this.currentDb.id}`)
          .then(async () => {
            this.closeModal()
            await this.fetch()
            this.deleting = false
            this.$notify.success('Datenbank gelöscht')
          })
          .catch(error => {
            console.log(error, error.response)
            this.deleting = false
          })
      }
    },

    sort(s) {
      if (s === this.currentSort) this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      this.currentSort = s
    },

    clearFilter() {
      this.searchQuery = ''
      this.currentSort = 'name'
      this.currentSortDir = 'asc'
    }
  }
}
</script>

<style lang="scss" scoped>
    .databases-table {
        .row

    {
        grid-template-columns: 1fr 1fr ;
    }

    }

    .prefix {
        position: absolute;
        left: 0;
        bottom: 8px;
        color: $color-gray;
    }

    input.has-prefix {
        padding-left: 65px;
    }
</style>