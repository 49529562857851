var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("\n      Benutzer\n    ")]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openModal($event)
                }
              }
            },
            [
              _c("span", { staticClass: "plus" }, [_vm._v("+")]),
              _vm._v(" Hinzufügen\n      ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.loading && !_vm.users.length
            ? _c("div", { staticClass: "skeleton info" }, [
                _vm._v("\n      Benutzer werden geladen...\n    ")
              ])
            : _vm.users.length
            ? _c("UserList", { attrs: { users: _vm.users } })
            : _c("div", { staticClass: "info" }, [
                _vm._v(
                  '\n      Noch keine Benutzer vorhanden. Klicke auf "Hinzufügen", um einen ersten Benutzer zu erstellen.\n    '
                )
              ])
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [_c("AddUserForm", { on: { userAdded: _vm.userAdded } })]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Dialog", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }