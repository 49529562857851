var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.server
        ? _c(
            "div",
            { staticClass: "server container" },
            [
              _c("router-link", { attrs: { to: "/servers" } }, [
                _vm._v("\n      Zurück\n    ")
              ]),
              _vm._v(" "),
              _c("h1", { staticClass: "mb-lg" }, [
                _vm._v("\n      " + _vm._s(_vm.server.name) + "\n      "),
                _c(
                  "span",
                  {
                    staticClass: "status",
                    class: [
                      { online: _vm.server.status === "Online" },
                      { offline: _vm.server.status === "Offline" },
                      { maintenance: _vm.server.status === "Maintenance" }
                    ]
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.server.status) + "\n      "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.serverHealth && _vm.serverHealth.length
                ? _c("div", [
                    _c("div", { staticClass: "grid" }, [
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("MemoryChart", {
                            attrs: {
                              data: _vm.serverHealth,
                              "latest-entry": _vm.serverHealth[0]
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("CpuChart", {
                            attrs: {
                              data: _vm.serverHealth,
                              "latest-entry": _vm.serverHealth[0]
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("DiskChart", {
                            attrs: {
                              data: _vm.serverHealth,
                              "latest-entry": _vm.serverHealth[0]
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "services mt-lg" }, [
                      _c("h2", { staticClass: "mb" }, [
                        _vm._v("\n          Services\n        ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "grid" },
                        _vm._l(_vm.serverHealth[0].service, function(
                          service,
                          key
                        ) {
                          return _c("li", { key: key, staticClass: "col-4" }, [
                            _c(
                              "div",
                              {
                                staticClass: "service",
                                class: { active: service === "active" }
                              },
                              [
                                _c("h4", [_vm._v(_vm._s(key))]),
                                _vm._v(" "),
                                _vm.serviceUpdating === key
                                  ? _c("div", { staticClass: "updating" }, [
                                      _vm._v(
                                        "\n                Aktualisieren...\n              "
                                      )
                                    ])
                                  : _c("div", { staticClass: "actions" }, [
                                      service === "active"
                                        ? _c(
                                            "button",
                                            {
                                              attrs: {
                                                title: "Service neu starten"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.setService(
                                                    key,
                                                    "restart"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    viewBox: "0 0 350 350"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    staticClass: "cls-1",
                                                    attrs: {
                                                      d:
                                                        "M350,175A175,175,0,1,1,175,0,175,175,0,0,1,350,175ZM235.1,114.9a84.77,84.77,0,0,0-70.7-24.23l-8.71-18.12L122,122l59.66,4.5L173.71,110A65,65,0,1,1,129,221L114.9,235.1A85,85,0,0,0,235.1,114.9Z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      service === "active"
                                        ? _c(
                                            "button",
                                            {
                                              attrs: {
                                                title: "Service anhalten"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.setService(
                                                    key,
                                                    "stop"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    viewBox: "0 0 350 350"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    staticClass: "cls-1",
                                                    attrs: {
                                                      d:
                                                        "M350,175A175,175,0,1,1,175,0,175,175,0,0,1,350,175ZM150,233.87V116.13A16.13,16.13,0,0,0,133.87,100H116.13A16.13,16.13,0,0,0,100,116.13V233.87A16.13,16.13,0,0,0,116.13,250h17.74A16.13,16.13,0,0,0,150,233.87Zm100,0V116.13A16.13,16.13,0,0,0,233.87,100H216.13A16.13,16.13,0,0,0,200,116.13V233.87A16.13,16.13,0,0,0,216.13,250h17.74A16.13,16.13,0,0,0,250,233.87Z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      service !== "active"
                                        ? _c(
                                            "button",
                                            {
                                              attrs: {
                                                title: "Service starten"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.setService(
                                                    key,
                                                    "start"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    viewBox: "0 0 350 350"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    staticClass: "cls-1",
                                                    attrs: {
                                                      d:
                                                        "M350,175A175,175,0,1,1,175,0,175,175,0,0,1,350,175ZM119.94,126.1v97.8a16.13,16.13,0,0,0,23.34,14.43l97.8-48.9a16.13,16.13,0,0,0,0-28.86l-97.8-48.9A16.13,16.13,0,0,0,119.94,126.1Z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                              ]
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                : _c("div", [
                    _c("h3", [
                      _vm._v("Es wurden noch keine Live-Daten gesammelt.")
                    ])
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "server-settings mt-xl" }, [
                _c("h2", { staticClass: "mb" }, [
                  _vm._v("\n        Server-Einstellungen\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    attrs: { autocomplete: "off" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.updateServer($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "grid" }, [
                      _c(
                        "div",
                        { staticClass: "fieldset col-12 col-xs-6 col-sm-4" },
                        [
                          _c("label", { attrs: { for: "name" } }, [
                            _vm._v("Servername")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.name,
                                expression: "form.name"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "name",
                              placeholder: "Name"
                            },
                            domProps: { value: _vm.form.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "name", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "fieldset col-12 col-xs-6 col-sm-4" },
                        [
                          _c("label", [_vm._v("Serverstatus")]),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              options: _vm.states,
                              placeholder: "Serverstatus",
                              clearable: false,
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.form.status,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "fieldset col-12 col-xs-6 col-sm-4" },
                        [
                          _c("label", { attrs: { for: "capacity" } }, [
                            _vm._v("Max. Benutzer")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.capacity,
                                expression: "form.capacity"
                              }
                            ],
                            attrs: {
                              type: "number",
                              name: "capacity",
                              placeholder: "Max. Benutzer"
                            },
                            domProps: { value: _vm.form.capacity },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "capacity",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "fieldset col-12 col-xs-6 col-sm-4" },
                        [
                          _c("label", { attrs: { for: "ip" } }, [
                            _vm._v("Server-IP")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.ip,
                                expression: "form.ip"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "ip",
                              placeholder: "IP-Adresse"
                            },
                            domProps: { value: _vm.form.ip },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "ip", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "fieldset col-12 col-xs-6 col-sm-4" },
                        [
                          _c("label", { attrs: { for: "url" } }, [
                            _vm._v("Server-Url")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.url,
                                expression: "form.url"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "url",
                              placeholder: "Server-Url"
                            },
                            domProps: { value: _vm.form.url },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "url", $event.target.value)
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "fieldset col-12 col-xs-6 col-sm-4" },
                        [
                          _c("label", { attrs: { for: "token" } }, [
                            _vm._v("API-Key")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.token,
                                expression: "form.token"
                              }
                            ],
                            attrs: {
                              type: "password",
                              name: "token",
                              autocomplete: "new-password",
                              placeholder: "****************"
                            },
                            domProps: { value: _vm.form.token },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "token", $event.target.value)
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btn-set mt" },
                      [
                        _c("SubmitBtn", {
                          attrs: {
                            label: "Speichern",
                            submitting: _vm.updating
                          }
                        }),
                        _vm._v(" "),
                        _c("SubmitBtn", {
                          attrs: {
                            label: "Server löschen",
                            submitting: _vm.deleting,
                            red: ""
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.removeServer($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Dialog", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }