var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "servers container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Server-Übersicht\n      "),
          _vm.servers.length
            ? _c("span", [_vm._v(_vm._s(_vm.servers.length))])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openModal($event)
                }
              }
            },
            [
              _c("span", { staticClass: "plus" }, [_vm._v("+")]),
              _vm._v(" Hinzufügen\n      ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.loading && !_vm.servers.length
            ? _c("div", { staticClass: "skeleton info" }, [
                _vm._v("\n      Server werden geladen...\n    ")
              ])
            : _vm.servers.length
            ? _c("ServerList", {
                attrs: {
                  servers: _vm.servers,
                  "server-health": _vm.serverHealth
                },
                on: { openModal: _vm.openModal }
              })
            : _c("div", { staticClass: "info" }, [
                _vm._v("\n      Noch keine Server vorhanden.\n    ")
              ])
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("h2", { staticClass: "mb" }, [
                  _vm._v("\n        Server hinzufügen\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addServer($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "name" } }, [
                        _vm._v("Servername")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "name",
                          placeholder: "Name des Servers"
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "name", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "capacity" } }, [
                        _vm._v("Benutzer")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.capacity,
                            expression: "form.capacity"
                          }
                        ],
                        attrs: {
                          type: "number",
                          name: "capacity",
                          placeholder: "Max. erlaubte Benutzer"
                        },
                        domProps: { value: _vm.form.capacity },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "capacity", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "ip" } }, [
                        _vm._v("Server-IP")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.ip,
                            expression: "form.ip"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "ip",
                          placeholder: "IP-Adresse"
                        },
                        domProps: { value: _vm.form.ip },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "ip", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "url" } }, [
                        _vm._v("Server-Url")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.url,
                            expression: "form.url"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "url",
                          placeholder: "Server-Url"
                        },
                        domProps: { value: _vm.form.url },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "url", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "name" } }, [
                        _vm._v("API-Key")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.token,
                            expression: "form.token"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "token",
                          placeholder: "16-Stelliger Token"
                        },
                        domProps: { value: _vm.form.token },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "token", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      attrs: {
                        label: "Server anlegen",
                        submitting: _vm.submitting
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }