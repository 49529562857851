var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container register" }, [
    !_vm.comingFromMail
      ? _c("div", [
          _vm.submitState != "success"
            ? _c("div", [
                _c("div", { staticClass: "mb-lg" }, [
                  _c("h1", { staticClass: "mb-sm" }, [
                    _vm._v("\n          Konto erstellen\n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    [
                      _vm._v("\n          Bereits registriert? "),
                      _c("router-link", { attrs: { to: "/login" } }, [
                        _vm._v("\n            Hier anmelden\n          ")
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "register-form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.register($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "fieldsetgroup" }, [
                      _c(
                        "div",
                        {
                          staticClass: "fieldset firstName",
                          class: {
                            "fieldset-error": _vm.$v.form.firstName.$error
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.form.firstName.$model,
                                expression: "$v.form.firstName.$model"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "firstName",
                              placeholder: "Vorname",
                              required: ""
                            },
                            domProps: { value: _vm.$v.form.firstName.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.form.firstName,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.form.firstName == ""
                            ? _c(
                                "span",
                                { staticClass: "required-notification" },
                                [_vm._v("Vorname benötigt")]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "required-notification",
                                  style: { color: "white" }
                                },
                                [_vm._v("Vorname benötigt ")]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "fieldset lastName",
                          class: {
                            "fieldset-error": _vm.$v.form.lastName.$error
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.form.lastName.$model,
                                expression: "$v.form.lastName.$model"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "lastName",
                              placeholder: "Nachname"
                            },
                            domProps: { value: _vm.$v.form.lastName.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.form.lastName,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.form.lastName == ""
                            ? _c(
                                "span",
                                { staticClass: "required-notification" },
                                [_vm._v("Nachname benötigt")]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "required-notification",
                                  style: { color: "white" }
                                },
                                [_vm._v("Nachname benötigt ")]
                              )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset country",
                        class: { "fieldset-error": _vm.$v.form.country.$error }
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            options: _vm.countries,
                            placeholder: "Land",
                            clearable: false,
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.$v.form.country.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.form.country, "$model", $$v)
                            },
                            expression: "$v.form.country.$model"
                          }
                        }),
                        _vm._v(" "),
                        _vm.form.country == ""
                          ? _c(
                              "span",
                              { staticClass: "required-notification" },
                              [_vm._v("Auswahl benötigt")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "required-notification",
                                style: { color: "white" }
                              },
                              [_vm._v("Nachname benötigt ")]
                            )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset street",
                        class: { "fieldset-error": _vm.$v.form.street.$error }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.form.street.$model,
                              expression: "$v.form.street.$model"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "street",
                            placeholder: "Straße und Hausnummer"
                          },
                          domProps: { value: _vm.$v.form.street.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.street,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.form.street == ""
                          ? _c(
                              "span",
                              { staticClass: "required-notification" },
                              [_vm._v("Adresse benötigt")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "required-notification",
                                style: { color: "white" }
                              },
                              [_vm._v("Nachname benötigt ")]
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldsetgroup" }, [
                      _c(
                        "div",
                        {
                          staticClass: "fieldset zip",
                          class: { "fieldset-error": _vm.$v.form.zip.$error }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.form.zip.$model,
                                expression: "$v.form.zip.$model"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "zip",
                              placeholder: "PLZ"
                            },
                            domProps: { value: _vm.$v.form.zip.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.form.zip,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.form.zip == ""
                            ? _c(
                                "span",
                                { staticClass: "required-notification" },
                                [_vm._v("Postleitzahl benötigt")]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "required-notification",
                                  style: { color: "white" }
                                },
                                [_vm._v("Nachname benötigt ")]
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "fieldset city",
                          class: { "fieldset-error": _vm.$v.form.city.$error }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.form.city.$model,
                                expression: "$v.form.city.$model"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "city",
                              placeholder: "Stadt"
                            },
                            domProps: { value: _vm.$v.form.city.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.form.city,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.form.city == ""
                            ? _c(
                                "span",
                                { staticClass: "required-notification" },
                                [_vm._v("Stadt benötigt")]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "required-notification",
                                  style: { color: "white" }
                                },
                                [_vm._v("Nachname benötigt ")]
                              )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset phone",
                        class: { "fieldset-error": _vm.$v.form.phone.$error }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.form.phone.$model,
                              expression: "$v.form.phone.$model"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "phone",
                            placeholder: "Telefon"
                          },
                          domProps: { value: _vm.$v.form.phone.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.phone,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.form.phone == ""
                          ? _c(
                              "span",
                              { staticClass: "required-notification" },
                              [_vm._v("Telefonnummer benötigt")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "required-notification",
                                style: { color: "white" }
                              },
                              [_vm._v("Nachname benötigt ")]
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset email",
                        class: { "fieldset-error": _vm.$v.form.email.$error }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.form.email.$model,
                              expression: "$v.form.email.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "email",
                            autocomplete: "username",
                            name: "email",
                            placeholder: "E-Mail"
                          },
                          domProps: { value: _vm.$v.form.email.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.email,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.form.email == ""
                          ? _c(
                              "span",
                              { staticClass: "required-notification" },
                              [_vm._v("Email benötigt")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "required-notification",
                                style: { color: "white" }
                              },
                              [_vm._v("Nachname benötigt ")]
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset password",
                        class: { "fieldset-error": _vm.$v.form.password.$error }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.form.password.$model,
                              expression: "$v.form.password.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "password",
                            autocomplete: "new-password",
                            name: "password",
                            placeholder: "Passwort"
                          },
                          domProps: { value: _vm.$v.form.password.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.password,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.form.password == ""
                          ? _c(
                              "span",
                              { staticClass: "required-notification" },
                              [_vm._v("Passwort benötigt")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "required-notification",
                                style: { color: "white" }
                              },
                              [_vm._v("Nachname benötigt ")]
                            ),
                        _vm._v(" "),
                        _c("div", { staticClass: "password-instructions" }, [
                          !_vm.$v.form.password.lower ||
                          !_vm.$v.form.password.upper ||
                          !_vm.$v.form.password.digit ||
                          !_vm.$v.form.password.minLength
                            ? _c("span", { staticClass: "text" }, [
                                _vm._v("Mindestens")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.lower
                            ? _c("span", [_vm._v("1 Kleinbuchstabe")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.upper
                            ? _c("span", [_vm._v("1 Großbuchstabe")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.digit
                            ? _c("span", [_vm._v("1 Ziffer")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.minLength
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$v.form.password.$params.minLength.min
                                  ) + " Zeichen"
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset",
                        class: {
                          "fieldset-error": _vm.$v.form.repeatPassword.$error
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.form.repeatPassword.$model,
                              expression: "$v.form.repeatPassword.$model"
                            }
                          ],
                          attrs: {
                            type: "password",
                            autocomplete: "new-password",
                            name: "repeat-password",
                            placeholder: "Passwort wiederholen"
                          },
                          domProps: {
                            value: _vm.$v.form.repeatPassword.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.repeatPassword,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.form.repeatPassword == ""
                          ? _c(
                              "span",
                              { staticClass: "required-notification" },
                              [_vm._v("Passwortbestätigung benötigt")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "required-notification",
                                style: { color: "white" }
                              },
                              [_vm._v("Nachname benötigt ")]
                            )
                      ]
                    ),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      attrs: {
                        label: "Konto erstellen",
                        submitting: _vm.submitting
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "legend",
                      { staticClass: "mt" },
                      [
                        _vm._v(
                          "\n          Mit der Erstellung des Kontos bestätigst du, dass du die "
                        ),
                        _c("router-link", { attrs: { to: "/agb" } }, [
                          _vm._v(
                            "\n            Nutzungsbedingungen\n          "
                          )
                        ]),
                        _vm._v(" und die "),
                        _c("router-link", { attrs: { to: "/datenschutz" } }, [
                          _vm._v(
                            "\n            Datenschutzrichtlinien\n          "
                          )
                        ]),
                        _vm._v(
                          " gelesen hast und diese in vollem Umfang akzeptierst.\n        "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _c("div", [
                _c(
                  "form",
                  {
                    staticClass: "auth-form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.auth($event)
                      }
                    }
                  },
                  [
                    _c("h1", { staticClass: "mb-sm" }, [
                      _vm._v("\n          Registrierung bestätigen\n        ")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("Ein Verifizierungscode wurde an "),
                      _c("strong", [_vm._v(_vm._s(_vm.registeredMail))]),
                      _vm._v(
                        " geschickt. Klicke auf den enthaltenen Link, oder trage den Code in das folgende Feld ein, um die Registrierung abzuschließen."
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset mt" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.authkey,
                            expression: "authkey",
                            modifiers: { trim: true }
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "authkey",
                          placeholder: "6-Stelliger Code"
                        },
                        domProps: { value: _vm.authkey },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.authkey = $event.target.value.trim()
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      staticClass: "mb",
                      attrs: {
                        label: "Registrierung abschließen",
                        submitting: _vm.submitting
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "link resend-btn reveal",
                        attrs: { href: "#", disabled: _vm.submitting },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.resend($event)
                          }
                        }
                      },
                      [_vm._v("\n          Neuen Code anfordern\n        ")]
                    )
                  ],
                  1
                )
              ])
        ])
      : _c("div", { staticClass: "auth-success" }, [
          _c("h2", [_vm._v("Registrierung wird abgeschlossen")])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }