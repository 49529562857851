var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.box
    ? _c(
        "div",
        { staticClass: "box-settings container" },
        [
          _c("div", { staticClass: "page-head mb" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v("\n      " + _vm._s(_vm.box.name) + "\n    ")
            ]),
            _vm._v(" "),
            _vm.accountType === "user" ||
            (_vm.accountType === "organisation" && _vm.isCustomerAdmin) ||
            _vm.isAdmin
              ? _c("div", { staticClass: "actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openModal($event)
                        }
                      }
                    },
                    [_vm._v("\n        Einstellungen\n      ")]
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "product-info mb-lg" }, [
            _c("li", [
              _c("span", [_vm._v("IP")]),
              _vm._v(" " + _vm._s(_vm.box.server.ip))
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("NS1")]),
              _vm._v(" " + _vm._s(_vm.box.product.ns1))
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("NS2")]),
              _vm._v(" " + _vm._s(_vm.box.product.ns2))
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v("Status")]),
              _vm._v(" " + _vm._s(_vm.box.isLocked ? "Gesperrt" : "Aktiv"))
            ])
          ]),
          _vm._v(" "),
          _c("Quota", { attrs: { box: _vm.box } }),
          _vm._v(" "),
          _vm.accountType === "user" ||
          (_vm.accountType === "organisation" && _vm.isCustomerAdmin) ||
          _vm.isAdmin
            ? _c("UpgradeBox", {
                attrs: {
                  box: _vm.box,
                  userid: _vm.id,
                  accounttype: _vm.accountType
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("Modal", {
            ref: "modal",
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c("h2", { staticClass: "mb" }, [
                        _vm._v("\n        Box-Einstellungen\n      ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "fieldset",
                              class: {
                                "fieldset-error": _vm.$v.form.name.$error
                              }
                            },
                            [
                              _c("label", { attrs: { for: "name" } }, [
                                _vm._v("Boxname")
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.form.name.$model,
                                    expression: "$v.form.name.$model"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  name: "name",
                                  placeholder: "Bezeichnung der Box",
                                  autocomplete: "off"
                                },
                                domProps: { value: _vm.$v.form.name.$model },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.form.name,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "fieldset" }, [
                            _c("label", [_vm._v("Standard-Box")]),
                            _vm._v(" "),
                            _c("label", { staticClass: "switch" }, [
                              _vm.form.isDefault
                                ? _c("span", [_vm._v("Favorisiert")])
                                : _c("span", [_vm._v("Nicht favorisiert")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.isDefault,
                                    expression: "form.isDefault"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.form.isDefault)
                                    ? _vm._i(_vm.form.isDefault, null) > -1
                                    : _vm.form.isDefault
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.form.isDefault,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.form,
                                            "isDefault",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.form,
                                            "isDefault",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.form, "isDefault", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "slider" })
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "fieldset",
                              class: {
                                "fieldset-error": _vm.$v.form.password.$error
                              }
                            },
                            [
                              _c("label", { attrs: { for: "password" } }, [
                                _vm._v("Box-Passwort (FTP/SSH)")
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.form.password.$model,
                                    expression: "$v.form.password.$model"
                                  }
                                ],
                                attrs: {
                                  type: "password",
                                  autocomplete: "new-password",
                                  name: "password",
                                  placeholder: "Globales Passwort für FTP/SSH"
                                },
                                domProps: {
                                  value: _vm.$v.form.password.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.form.password,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "password-instructions" },
                                [
                                  !_vm.$v.form.password.lower ||
                                  !_vm.$v.form.password.upper ||
                                  !_vm.$v.form.password.digit ||
                                  !_vm.$v.form.password.minLength
                                    ? _c("span", { staticClass: "text" }, [
                                        _vm._v("Mindestens")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.form.password.lower
                                    ? _c("span", [_vm._v("1 Kleinbuchstabe")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.form.password.upper
                                    ? _c("span", [_vm._v("1 Großbuchstabe")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.form.password.digit
                                    ? _c("span", [_vm._v("1 Ziffer")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.form.password.minLength
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$v.form.password.$params
                                              .minLength.min
                                          ) + " Zeichen"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("SubmitBtn", {
                            attrs: {
                              label: "Änderungen speichern",
                              submitting: _vm.submitting
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.updateBox($event)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn mt-sm",
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.box.server.url +
                                  "/phpmyadmin",
                                target: "_blank"
                              }
                            },
                            [_vm._v("phpMyAdmin")]
                          ),
                          _vm._v(" "),
                          _vm.isAdmin
                            ? _c("SubmitBtn", {
                                staticClass: "mt-sm",
                                attrs: {
                                  label: "Box löschen",
                                  submitting: _vm.deleting,
                                  red: ""
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.deleteBox($event)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3734862154
            )
          }),
          _vm._v(" "),
          _c("Dialog", { ref: "dialog" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }