<template>
  <div class="backups container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Backups
        <span v-if="backups.length && box">{{ backups.length }}/{{ box.product.backups }}</span>
      </h1>
      <div class="actions">
        <button
          class="btn"
          @click.prevent="addBackup"
        >
          <span class="plus">+</span> Erstellen
        </button>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="loading"
        class="skeleton info"
      >
        Backups werden geladen...
      </div>
      <BackupList
        v-else
        :backups="backups"
        @fetch="fetchBackups"
      />
    </transition>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Backups',
  components: {
    BackupList: () => import('../components/backups/BackupList')
  },

  data() {
    return {
      loading: false,
      backups: [],
      submitting: false
    }
  },

  computed: {
    ...mapGetters([
      'box',
      'accountType',
      'id'
    ])
  },

  watch: {
    'box': {
      immediate: true,
      handler() {
        this.fetchBackups()
      }
    }
  },

  async created() {
    this.loading = true
  },

  methods: {
    openModal() {
      this.$refs.modal.open()
    },

    closeModal() {
      this.$refs.modal.close()
    },

    async fetchBackups() {
      if(this.id && this.box) {
        const backups = await this.$axios.get(`/api/${this.accountType}/${this.id}/box/${this.box.id}/backups`)
        this.backups = backups.data.reverse()
        this.loading = false
      }
    },

    async addBackup() {
      this.$notify.success('Backup wird erstellt.')
      this.$axios.post(`/api/${this.accountType}/${this.id}/box/${this.box.id}/backup`)
    }
  }
}
</script>