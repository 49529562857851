<template>
  <div class="domains container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Domains
        <span v-if="sortedDomains && sortedDomains.length">{{ sortedDomains.length }}</span>
      </h1>
      <div
        v-if="accountType === 'user' || (accountType === 'organisation' && isCustomerAdmin) || isAdmin"
        class="actions"
      >
        <button
          class="btn"
          @click.prevent="openModal"
        >
          <span class="plus">+</span> Hinzufügen
        </button>
      </div>
    </div>

    <div class="search mb-lg">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Domains filtern"
      >
      <a
        v-if="searchQuery"
        href="#"
        class="icon clear"
        @click.prevent="clearFilter"
      ><span /><span /></a>
    </div>

    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <div
        v-if="loading && !domains.length"
        key="loading"
        class="skeleton info"
      >
        Domains werden geladen...
      </div>
      <div
        v-else-if="domains.length"
        key="table"
        class="domains-table table"
      >
        <!-- Head -->
        <div
          key="head"
          class="row head"
          :class="currentSortDir"
        >
          <div
            class="cell"
            @click.prevent="sort('name')"
          >
            Domain
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>
        </div>
        <!-- Body -->
        <Domain
          v-for="domain in sortedDomains"
          :key="domain.id"
          :domain="domain"
          @edit="edit"
          @fetchDomains="fetchDomains"
          @checkMapped="checkMapped"
        />
        <div
          v-if="domains.length && sortedDomains < 1"
          key="no-results"
          class="row no-results"
        >
          <div class="cell">
            Keine Domains gefunden
          </div>
        </div>
      </div>
      <div
        v-else
        key="noresults"
        class="info"
      >
        <template v-if="isAdmin || isCustomerAdmin">
          Es wurden noch keine Domains für diese Box angelegt. Klicke auf "Hinzufügen", um eine erste Domain anzulegen.
        </template>
        <template v-else>
          Es wurden noch keine Domains für diese Box angelegt.
        </template>
      </div>
    </transition>
    
    <Modal ref="add">
      <template #content>
        <h2 class="mb">
          Domain anlegen
        </h2>
        <form @submit.prevent="form.vHost === 'vHost' || form.domainAvailable || form.authCode !== '' ? addDomain() : checkDomain()">
          <div class="fieldset domain">
            <input
              v-model="form.selectedDomain"
              type="text"
              name="domain"
              placeholder="domain.tld"
              @input="onChange"
            >
          </div>
          <div class="fieldset checkbox">
            <label>
              Domainregistrierung / Providerwechsel
              <input
                v-model="form.vHost"
                type="radio"
                value="register"
                name="register"
              >
              <span class="checkmark" />
            </label>
          </div>
          <div class="fieldset checkbox">
            <label>
              Virtual Host Eintrag (keine Registrierung)
              <input
                v-model="form.vHost"
                type="radio"
                value="vHost"
                name="vhost"
              >
              <span class="checkmark" />
            </label>
          </div>
          <div
            v-if="form.vHost !== 'vHost' && form.checked && form.domainAvailable"
            class="info mt"
          >
            <h4 class="mb-sm">
              Domain verfügbar
            </h4>
            <div class="price mb-sm">
              {{ form.setup.toLocaleString('de-DE', {
                style: 'currency', 
                currency: 'EUR', 
                minimumFractionDigits: 2}) }} einmalige Einrichtungsgebühr<br>
              {{ form.price.toLocaleString('de-DE', {
                style: 'currency', 
                currency: 'EUR', 
                minimumFractionDigits: 2}) }} monatl. zzgl. 19% MwSt.
            </div>
            <p>
              Mir ist bekannt, dass die Mindestvertragslaufzeit für Domains 12 Monate beträgt. Ebenso habe ich die AGB zur Kenntnis genommen und akzeptiere diese in vollem Umfang.<br><br>
              Ich bestätige, dass ich der Inhaber der Domain bin, oder vom Inhaber zum Transfer der Domain beauftragt wurde und bevollmächtigte hiermit {{ company }} alle erforderlichen Schritte für den Domainumzug zu unternehmen.
            </p>
          </div>
          <div
            v-else-if="form.vHost !== 'vHost' && form.checked && !form.domainAvailable"
            class="mt"
          >
            <div class="fieldset">
              <label for="authcode">Authcode/AuthInfo</label>
              <input
                v-model="form.authCode"
                type="text"
                name="authcode"
                placeholder="Authcode"
              >
            </div>
          </div>
          <SubmitBtn
            class="mt"
            :label="!form.domainAvailable && !form.authCode ? (form.vHost === 'vHost' ? 'vHost anlegen' : 'Domain prüfen') : !form.domainAvailable && form.authCode && form.vHost !== 'vHost' ? 'Domain transferieren' : (form.vHost !== 'vHost' ? 'Domain registrieren' : 'vHost anlegen')"
            :submitting="adding"
            :disabled="!form.selectedDomain"
          />
        </form>
      </template>
    </Modal>
    <Modal ref="edit">
      <template #content>
        <h2>
          {{ currentDomain.name }}
        </h2>
        <h4
          class="mb-lg ip"
        >
          IP: {{ box.server.ip }}
        </h4>
        <form
          class="mb-xl"
          @submit.prevent="updateDomain"
        >
          <h3 class="mb">
            Domain-Einstellungen
          </h3>
          <div class="fieldset">
            <label>Neuer Box zuweisen</label>
            <v-select
              v-model="editForm.newBox"
              :options="boxes"
              placeholder="Box wählen"
              :clearable="true"
              label="name"
              autocomplete="off"
            />
          </div>
          <div class="fieldset">
            <label for="name">TTL</label>
            <input
              v-model="editForm.ttl"
              type="number"
              name="ttl"
              placeholder="600"
            >
          </div>
          <div class="fieldset">
            <label>Auto-Renew</label>
            <label class="switch">
              <span v-if="editForm.autoRenew">Aktiviert</span>
              <span v-else>Deaktiviert</span>
              <input
                v-model="editForm.autoRenew"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>
          <SubmitBtn
            label="Änderungen speichern"
            :submitting="updating"
          />
          <SubmitBtn
            class="mt-sm"
            label="Domain löschen"
            :submitting="deleting"
            red
            @click.native.prevent="deleteDomain"
          />
        </form>
        <Subdomains
          :subdomains="subdomains"
          :domainid="currentDomain.id"
          :userid="id"
          :boxid="box.id"
          @fetch="fetchDomains"
        />
        <Dns
          :records="currentDomain.dNSRecords"
          :userid="id"
          :boxid="box.id"
          :domainid="currentDomain.id"
          @fetch="fetchDomains"
        />
      </template>
    </Modal>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import _ from 'lodash'

export default {
  name: 'Domains',

  components: {
    Modal: () => import('../components/Modal'),
    Dialog: () => import('../components/Dialog'),
    SubmitBtn: () => import('../components/SubmitBtn'),
    Domain: () => import('../components/domains/Domain'),
    Dns: () => import('../components/domains/Dns'),
    Subdomains: () => import('../components/domains/Subdomains')
  },

  data() {
    return {
      loading: true,
      adding: false,
      updating: false,
      deleting: false,
      checkingDNS: false,

      domains: [],
      boxes: [],

      currentDomain: null,

      editForm: {
        ttl: '600',
        autoRenew: '',
        newBox: ''
      },

      form: {
        selectedDomain: '',
        vHost: 'register',
        checked: false,
        domainAvailable: false,
        price: '',
        setup: '',
        authCode: ''
      },
      
      currentSort: 'name',
      currentSortDir: 'asc',
      searchQuery: '',
    }
  },

  computed: {
    ...mapGetters([
      'box',
      'accountType',
      'id',
      'isAdmin',
      'isCustomerAdmin',
      'config'
    ]),

    sortedDomains() {
      var filteredDomains = this.domains.filter(domain => {
        if(domain) {
          return domain.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        } else {
          return null
        }
      })
      return _.orderBy(filteredDomains, this.currentSort, this.currentSortDir) 
    },

    subdomains() {
      if (this.currentDomain) {
        return this.domains.filter(domain => domain.isSub && domain.parentId === this.currentDomain.id)
      } else {
        return []
      }
    },

    company() {
      return this.config.company
    }
  },

  watch: {
    'box': {
      immediate: true,
      handler() {
        this.fetchDomains()
        this.fetchBoxes()
      }
    },

    'domains'(newVal, oldVal) {
      // update currentDomain on refetch to show newly added dns record
      if(newVal && newVal !== oldVal && this.currentDomain) this.currentDomain = newVal.find(domain => domain.id === this.currentDomain.id)
    }
  },

  methods: {
    async fetchDomains() {
      if(this.id && this.box) {
        const domains = await this.$axios.get(`/api/${this.accountType}/${this.id}/box/${this.box.id}/domains`)
        this.domains = domains.data
        this.loading = false
        console.log(this.domains)
      }
    },

    async fetchBoxes() {
      if(this.id && this.box) {
        const allBoxes = await this.$axios.get(`/api/${this.accountType}/${this.id}/boxes`)
        this.boxes = allBoxes.data.filter(box => box.id !== this.box.id)
      }
    },

    edit(domain) {
      this.$refs.edit.open()
      this.currentDomain = domain
      this.editForm.autoRenew = domain.autoRenew
      this.editForm.ttl = domain.ttl
    },

    closeModal() {
      this.$refs.add.close()
      this.$refs.edit.close()
      this.editForm.newBox = ''
    },

    openModal() {
      this.$refs.add.open()
    },

    async checkDomain() {
      const strippedDomain = this.form.selectedDomain.replace(/^https?:\/\//, '').replace(/^www./, '').replace(/\/$/, '')
      this.adding = true
      this.$axios.get(`/api/domain/check/${strippedDomain}`).then(async (response) => {
        if(response.data.available) {
          this.form.domainAvailable = true
          this.form.price = response.data.price
          this.form.setup = response.data.setup
        } else if(response.data.error && response.data.error.faultcode === '2004') {
          this.$notify.error('Ungültiges Domainformat')
        }
        this.form.checked = true
        this.adding = false
      })
    },

    async addDomain() {
      const strippedDomain = this.form.selectedDomain.replace(/^https?:\/\//, '').replace(/^www./, '').replace(/\/$/, '')
      this.adding = true
      this.$axios.post(`/api/${this.accountType}/${this.id}/box/${this.box.id}/domain`, {
        domain: strippedDomain,
        vHost: this.form.vHost === 'vHost' ? true : false,
        authCode: this.form.authCode ? this.form.authCode : ''
      }).then(async () => {
        await this.fetchDomains()
        this.$notify.success(this.form.vHost !== 'vHost' ? 'Domain registriert' : 'vHost erstellt')
        this.adding = false
        this.form.selectedDomain = ''
        this.form.vHost = 'register'
        this.form.checked = false,
        this.form.domainAvailable = false,
        this.form.price = ''
        this.form.setup = ''
      }).catch(error => {
        if(error.response.data.error === 'DNS domain exists') {
          this.$notify.error('Domain existiert bereits')
        } else if (error.response.data.error.error.returnMessage === 'Invalid authorization information') {
          this.$notify.error('Authcode ungültig')
        } else {
          console.log(error.response)
        }
        this.adding = false
      })
    },

    updateDomain() {
      this.updating = true
      const formData = {
        newBox: this.editForm.newBox ? this.editForm.newBox : this.box.id,
        autoRenew: this.editForm.autoRenew,
        ttl: this.editForm.ttl ? this.editForm.ttl : 600
      }
      this.$axios.put(`/api/${this.accountType}/${this.id}/box/${this.box.id}/domain/${this.currentDomain.id}`, formData).then(() => {
        this.$notify.success('Domain aktualisiert')
        this.fetchDomains()
        this.updating = false
      }).catch(error => {
        console.log(error.response)
        this.updating = false
      })
    },

    deleteDomain() {
      this.deleting = true
      this.$axios.delete(`/api/${this.accountType}/${this.id}/box/${this.box.id}/domain/${this.currentDomain.id}`).then(() => {
        this.$notify.success('Domain gelöscht')
        this.closeModal()
        this.fetchDomains()
        this.deleting = false
      }).catch(error => {
        console.log(error.response)
        this.deleting = false
      })
    },

    checkMapped(domainId) {
      this.checkingDNS = true
      this.$axios.put(`/api/${this.accountType}/${this.id}/box/${this.box.id}/domain/${domainId}`, {
        checkMapped: true
      }).then(async (response) => {
        if(response.data.mapped) {
          await this.fetchDomains()
          this.$notify.success('DNS-Eintrag korrekt')
        } else {
          this.$notify.error('DNS-Eintrag leitet noch nicht auf dein Hosting Paket, versuch es in 30 Minuten nocheinmal')
        }
        this.checkingDNS = false
      }).catch(error => {
        console.log(error.response)
        this.checkingDNS = false
      })
    },

    sort(s) {
      if(s === this.currentSort) this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      this.currentSort = s
    },

    clearFilter() {
      this.searchQuery = ''
      this.currentSort = 'name'
      this.currentSortDir = 'asc'
    },

    onChange() {
      if(this.form.domainAvailable && this.form.checked) {
        this.form.price = ''
        this.form.checked = false
        this.form.domainAvailable = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fieldset.domain {
	&::before {
		content: 'https://';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		line-height: 34px;
		color: $color-gray;
		pointer-events: none;
	}

	input {
		padding-left: 45px;
	}
}

.domains-table {
  .row {
    grid-template-columns: 3fr 1fr;
  }
}
</style>