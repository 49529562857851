<template>
  <div class="container login">
    <div class="grid">
      <div class="col-sm-6 bg" :style="`background: ${config && config.loginBgColor || '#001c31'};`">
        <div class="inner">
          <img
            class="loginIcon"
            v-if="config && config.loginIcon"
            :src="'/images/' + config.loginIcon"
            :alt="config.company + '-logo'"
          >
          <div
            v-if="config && config.loginText"
            class="login-text"
          >
            {{ config.loginText }}
          </div>
          <img
            class="bottomIcon"
            v-if="config && config.bottomIcon"
            :src="'/images/' + config.bottomIcon"
            :alt="config.company + '-logo'"
          >
        </div>
        <img
          class="loginBgLeft"
          v-if="config && config.loginBgLeft"
          :src="'/images/' + config.loginBgLeft"
          :alt="config.company"
        >
        <img
          class="loginBgRight"
          v-if="config && config.loginBgRight"
          :src="'/images/' + config.loginBgRight"
          :alt="config.company"
        >
      </div>
      <div class="col-sm-6 form">
        <form @submit.prevent="login">
          <div
            v-if="error"
            class="info error mb"
          >
            <template v-if="error === 'company account not active please activate account with OTP admin activation key or contact support'">
              <h3 class="mb-sm">
                Unternehmens-Account inaktiv
              </h3>
              <p>
                Der dir zugewiesene Unternehmens-Account wurde wurde noch nicht aktiviert. Bei Fragen erreichst du unseren Support unter <a
                  :href="`mailto:${companyMail}`"
                  target="_blank"
                >{{ companyMail }}</a>, oder per Telefon unter der <a
                  :href="`tel:${companyPhone}`"
                  target="_blank"
                >{{ companyPhone }}</a> 
              </p>
            </template>
            <template v-else-if="error === 'email or password is wrong.'">
              <p>E-Mail oder Passwort fehlerhaft.</p>
            </template>
          </div>
          <h1 class="mb-sm">
            Login
          </h1>
          <p>
            Du hast keinen Account? <router-link to="/register">
              Jetzt registrieren
            </router-link>
          </p>
          <div
            class="fieldset mt-lg"
            :class="{'fieldset-error' : $v.form.email.$error}"
          >
            <label for="email">E-Mail</label>
            <input
              id="email"
              v-model.trim="$v.form.email.$model"
              type="email"
              required
              autofocus
              autocomplete="username"
              placeholder="beispiel@email.com"
            >
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.password.$error}"
          >
            <label for="current-password">Passwort</label>
            <input
              id="current-password"
              ref="password"
              v-model.trim="$v.form.password.$model"
              :type="[showPassword ? 'text' : 'password']"
              autocomplete="current-password"
              required
              placeholder="Gib dein Passwort ein"
            >
            <div
              class="toggle-visibility"
              @click="showPassword = !showPassword"
            >
              <svg
                v-if="!showPassword"
                enable-background="new 0 0 32 32"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              ><path
                id="XMLID_320_"
                d="M29.946,15.675C27.954,9.888,22.35,6,16,6S4.046,9.888,2.054,15.675c-0.072,0.21-0.072,0.44,0,0.65  C4.046,22.112,9.65,26,16,26s11.954-3.888,13.946-9.675C30.018,16.115,30.018,15.885,29.946,15.675z M16,22c-3.309,0-6-2.691-6-6  s2.691-6,6-6s6,2.691,6,6S19.309,22,16,22z"
              /></svg>
              <svg
                v-else
                enable-background="new 0 0 32 32"
                viewBox="0 0 32 32"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              ><path
                id="XMLID_323_"
                d="M20.722,24.964c0.096,0.096,0.057,0.264-0.073,0.306c-7.7,2.466-16.032-1.503-18.594-8.942  c-0.072-0.21-0.072-0.444,0-0.655c0.743-2.157,1.99-4.047,3.588-5.573c0.061-0.058,0.158-0.056,0.217,0.003l4.302,4.302  c0.03,0.03,0.041,0.072,0.031,0.113c-1.116,4.345,2.948,8.395,7.276,7.294c0.049-0.013,0.095-0.004,0.131,0.032  C17.958,22.201,20.045,24.287,20.722,24.964z"
              /><path
                id="XMLID_325_"
                d="M24.68,23.266c2.406-1.692,4.281-4.079,5.266-6.941c0.072-0.21,0.072-0.44,0-0.65  C27.954,9.888,22.35,6,16,6c-2.479,0-4.841,0.597-6.921,1.665L3.707,2.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414  l26,26c0.391,0.391,1.023,0.391,1.414,0c0.391-0.391,0.391-1.023,0-1.414L24.68,23.266z M16,10c3.309,0,6,2.691,6,6  c0,1.294-0.416,2.49-1.115,3.471l-8.356-8.356C13.51,10.416,14.706,10,16,10z"
              /></svg>
            </div>
          </div>
          <SubmitBtn
            class="mb-sm"
            label="Anmelden"
            :submitting="submitting"
          />
          <router-link
            class="forgot-pw"
            to="/forgot"
          >
            Passwort vergessen?
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Login',

  components: {
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      error: '',
      showPassword: false,
      submitting: false,
    }
  },

  validations: {
    form: {
      email: { required, email },
      password: { required }
    }
  },

  computed: {
    ...mapGetters([
      'config'
    ]),

    companyMail() {
      return this.config.email
    },

    companyPhone() {
      return this.config.phone
    }
  },

  async created() {
    if (this.$route.params.mail) {
      this.form.email = this.$route.params.mail
    }
  },

  methods: {
    ...mapMutations({
      setUser: 'setUser'
    }),

    async login() {
      this.$v.$touch()
      if (!this.$v.form.email.required) {
        this.$notify.error('E-Mail benötigt')
      } else if (!this.$v.form.email.email) {
        this.$notify.error('Ungültige E-Mail')
      } else if (!this.$v.form.password.required) {
        this.$notify.error('Passwort benötigt')
      } else {
        this.submitting = true
        const fp = await this.$fingerprint.load()
        const result = await fp.get()
        this.$axios.post('auth/login', this.form, {
          headers: {
            'Fingerprint': result.visitorId
          },
        }).then(response => {
          if(response.data.error) {
            this.submitting = false
            this.error = response.data.error
            this.$notify.error(`Login fehlgeschlagen`)
            return
          }
          const token = response.data.token
          const role = response.data.user.roles.indexOf('admin') >= 0 ? 'admin' : response.data.user.roles.indexOf('customer_admin') >= 0 ? 'customer_admin' : null
          localStorage.setItem('user-token', token)
          localStorage.setItem('renew-token', response.data.renewToken)
          localStorage.setItem('fingerprint', result.visitorId)
          this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          this.$axios.defaults.headers.common['Fingerprint'] = result.visitorId
          this.setUser(response.data.user)
          this.submitting = false
          if(role != null) {
            localStorage.setItem('role', role)
            if(role === 'admin') {
              this.$router.push('/servers')
            } else if(role === 'customer_admin') {
              response.data.user.company ? this.$router.push(`/organisations/${response.data.user.company}`) : this.$router.push('/boxes/new')
            }
          } else {
            // const box = response.data.user.boxes.find(box => box.isDefault) ? response.data.user.boxes.find(box => box.isDefault) : response.data.user.boxes[0]
            if(response.data.user.boxes.length) {
              this.$router.push('/box')
            } else {
              this.$router.push('/boxes/new')
            }
          }
          this.$notify.success(`Willkommen zurück, ${response.data.user.firstName}`)
        })
          .catch(() => {
            this.submitting = false
            localStorage.removeItem('user-token')
            localStorage.removeItem('role')
            localStorage.removeItem('renew-token')
            localStorage.removeItem('fingerprint')
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 $spacer;
      
  .grid {
    min-height: 100vh;

    .bg {
      position: relative;
      overflow: hidden;
      min-height: 260px;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 9;
      padding: $spacer-lg;
    }

    .loginIcon {
      width: 200px;
      margin: auto 0;
    }

    .login-text {
      font-size: $fontsize-sm;
      color: #fff;
      opacity: 0.6;
      width: 100%;
      text-align: center;
      margin-bottom: $spacer-sm;
    }

    .bottomIcon {
      width: 100px;
    }

    .loginBgLeft {
      position: absolute;
      left: 0;
      top: 0;
      width: 33%;
      z-index: 1;
    }

    .loginBgRight {
      position: absolute;
      right: 0;
      top: 0;
      width: 33%;
      z-index: 1;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      form {
        max-width: 300px;

        .toggle-visibility {
          position: absolute;
          bottom: 2px;
          height: 32px;
          width: 32px;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 16px;
            fill: $color-gray;
            transition: all .3s;
          }

          &:hover {
            svg {
              fill: darken($color-gray, 20%);
            }
          }
        }

        .btn {
          width: 100%;
        }
      }
    }
  }
}
</style>