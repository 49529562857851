<template>
  <div class="container register">
    <div v-if="!comingFromMail">
      <div v-if="submitState != 'success'">
        <div class="mb-lg">
          <h1 class="mb-sm">
            Konto erstellen
          </h1>
          <p>
            Bereits registriert? <router-link to="/login">
              Hier anmelden
            </router-link>
          </p>
        </div>

        <!-- <div
          v-if="!accountType"
          class="account-type-selection"
        >
          <button
            class="btn mb-sm"
            @click.prevent="accountType = 'user'"
          >
            Benutzerkonto erstellen
          </button>
          <button
            class="btn"
            @click.prevent="accountType = 'organisation'"
          >
            Unternehmenskonto erstellen
          </button>
        </div> -->

        <form
          class="register-form"
          @submit.prevent="register"
        >
          <!-- <div
            v-if="accountType === 'organisation'"
            class="mb-lg"
          >
            <h3 class="mb">
              Unternehmensdaten
            </h3>
            <div
              class="fieldset organisation-name"
              :class="{'fieldset-error' : $v.form.companyName.$error}"
            >
              <input
                v-model="$v.form.companyName.$model"
                type="text"
                name="companyName"
                placeholder="Name des Unternehmens"
              >
            </div>
            <div class="fieldsetgroup">
              <div
                class="fieldset country"
                :class="{'fieldset-error' : $v.form.companyCountry.$error}"
              >
                <v-select
                  v-model="$v.form.companyCountry.$model"
                  :options="countries"
                  placeholder="Land"
                  :clearable="false"
                  autocomplete="off"
                />
              </div>
              <div
                class="fieldset street"
                :class="{'fieldset-error' : $v.form.companyStreet.$error}"
              >
                <input
                  v-model="$v.form.companyStreet.$model"
                  type="text"
                  name="street"
                  placeholder="Straße und Hausnummer"
                >
              </div>
            </div>
            <div class="fieldsetgroup">
              <div
                class="fieldset zip"
                :class="{'fieldset-error' : $v.form.companyZip.$error}"
              >
                <input
                  v-model="$v.form.companyZip.$model"
                  type="text"
                  name="zip"
                  placeholder="PLZ"
                >
              </div>
              <div
                class="fieldset city"
                :class="{'fieldset-error' : $v.form.companyCity.$error}"
              >
                <input
                  v-model="$v.form.companyCity.$model"
                  type="text"
                  name="city"
                  placeholder="Stadt"
                >
              </div>
            </div>
            <div
              class="fieldset phone"
              :class="{'fieldset-error' : $v.form.companyPhone.$error}"
            >
              <input
                v-model="$v.form.companyPhone.$model"
                type="text"
                name="phone"
                placeholder="Telefon"
              >
            </div>
            <div
              class="fieldset email"
              :class="{'fieldset-error' : $v.form.companyEmail.$error}"
            >
              <input
                v-model.trim="$v.form.companyEmail.$model"
                type="email"
                autocomplete="username"
                name="companyEmaiö"
                placeholder="E-Mail"
              >
            </div>
          </div> -->
          <!-- <h3
            v-if="accountType === 'organisation'"
            class="mb"
          >
            Benutzerdaten
          </h3> -->
          <div class="fieldsetgroup">
            <div
              class="fieldset firstName"
              :class="{'fieldset-error' : $v.form.firstName.$error}"
            >
              <input
                v-model="$v.form.firstName.$model"
                type="text"
                name="firstName"
                placeholder="Vorname"
                required
              >
              <span
                v-if="form.firstName == ''"
                class="required-notification"
              >Vorname benötigt</span>
              <span
                v-else
                class="required-notification"
                :style="{color: 'white'}"
              >Vorname benötigt </span>
            </div>
            <div
              class="fieldset lastName"
              :class="{'fieldset-error' : $v.form.lastName.$error}"
            >
              <input
                v-model="$v.form.lastName.$model"
                type="text"
                name="lastName"
                placeholder="Nachname"
              >
              <span
                v-if="form.lastName == ''"
                class="required-notification"
              >Nachname benötigt</span>
              <span
                v-else
                class="required-notification"
                :style="{color: 'white'}"
              >Nachname benötigt </span>
            </div>
          </div>
          <div
            class="fieldset country"
            :class="{'fieldset-error' : $v.form.country.$error}"
          >
            <v-select
              v-model="$v.form.country.$model"
              :options="countries"
              placeholder="Land"
              :clearable="false"
              autocomplete="off"
            />
            <span
              v-if="form.country == ''"
              class="required-notification"
            >Auswahl benötigt</span>
            <span
              v-else
              class="required-notification"
              :style="{color: 'white'}"
            >Nachname benötigt </span>
          </div>
          <div
            class="fieldset street"
            :class="{'fieldset-error' : $v.form.street.$error}"
          >
            <input
              v-model="$v.form.street.$model"
              type="text"
              name="street"
              placeholder="Straße und Hausnummer"
            >
            <span
              v-if="form.street == ''"
              class="required-notification"
            >Adresse benötigt</span>
            <span
              v-else
              class="required-notification"
              :style="{color: 'white'}"
            >Nachname benötigt </span>
          </div>
          <div class="fieldsetgroup">
            <div
              class="fieldset zip"
              :class="{'fieldset-error' : $v.form.zip.$error}"
            >
              <input
                v-model="$v.form.zip.$model"
                type="text"
                name="zip"
                placeholder="PLZ"
              >
              <span
                v-if="form.zip == ''"
                class="required-notification"
              >Postleitzahl benötigt</span>
              <span
                v-else
                class="required-notification"
                :style="{color: 'white'}"
              >Nachname benötigt </span>
            </div>
            <div
              class="fieldset city"
              :class="{'fieldset-error' : $v.form.city.$error}"
            >
              <input
                v-model="$v.form.city.$model"
                type="text"
                name="city"
                placeholder="Stadt"
              >
              <span
                v-if="form.city == ''"
                class="required-notification"
              >Stadt benötigt</span>
              <span
                v-else
                class="required-notification"
                :style="{color: 'white'}"
              >Nachname benötigt </span>
            </div>
          </div>
          <div
            class="fieldset phone"
            :class="{'fieldset-error' : $v.form.phone.$error}"
          >
            <input
              v-model="$v.form.phone.$model"
              type="text"
              name="phone"
              placeholder="Telefon"
            >
            <span
              v-if="form.phone == ''"
              class="required-notification"
            >Telefonnummer benötigt</span>
            <span
              v-else
              class="required-notification"
              :style="{color: 'white'}"
            >Nachname benötigt </span>
          </div>
          <div
            class="fieldset email"
            :class="{'fieldset-error' : $v.form.email.$error}"
          >
            <input
              v-model.trim="$v.form.email.$model"
              type="email"
              autocomplete="username"
              name="email"
              placeholder="E-Mail"
            >
            <span
              v-if="form.email == ''"
              class="required-notification"
            >Email benötigt</span>
            <span
              v-else
              class="required-notification"
              :style="{color: 'white'}"
            >Nachname benötigt </span>
          </div>
          <div
            class="fieldset password"
            :class="{'fieldset-error' : $v.form.password.$error}"
          >
            <input
              v-model.trim="$v.form.password.$model"
              type="password"
              autocomplete="new-password"
              name="password"
              placeholder="Passwort"
            >
            <span
              v-if="form.password == ''"
              class="required-notification"
            >Passwort benötigt</span>
            <span
              v-else
              class="required-notification"
              :style="{color: 'white'}"
            >Nachname benötigt </span>
            <div class="password-instructions">
              <span
                v-if="!$v.form.password.lower || !$v.form.password.upper || !$v.form.password.digit || !$v.form.password.minLength"
                class="text"
              >Mindestens</span>
              <span v-if="!$v.form.password.lower">1 Kleinbuchstabe</span>
              <span v-if="!$v.form.password.upper">1 Großbuchstabe</span>
              <span v-if="!$v.form.password.digit">1 Ziffer</span>
              <span v-if="!$v.form.password.minLength">{{ $v.form.password.$params.minLength.min }} Zeichen</span>
            </div>
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.repeatPassword.$error}"
          >
            <!-- <label for="repeat-password">Passwort wiederholen</label> -->
            <input
              v-model="$v.form.repeatPassword.$model"
              type="password"
              autocomplete="new-password"
              name="repeat-password"
              placeholder="Passwort wiederholen"
            >
            <span
              v-if="form.repeatPassword == ''"
              class="required-notification"
            >Passwortbestätigung benötigt</span>
            <span
              v-else
              class="required-notification"
              :style="{color: 'white'}"
            >Nachname benötigt </span>
          </div>
          <!-- <div
            v-if="accountType === 'user'"
            class="fieldset accessCode"
          >
            <input
              v-model="form.accessCode"
              type="text"
              name="accessCode"
              placeholder="Unternehmens-Code"
            >
            <legend class="mt-sm">
              Feld freilassen, wenn du keinem hier registrierten Unternehmen angehörst.
            </legend>
          </div> -->
          <SubmitBtn
            label="Konto erstellen"
            :submitting="submitting"
          />
          <legend class="mt">
            Mit der Erstellung des Kontos bestätigst du, dass du die <router-link to="/agb">
              Nutzungsbedingungen
            </router-link> und die <router-link to="/datenschutz">
              Datenschutzrichtlinien
            </router-link> gelesen hast und diese in vollem Umfang akzeptierst.
          </legend>
        </form>
      </div>

      <div v-else>
        <form
          class="auth-form"
          @submit.prevent="auth"
        >
          <h1 class="mb-sm">
            Registrierung bestätigen
          </h1>
          <p>Ein Verifizierungscode wurde an <strong>{{ registeredMail }}</strong> geschickt. Klicke auf den enthaltenen Link, oder trage den Code in das folgende Feld ein, um die Registrierung abzuschließen.</p>
          <div class="fieldset mt">
            <input
              v-model.trim="authkey"
              type="text"
              name="authkey"
              placeholder="6-Stelliger Code"
            >
          </div>
          <SubmitBtn
            class="mb"
            label="Registrierung abschließen"
            :submitting="submitting"
          />
          <a
            class="link resend-btn reveal"
            href="#"
            :disabled="submitting"
            @click.prevent="resend"
          >
            Neuen Code anfordern
          </a>
        </form>
      </div>
    </div>
    <div
      v-else
      class="auth-success"
    >
      <h2>Registrierung wird abgeschlossen</h2>
    </div>
  </div>
</template>

<script>
import { required, email, minLength, sameAs, helpers } from 'vuelidate/lib/validators'

// Custom Validators
const lower = helpers.regex('lower', /[a-z]+/)
const upper = helpers.regex('upper', /[A-Z]+/)
const digit = helpers.regex('digit', /[0-9]+/)

export default {
  name: 'Register',

  components: {
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  // head: {
  //   title: 'CIAB.IO Registrieren',
  //   meta: [{
  //     hid: 'description',
  //     name: 'description',
  //     content: 'Jetzt neuen Ciab.io Account registrieren.'
  //   }]
  // },

  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        // accessCode: '',
        country: '',
        street: '',
        zip: '',
        city: '',
        phone: '',
        email: '',
        password: '',
        repeatPassword: '',

        // companyName: '',
        // companyStreet: '',
        // companyZip: '',
        // companyCity: '',
        // companyPhone: '',
        // companyEmail: '',
        // companyCountry: ''
      },
      countries: [
        'Deutschland',
        'Österreich',
        'Schweiz'
      ],
      // accountType: '',
      authkey: null,
      submitState: null,
      submitting: false,
      comingFromMail: false,
      registeredMail: ''
    }
  },

  validations: {
    form: {
      firstName: {required},
      lastName: {required},
      country: {required},
      street: {required},
      zip: {required},
      city: {required},
      phone: {required},
      email: {required, email},
      password: {required, digit, lower, upper, minLength: minLength(8)},
      repeatPassword: {required, sameAsPassword: sameAs('password')}
      // companyName: {
      //   required: requiredIf(function() {
      //     return this.accountType === 'organisation'
      //   }),
      // },
      // companyStreet: {
      //   required: requiredIf(function() {
      //     return this.accountType === 'organisation'
      //   }),
      // },
      // companyZip: {
      //   required: requiredIf(function() {
      //     return this.accountType === 'organisation'
      //   }),
      // },
      // companyCity: {
      //   required: requiredIf(function() {
      //     return this.accountType === 'organisation'
      //   }),
      // },
      // companyPhone: {
      //   required: requiredIf(function() {
      //     return this.accountType === 'organisation'
      //   }),
      // },
      // companyEmail: {
      //   required: requiredIf(function() {
      //     return this.accountType === 'organisation'
      //   }),
      // },
      // companyCountry: {
      //   required: requiredIf(function() {
      //     return this.accountType === 'organisation'
      //   }),
      // },
    }
  },

  created() {
    if(this.$route.query.authkey && this.$route.query.mail) {
      this.comingFromMail = true
      this.authkey = this.$route.query.authkey
      this.form.email = this.$route.query.mail
      this.auth()
    }
  },

  methods: {
    async register() {
      this.$v.$touch()
      if(!this.$v.form.email.required) {
        this.$notify.error(`E-Mail benötigt`)
      } else if(!this.$v.form.email.email) {
        this.$notify.error(`Ungültige E-Mail`)
      } else if(!this.$v.form.firstName.required) {
        this.$notify.error(`Vorname benötigt`)
      } else if(!this.$v.form.lastName.required) {
        this.$notify.error(`Nachname benötigt`)
      } else if(!this.$v.form.country.required) {
        this.$notify.error(`Land benötigt`)
      } else if(!this.$v.form.street.required) {
        this.$notify.error(`Strasse benötigt`)
      } else if(!this.$v.form.zip.required) {
        this.$notify.error(`PLZ benötigt`)
      } else if(!this.$v.form.city.required) {
        this.$notify.error(`Ort benötigt`)
      } else if(!this.$v.form.phone.required) {
        this.$notify.error(`Telefon benötigt`)
      } else if(!this.$v.form.password.required) {
        this.$notify.error(`Passwort benötigt`)
      } else if(this.$v.form.password.$error) {
        this.$notify.error(`Ungültiges Passwort`)
      } else if(!this.$v.form.repeatPassword.required) {
        this.$notify.error(`Passwort bestätigen`)
      } else if(!this.$v.form.repeatPassword.sameAsPassword) {
        this.$notify.error(`Passwörter stimmen nicht überein`)
      } else {
        this.submitState = 'pending'
        this.submitting = true
        let formData = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          country: this.form.country,
          street: this.form.street,
          zip: this.form.zip,
          city: this.form.city,
          phone: this.form.phone,
          email: this.form.email,
          password: this.form.password
        }
        // if(this.accountType === 'organisation') {
        //   formData.companyName = this.form.companyName
        //   formData.companyStreet = this.form.companyStreet
        //   formData.companyZip = this.form.companyZip
        //   formData.companyCity = this.form.companyCity
        //   formData.companyPhone = this.form.companyPhone
        //   formData.companyEmail = this.form.companyEmail
        //   formData.companyCountry = this.form.companyCountry
        // }
        // if(this.form.accessCode) formData.accessCode = this.form.accessCode
        // await this.$axios.post(this.accountType !== 'organisation' ? 'auth/register' : 'auth/register/organisation', formData).then(response => {
        await this.$axios.post('auth/register', formData).then(response => {
          this.registeredMail = response.data.user
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.submitState = 'success'
          this.submitting = false
        }).catch(error => {
          console.log(error.response)
          this.submitting = false
          this.submitState = null
        })
      }
    },

    async auth() {
      this.submitting = true
      this.$axios.post('auth/activate', {
        email: this.form.email,
        otp: this.authkey
      }).then(() => {
        this.submitting = false
        this.$notify.success(`Registrierung erfolgreich`)
        this.$router.push({
          name: 'login',
          params: { mail: this.form.email }
        })
      }).catch(error => {
        this.submitting = false
        console.log(error.response)
        this.$notify.error(`Abgelaufener Auth-Code oder Benutzer nicht gefunden.`)
        if(this.comingFromMail) {
          this.comingFromMail = false
          this.submitState = 'success'
        }
      })
    },

    async resend() {
      this.submitting = true
      this.$axios.post('auth/otp', {
        email: this.form.email
      }).then(() => {
        this.submitting = false
        this.$notify.success(`Authkey wurde erneut gesendet`)
      }).catch(error => {
        console.log(error.response)
        this.submitting = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>

.required-notification{
  color : gray
}

.register {
  min-height: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;

  .auth-form {
    text-align: center;
  }
}
</style>