<template>
  <div class="new-box container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Tarif wählen
      </h1>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="loading"
        class="skeleton container"
      >
        Tarife werden geladen
      </div>
      <ProductList
        v-else
        :products="products"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'NewBox',
  components: {
    ProductList: () => import('../components/ProductList')
  },

  data() {
    return {
      loading: false,
      products: []
    }
  },

  async created() {
    this.loading = true
    this.fetch()
  },

  methods: {
    async fetch() {
      const products = await this.$axios.get('/api/products')
      this.products = products.data
      this.loading = false
    },
  }
}
</script>