<template>
  <div
    v-if="!loading && user"
    class="user container"
  >
    <router-link :to="$route.params.route ? $route.params.route : '/users'">
      Zurück
    </router-link>
    <div class="page-head mb-lg">
      <h1 class="title">
        {{ user.firstName }} {{ user.lastName }}
      </h1>
      <div class="status">
        <span
          v-if="user.active"
          class="active"
        >Aktiviert</span>
        <span v-else>Inaktiv</span>
        <span :class="{'online' : user.status === 'Online'}">{{ user.status }}</span>
      </div>
    </div>
    <UserProfile
      :user="user"
      @fetchUser="fetchUser"
    />
    <UserBoxes v-if="isAdmin" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'User',

  components: {
    UserProfile: () => import('../components/users/UserProfile'),
    UserBoxes: () => import('../components/users/UserBoxes')
  },

  data() {
    return {
      loading: false,
      user: {}
    }
  },

  computed: {
    ...mapGetters([
      'isAdmin',
      'isCustomerAdmin'
    ])
  },

  async created() {
    this.loading = true
    this.fetchUser()
  },

  methods: {
    async fetchUser() {
      const user = await this.$axios.get(`api/user/${this.$route.params.id}`)
      this.user = user.data
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  font-size: 12px;
  display: flex;

  > span {
    padding-bottom: $spacer-xs;
    margin-left: $spacer;
    border-bottom: 2px solid $color-error;
    transition: all .3s;

    &.active,
    &.online {
      border-bottom: 2px solid $color-success;
    }
  }
}
</style>