var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && _vm.organisation
    ? _c(
        "div",
        { staticClass: "organisation container" },
        [
          _vm.isAdmin
            ? _c("router-link", { attrs: { to: "/organisations" } }, [
                _vm._v("\n    Zurück\n  ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "page-head mb-sm" }, [
            _c("h1", { staticClass: "title" }, [
              _vm._v("\n      " + _vm._s(_vm.organisation.name) + "\n    ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "status" }, [
              _vm.organisation.active
                ? _c("span", { staticClass: "active" }, [_vm._v("Aktiviert")])
                : _c("span", [_vm._v("Inaktiv")]),
              _vm._v(" "),
              _c(
                "span",
                { class: { online: _vm.organisation.status === "Online" } },
                [_vm._v(_vm._s(_vm.organisation.status))]
              )
            ])
          ]),
          _vm._v(" "),
          _c("OrganisationProfile", {
            attrs: { organisation: _vm.organisation },
            on: { fetchOrganisation: _vm.fetchOrganisation }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }