<template>
  <div>
    <div
      v-if="server"
      class="server container"
    >
      <router-link to="/servers">
        Zurück
      </router-link>
      <h1 class="mb-lg">
        {{ server.name }}
        <span
          class="status"
          :class="[
            {'online' : server.status === 'Online'},
            {'offline' : server.status === 'Offline'},
            {'maintenance' : server.status === 'Maintenance'}
          ]"
        >
          {{ server.status }}
        </span>
      </h1>

      <div v-if="serverHealth && serverHealth.length">
        <div class="grid">
          <div class="col-4">
            <MemoryChart
              :data="serverHealth"
              :latest-entry="serverHealth[0]"
            />
          </div>
          <div class="col-4">
            <CpuChart
              :data="serverHealth"
              :latest-entry="serverHealth[0]"
            />
          </div>
          <div class="col-4">
            <DiskChart
              :data="serverHealth"
              :latest-entry="serverHealth[0]"
            />
          </div>
        </div>

        <div class="services mt-lg">
          <h2 class="mb">
            Services
          </h2>
          <ul class="grid">
            <li
              v-for="(service, key) in serverHealth[0].service"
              :key="key"
              class="col-4"
            >
              <div
                class="service"
                :class="{'active' : service === 'active'}"
              >
                <h4>{{ key }}</h4>
                <div
                  v-if="serviceUpdating === key"
                  class="updating"
                >
                  Aktualisieren...
                </div>
                <div
                  v-else
                  class="actions"
                >
                  <button
                    v-if="service === 'active'"
                    title="Service neu starten"
                    @click.prevent="setService(key, 'restart')"
                  >
                    <svg viewBox="0 0 350 350"><path
                      class="cls-1"
                      d="M350,175A175,175,0,1,1,175,0,175,175,0,0,1,350,175ZM235.1,114.9a84.77,84.77,0,0,0-70.7-24.23l-8.71-18.12L122,122l59.66,4.5L173.71,110A65,65,0,1,1,129,221L114.9,235.1A85,85,0,0,0,235.1,114.9Z"
                    /></svg>
                  </button>
                  <button
                    v-if="service === 'active'"
                    title="Service anhalten"
                    @click.prevent="setService(key, 'stop')"
                  >
                    <svg viewBox="0 0 350 350"><path
                      class="cls-1"
                      d="M350,175A175,175,0,1,1,175,0,175,175,0,0,1,350,175ZM150,233.87V116.13A16.13,16.13,0,0,0,133.87,100H116.13A16.13,16.13,0,0,0,100,116.13V233.87A16.13,16.13,0,0,0,116.13,250h17.74A16.13,16.13,0,0,0,150,233.87Zm100,0V116.13A16.13,16.13,0,0,0,233.87,100H216.13A16.13,16.13,0,0,0,200,116.13V233.87A16.13,16.13,0,0,0,216.13,250h17.74A16.13,16.13,0,0,0,250,233.87Z"
                    /></svg>
                  </button>
                  <button
                    v-if="service !== 'active'"
                    title="Service starten"
                    @click.prevent="setService(key, 'start')"
                  >
                    <svg viewBox="0 0 350 350"><path
                      class="cls-1"
                      d="M350,175A175,175,0,1,1,175,0,175,175,0,0,1,350,175ZM119.94,126.1v97.8a16.13,16.13,0,0,0,23.34,14.43l97.8-48.9a16.13,16.13,0,0,0,0-28.86l-97.8-48.9A16.13,16.13,0,0,0,119.94,126.1Z"
                    /></svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <h3>Es wurden noch keine Live-Daten gesammelt.</h3>
      </div>

      <div class="server-settings mt-xl">
        <h2 class="mb">
          Server-Einstellungen
        </h2>
        <form
          autocomplete="off"
          @submit.prevent="updateServer"
        >
          <div class="grid">
            <div class="fieldset col-12 col-xs-6 col-sm-4">
              <label for="name">Servername</label>
              <input
                v-model="form.name"
                type="text"
                name="name"
                placeholder="Name"
              >
            </div>
            <div class="fieldset col-12 col-xs-6 col-sm-4">
              <label>Serverstatus</label>
              <v-select
                v-model="form.status"
                :options="states"
                placeholder="Serverstatus"
                :clearable="false"
                autocomplete="off"
              />
            </div>
            <div class="fieldset col-12 col-xs-6 col-sm-4">
              <label for="capacity">Max. Benutzer</label>
              <input
                v-model="form.capacity"
                type="number"
                name="capacity"
                placeholder="Max. Benutzer"
              >
            </div>
            <div class="fieldset col-12 col-xs-6 col-sm-4">
              <label for="ip">Server-IP</label>
              <input
                v-model="form.ip"
                type="text"
                name="ip"
                placeholder="IP-Adresse"
              >
            </div>
            <div class="fieldset col-12 col-xs-6 col-sm-4">
              <label for="url">Server-Url</label>
              <input
                v-model="form.url"
                type="text"
                name="url"
                placeholder="Server-Url"
              >
            </div>
            <div class="fieldset col-12 col-xs-6 col-sm-4">
              <label for="token">API-Key</label>
              <input
                v-model="form.token"
                type="password"
                name="token"
                autocomplete="new-password"
                placeholder="****************"
              >
            </div>
          </div>
          <div class="btn-set mt">
            <SubmitBtn
              label="Speichern"
              :submitting="updating"
            />
            <SubmitBtn
              label="Server löschen"
              :submitting="deleting"
              red
              @click.native.prevent="removeServer"
            />
          </div>
        </form>
      </div>
    </div>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import Vue from "vue"
import TrendChart from "vue-trend-chart"

Vue.use(TrendChart)

export default {
  name: 'Server',

  components: {
    MemoryChart: () => import('../components/servers/MemoryChart'),
    CpuChart: () => import('../components/servers/CpuChart'),
    DiskChart: () => import('../components/servers/DiskChart'),
    Dialog: () => import('../components/Dialog'),
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  data() {
    return {
      server: {},
      serverHealth: [],
      interval: null,
      form: {
        name: '',
        capacity: '',
        ip: '',
        status: '',
        token: '',
        url: ''
      },
      states: [
        'Online',
        'Offline',
        'Maintenance'
      ],
      updating: false,
      deleting: false,
      serviceUpdating: false
    }
  },

  async created() {
    await this.fetchServer()
    await this.fetchHealth()
    this.form.name = this.server.name
    this.form.status = this.server.status
    this.form.capacity = this.server.capacity
    this.form.url = this.server.url
    this.form.ip = this.server.ip
  },

  mounted() {
    this.interval = setInterval(async () => {
      this.fetchHealth()
    },90000)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    async fetchServer() {
      const server = await this.$axios.get(`api/server/${this.$route.params.id}`)
      this.server = server.data
    },
    
    async fetchHealth() {
      const serverHealth = await this.$axios.get(`api/server/health/${this.$route.params.id}`)
      this.serverHealth = serverHealth.data
    },

    async removeServer() {
      const confirmation = await this.$refs.dialog.open(`
        <h3 class="mb">Server löschen</h3>
        <p>Server-Eintrag wirklich aus der Datenbank entfernen? Alle Abhängigkeiten werden dadurch gelöscht!</p>
      `)
      if (confirmation === 'ok') {
        this.$axios.delete(`/api/server/${this.$route.params.id}`)
          .then(() => {
            this.$notify.success('Server gelöscht')
            this.$router.push('/servers')
          })
          .catch((error) => {
            console.log(error.response)
          })
      }
    },

    async updateServer() {
      const confirmation = await this.$refs.dialog.open(`
        <h3>Einstellungen aktualisieren</h3>
        Server-Einstellungen wirklich aktualisieren?
      `)
      if (confirmation === 'ok') {
        const formData = {
          name: this.form.name,
          capacity: this.form.capacity,
          ip: this.form.ip,
          status: this.form.status,
          url: this.form.url
        }
        if(this.form.token) {
          formData.token = this.form.token
        }
        this.$axios.put(`/api/server/${this.$route.params.id}`, formData).then(() => {
          this.fetchServer()
          this.$notify.success('Server aktualisiert')
        }).catch((error) => {
          console.log(error.response)
        })
      }
    },

    async setService(key, cmd) {
      this.serviceUpdating = key
      this.$axios.post(`/api/server/${this.$route.params.id}/service`, {
        cmd: cmd,
        service: key
      }).then(async () => {
        this.$notify.success('Service aktualisiert')
        await this.fetchHealth()
        this.serviceUpdating = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.status {
  font-size: $fontsize;
  vertical-align: super;
  font-weight: $medium;

  &.online {
    color: $color-success;
  }

  &.offline {
    color: $color-error;
  }

  &.maintenance {
    color: $color-warning;
  }
}

.btn-set {  
  .token-input {
    margin-right: $spacer;
  }
}

.services {
  .service {
    border: 2px solid $color-error-light;
    border-radius: 6px;
    padding: $spacer-sm;
    transition: border-color .3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions {
      height: 18px;

      button {
        width: 18px;
        height: 18px;

        svg {
          fill: $color-primary;
        }
      }
    }

    &:hover {
      border-color: $color-error;
    }

    &.active {
      border-color: $color-success-light;

      &:hover {
        border-color: $color-success;
      }
    }
  }
}

::v-deep {
  .data-info {
    margin-bottom: 0 !important;
  }
}
</style>