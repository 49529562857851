var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "servers container" },
    [
      _c("div", { staticClass: "page-head mb" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(" \n      Rechnungen\n      "),
          _vm.bills.length
            ? _c("span", [_vm._v(_vm._s(_vm.bills.length))])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.openModal($event)
              }
            }
          },
          [
            _c("span", { staticClass: "plus" }, [
              _vm._v("+ Rechnung erstellen ")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("BillList", { attrs: { bills: _vm.bills } })],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("h2", { staticClass: "mb" }, [
                  _vm._v("\n        Rechnung anlegen\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addBill($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "name" } }, [
                        _vm._v("Rechnungname")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "name",
                          placeholder: "Name der Rechnung"
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "name", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("label", { attrs: { for: "capacity" } }, [
                          _vm._v("Benutzer")
                        ]),
                        _vm._v(" "),
                        _vm.users.length
                          ? _c("v-select", {
                              attrs: {
                                label: "firstName",
                                placeholder: "Benutzer wählen...",
                                options: _vm.users
                              },
                              on: { input: _vm.userChosen },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selected-option",
                                    fn: function(user) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(user.lastName) +
                                            ", " +
                                            _vm._s(user.firstName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "option",
                                    fn: function(user) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(user.lastName) +
                                            ", " +
                                            _vm._s(user.firstName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2979576461
                              ),
                              model: {
                                value: _vm.chosenUser,
                                callback: function($$v) {
                                  _vm.chosenUser = $$v
                                },
                                expression: "chosenUser"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "status" } }, [
                        _vm._v("Status")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.status,
                            expression: "form.status"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "status",
                          placeholder: "Status der Rechnung"
                        },
                        domProps: { value: _vm.form.status },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "status", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.possibleBills
                      ? _c("div", [
                          _c("span", { staticClass: "box-title" }, [
                            _vm._v(" Boxen: ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(_vm.possibleBills.products, function(
                              product
                            ) {
                              return _c(
                                "li",
                                {
                                  key: product.boxName,
                                  staticClass: "box-item"
                                },
                                [
                                  _c("span", { staticClass: "box-name" }, [
                                    _vm._v(_vm._s(product.boxName))
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "box-price" }, [
                                    _vm._v(
                                      _vm._s(_vm.convertPrice(product.price))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  product.domains.length > 0
                                    ? _c(
                                        "ul",
                                        { staticClass: "domain-container" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "domain-title" },
                                            [_vm._v("Domains: ")]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(product.domains, function(
                                            domain
                                          ) {
                                            return _c(
                                              "li",
                                              {
                                                key: domain.id,
                                                staticClass: "domain-item"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "domain-name"
                                                  },
                                                  [_vm._v(_vm._s(domain.name))]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "domain-price"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.convertPrice(
                                                          domain.price
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "box-titel" }, [
                            _vm._v(
                              " Kompletter Preis: " +
                                _vm._s(
                                  _vm.convertPrice(_vm.possibleBills.totalPrice)
                                ) +
                                "  "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      attrs: {
                        label: "Rechnung anlegen",
                        submitting: _vm.submitting
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }