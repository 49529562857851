<template>
  <div class="instances container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Instanzen
        <span v-if="instances && instances.length">{{ instances.length }}</span>
      </h1>
      <div
        v-if="accountType === 'user' || (accountType === 'organisation' && isCustomerAdmin) || isAdmin"
        class="actions"
      >
        <SubmitBtn
          label="Hinzufügen"
          :submitting="submitting"
          @click.native.prevent="openModal"
        />
        <!-- <button
          class="btn"
          @click.prevent="openModal"
        >
          <span class="plus">+</span> Hinzufügen
        </button> -->
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <div
        v-if="loading && !instances.length"
        key="loading"
        class="skeleton"
      >
        Instanzen werden geladen...
      </div>
      <InstanceList
        v-else-if="instances.length"
        key="instances"
        :box="box"
        :instances="instances"
        :domains="domains"
        @fetch="fetchInstances"
      />
      <div
        v-else
        key="noresults"
        class="info"
      >
        <template v-if="isAdmin || isCustomerAdmin">
          Noch keine Instanzen für diese Box vorhanden. Klicke auf "Hinzufügen", um eine erste Instanz zu erstellen.
        </template>
        <template v-else>
          Es wurden noch keine Instanzen für diese Box erstellt.
        </template>
      </div>
    </transition>

    <Modal ref="modal">
      <template #content>
        <h2 class="mb">
          Instanz hinzufügen
        </h2>
        <form @submit.prevent="addInstance">
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.name.$error}"
          >
            <label for="name">Instanzname</label>
            <input
              v-model="$v.form.name.$model"
              type="text"
              name="name"
              placeholder="Z.b. Wordpress 1"
            >
          </div>
          <div class="fieldset">
            <label>Haupt-Domain</label>
            <v-select
              v-model="form.domain"
              :options="availableDomains"
              placeholder="Domain wählen"
              :clearable="true"
              label="name"
              autocomplete="off"
            >
              <template #no-options>
                Keine Domain(s) gefunden. <router-link to="/domains">
                  Domain anlegen
                </router-link>
              </template>
            </v-select>
          </div>
          <div class="fieldset">
            <label>Weitere Domains</label>
            <v-select
              v-model="form.aliases"
              :options="availableAliases"
              placeholder="Domain(s) wählen"
              :reduce="domain => domain.name"
              :multiple="true"
              :clearable="true"
              label="name"
              autocomplete="off"
              :disabled="!form.domain"
            >
              <template #no-options>
                Keine Domain(s) gefunden. <router-link to="/domains">
                  Domain anlegen
                </router-link>
              </template>
            </v-select>
            <legend class="mt-sm">
              Alle weiteren Domains leiten automatisch auf die Haupt-Domain dieser Instanz.
            </legend>
          </div>
          <div class="fieldset">
            <label>PHP Version</label>
            <v-select
              v-model="form.webTemplate"
              :options="webTemplates"
              placeholder="PHP-Version wählen"
              autocomplete="off"
            >
              <template #no-options>
                Keine PHP-Versionen gefunden.
              </template>
            </v-select>
          </div>
					<div class="fieldset">
            <label>mod-Pagespeed</label>
            <label class="switch">
              <span v-if="form.pagespeed">Aktiviert</span>
              <span v-else>Deaktiviert</span>
              <input
                v-model="form.pagespeed"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>
					<div class="fieldset">
            <label>Caching</label>
            <label class="switch">
              <span v-if="form.caching">Aktiviert</span>
              <span v-else>Deaktiviert</span>
              <input
                v-model="form.caching"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>
          <!-- <div class="fieldset">
            <label>Proxy Template</label>
            <v-select
              v-model="form.proxyTemplate"
              :options="box.server.proxyTemplates"
              placeholder="Template wählen"
              autocomplete="off"
            >
              <template #no-options>
                Keine Templates gefunden.
              </template>
            </v-select>
          </div> -->
          <div class="fieldset">
            <label for="homedir">Hauptverzeichnis</label>
            <input
              v-model="form.homeDir"
              type="text"
              name="homedir"
              placeholder="/"
            >
          </div>
          <div class="fieldset">
            <label>SSL-Verschlüsselung</label>
            <label class="switch">
              <span v-if="form.ssl">Aktiviert</span>
              <span v-else>Deaktiviert</span>
              <input
                v-model="form.ssl"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>
          <div class="fieldset">
            <label>Software</label>
            <ul class="software mt">
              <li
                v-for="sw in software"
                :key="sw"
                class="card sw"
                :class="{'selected' : form.preInstall === sw}"
                @click="form.preInstall = form.preInstall === sw ? '' : sw"
              >
                <template v-if="sw === 'Shopware'">
                  <svg viewBox="0 0 99.17 99.1"><path
                    d="M79.1,88.29C74.78,85.05,68.47,82.61,61.71,80c-8-3.06-17-6.58-23.87-12-7.75-6.22-11.44-14-11.44-23.79A26.68,26.68,0,0,1,36.94,22.52c7.75-6.12,19.46-9.28,34-9.28A91.28,91.28,0,0,1,82.25,14c.27,0,.63-.09.72-.36a.69.69,0,0,0-.18-.81A49.54,49.54,0,0,0,0,49.55,49.51,49.51,0,0,0,49.55,99.1,49.42,49.42,0,0,0,79.1,89.37a.64.64,0,0,0,.27-.54A.78.78,0,0,0,79.1,88.29Z"
                  /><path
                    d="M98.92,45.05a.52.52,0,0,0-.27-.46C88.29,36,80,32.52,69.73,32.52c-5.41,0-9.55,1.08-12.34,3.25a9.09,9.09,0,0,0-3.7,7.47c0,8.47,10.36,12.35,22.44,16.85C82.34,62.43,88.74,64.78,94.6,68c.09.09.18.09.36.09h.27a.74.74,0,0,0,.45-.45,49.3,49.3,0,0,0,3.42-18A19.62,19.62,0,0,0,98.92,45.05Z"
                  /></svg>
                  <span>Shopware</span>
                </template>
                <template v-else-if="sw === 'Typo3'">
                  <svg viewBox="0 0 99.81 101.12"><path
                    d="M75.52,69.69a14,14,0,0,1-4.26.59c-12.84,0-31.7-44.87-31.7-59.8,0-5.5,1.31-7.34,3.14-8.91C27,3.41,8.12,9.17,2.1,16.51.79,18.34,0,21.22,0,24.89c0,23.32,24.89,76.23,42.44,76.23,8.12,0,21.82-13.36,33.08-31.43"
                  /><path
                    d="M67.33,0C83.57,0,99.81,2.62,99.81,11.79,99.81,30.39,88,52.92,82,52.92c-10.74,0-24.1-29.86-24.1-44.8C57.9,1.31,60.52,0,67.33,0"
                  /></svg>
                  <span>Typo3</span>
                </template>
                <!-- <template v-else-if="sw === 'oxid'">
                  <svg viewBox="0 -30 100 100"><path
                    d="M14.55,0A77.67,77.67,0,0,1,24,.35c2.47.36,4,1.5,4.49,3.53a31.42,31.42,0,0,1,.53,7,32.33,32.33,0,0,1-.53,7c-.53,2-2,3.18-4.49,3.53a75.91,75.91,0,0,1-9.35.35,76.7,76.7,0,0,1-9.44-.35c-2.47-.35-4-1.5-4.49-3.53C.18,16.49,0,14.11,0,10.76A31.66,31.66,0,0,1,.53,3.88c.53-2,2-3.17,4.5-3.53A79.13,79.13,0,0,1,14.55,0m0,5.56a38.4,38.4,0,0,0-6.17.26A2,2,0,0,0,6.79,7.14a15.21,15.21,0,0,0-.35,4.24,19.51,19.51,0,0,0,.17,2.73A2.48,2.48,0,0,0,8.91,16c1,.09,2.82.18,5.55.18,2.56,0,4.32-.09,5.2-.18a3.53,3.53,0,0,0,1.94-.62,2.45,2.45,0,0,0,.8-1.23,11.33,11.33,0,0,0,.17-2.73A28.2,28.2,0,0,0,22.4,8a3.4,3.4,0,0,0-.53-1.58,2.34,2.34,0,0,0-1.68-.8c-.79-.08-2.64-.08-5.64-.08M29.81.26h8.81l6.53,7.41L51.68.26h8.9L50.44,10.76,61.11,21.43H52l-6.88-7.59-6.88,7.59H29L39.86,10.76Zm32.62,0h6.71V21.43H62.43Zm9.7,21.17V.26H87.39c2.73,0,4.5,0,5.29.09a15.45,15.45,0,0,1,2.65.36,5.16,5.16,0,0,1,4.14,3.61,9.41,9.41,0,0,1,.44,2.38c.09.88.09,2.12.09,3.88s-.09,3.35-.18,4.32a11.11,11.11,0,0,1-.53,2.65A5,5,0,0,1,95.41,21c-.88.17-1.94.35-2.91.44s-2.73.09-5.11.09H72.13Zm6.35-5.38h9a31.52,31.52,0,0,0,3.61-.18,3.34,3.34,0,0,0,1.68-.53,2.11,2.11,0,0,0,.7-1.41A12.68,12.68,0,0,0,93.65,11a23.31,23.31,0,0,0-.18-3,2.9,2.9,0,0,0-.61-1.49,2.64,2.64,0,0,0-1.5-.71,33,33,0,0,0-4-.18H78.48Z"
                  /><path
                    d="M39.42,34.39a2.32,2.32,0,0,0-.8-2,3,3,0,0,0-1.41-.53,20.05,20.05,0,0,0-2.64-.17,15.24,15.24,0,0,0-2.29.17,2.37,2.37,0,0,0-1.24.53,2.61,2.61,0,0,0-.88,2Zm.09,2.91h1.58a2.66,2.66,0,0,1-1.23,2.47,9.37,9.37,0,0,1-4.23.71,37,37,0,0,1-3.8-.18,4.55,4.55,0,0,1-1.94-.62c-1-.7-1.49-2.11-1.49-4.14A6.28,6.28,0,0,1,29,32.28a3.13,3.13,0,0,1,1.77-1.42,13.42,13.42,0,0,1,3.79-.35,18.31,18.31,0,0,1,3.7.27,3,3,0,0,1,1.77,1,5.14,5.14,0,0,1,.79,1.5,13.22,13.22,0,0,1,.26,2.56H30.16a3.86,3.86,0,0,0,.7,2.56,2.16,2.16,0,0,0,1.42.62,25.21,25.21,0,0,0,3.17.17,16.14,16.14,0,0,0,2.47-.17,2.06,2.06,0,0,0,1.15-.53,3.63,3.63,0,0,0,.44-1.24"
                  /><path
                    d="M42.33,36.86H44v.27a3.37,3.37,0,0,0,.18,1.14.91.91,0,0,0,.71.53,15.34,15.34,0,0,0,3.88.27A22.06,22.06,0,0,0,51.59,39a2.82,2.82,0,0,0,1.14-.36,1.15,1.15,0,0,0,.44-1,1.39,1.39,0,0,0-.52-1.24,5,5,0,0,0-2-.35c-.36,0-1.85,0-4.5-.09a8,8,0,0,1-1.68-.18,2.68,2.68,0,0,1-1-.44,2.39,2.39,0,0,1-.8-2,2.53,2.53,0,0,1,1-2.21,3.48,3.48,0,0,1,1.68-.53,30.93,30.93,0,0,1,3.44-.17,18.48,18.48,0,0,1,3.7.26,3.45,3.45,0,0,1,1.76,1,2.5,2.5,0,0,1,.36.71,4.19,4.19,0,0,1,.09,1.23H52.91a1.71,1.71,0,0,0-.26-1.15,2.51,2.51,0,0,0-1.24-.44,18.55,18.55,0,0,0-2.64-.17,10.11,10.11,0,0,0-3.88.44,1.28,1.28,0,0,0-.53,1.14A1,1,0,0,0,45,34.39a6.48,6.48,0,0,0,2.38.27c1.15,0,2.65,0,4.5.08a3.32,3.32,0,0,1,2.38.71,2.58,2.58,0,0,1,.71,2.12A3.08,3.08,0,0,1,54.67,39a1.71,1.71,0,0,1-.88.88,4.88,4.88,0,0,1-1.67.44c-.8.09-1.86.09-3.35.09a32.9,32.9,0,0,1-3.62-.18,4.12,4.12,0,0,1-1.76-.53,2.47,2.47,0,0,1-.8-.88,3.61,3.61,0,0,1-.26-1.5c-.09,0,0-.17,0-.44"
                  /><path
                    d="M61.82,35.8a20.56,20.56,0,0,0-2.74.09c-.53.09-.88.09-1.06.27a1.38,1.38,0,0,0-.61,1.32,1.44,1.44,0,0,0,.7,1.32,15.36,15.36,0,0,0,4,.27,8.58,8.58,0,0,0,3.26-.36,1.21,1.21,0,0,0,.8-1.32,1.32,1.32,0,0,0-.88-1.32,13.46,13.46,0,0,0-3.44-.27m-4-2.38H56.26V33a2.06,2.06,0,0,1,1.15-2.12,12,12,0,0,1,4.32-.52,16.89,16.89,0,0,1,3.61.26,3.75,3.75,0,0,1,1.77.88,3.4,3.4,0,0,1,.7,1.24A13.84,13.84,0,0,1,68,35.19v5.2H66.4V39.24h-.09A2.74,2.74,0,0,1,64.9,40.3a11.61,11.61,0,0,1-3.08.26c-1.77,0-2.91,0-3.62-.08A3,3,0,0,1,56.7,40a1.66,1.66,0,0,1-.7-.88,3.22,3.22,0,0,1-.18-1.42,4.76,4.76,0,0,1,.26-1.76A1.57,1.57,0,0,1,57.14,35a10.74,10.74,0,0,1,3.71-.35,32.39,32.39,0,0,1,4,.17,2,2,0,0,1,1.42.88h.17V34.39A3.11,3.11,0,0,0,66,32.54a2.42,2.42,0,0,0-1.23-.71A19.57,19.57,0,0,0,62,31.66a12.85,12.85,0,0,0-3.26.26c-.53.18-.71.62-.71,1.33v.17Z"
                  /><rect
                    x="69.58"
                    y="26.1"
                    width="1.59"
                    height="14.2"
                  /><path
                    d="M83.6,34.39a2.32,2.32,0,0,0-.8-2,3,3,0,0,0-1.41-.53,20.05,20.05,0,0,0-2.64-.17,15.24,15.24,0,0,0-2.29.17,2.37,2.37,0,0,0-1.24.53,2.61,2.61,0,0,0-.88,2Zm.09,2.91h1.58A2.66,2.66,0,0,1,84,39.77a9.37,9.37,0,0,1-4.23.71A37,37,0,0,1,76,40.3a4.51,4.51,0,0,1-1.94-.62c-1-.7-1.5-2.11-1.5-4.14a6.27,6.27,0,0,1,.62-3.26A3.13,3.13,0,0,1,75,30.86a13.42,13.42,0,0,1,3.79-.35,18.31,18.31,0,0,1,3.7.27,3,3,0,0,1,1.77,1,5.14,5.14,0,0,1,.79,1.5,13.22,13.22,0,0,1,.26,2.56H74.34a3.86,3.86,0,0,0,.7,2.56,2.16,2.16,0,0,0,1.42.62,25.21,25.21,0,0,0,3.17.17,16.14,16.14,0,0,0,2.47-.17,2.06,2.06,0,0,0,1.15-.53,3.63,3.63,0,0,0,.44-1.24"
                  /><path
                    d="M86.42,36.86H88.1v.27a3.41,3.41,0,0,0,.17,1.14.91.91,0,0,0,.71.53,15.34,15.34,0,0,0,3.88.27A22.06,22.06,0,0,0,95.68,39a2.94,2.94,0,0,0,1.15-.36,1.17,1.17,0,0,0,.44-1,1.39,1.39,0,0,0-.53-1.24,5,5,0,0,0-2-.35c-.35,0-1.85,0-4.5-.09a7.94,7.94,0,0,1-1.67-.18,2.73,2.73,0,0,1-1.06-.44,2.39,2.39,0,0,1-.8-2,2.53,2.53,0,0,1,1-2.21,3.52,3.52,0,0,1,1.68-.53,30.93,30.93,0,0,1,3.44-.17,18.48,18.48,0,0,1,3.7.26,3.45,3.45,0,0,1,1.77,1,2.75,2.75,0,0,1,.35.71,4.19,4.19,0,0,1,.09,1.23H97a1.71,1.71,0,0,0-.26-1.15,2.51,2.51,0,0,0-1.24-.44,18.44,18.44,0,0,0-2.64-.17,10.11,10.11,0,0,0-3.88.44,1.28,1.28,0,0,0-.53,1.14,1,1,0,0,0,.62,1.06,6.41,6.41,0,0,0,2.38.27c1.14,0,2.64,0,4.49.08a3.75,3.75,0,0,1,2.47.71,2.58,2.58,0,0,1,.71,2.12A3.08,3.08,0,0,1,98.85,39a1.71,1.71,0,0,1-.88.88,4.88,4.88,0,0,1-1.67.44c-.8.09-1.86.09-3.35.09a32.9,32.9,0,0,1-3.62-.18,4.12,4.12,0,0,1-1.76-.53,2.47,2.47,0,0,1-.8-.88,3.61,3.61,0,0,1-.26-1.5c-.09,0-.09-.17-.09-.44"
                  /></svg>
                  <span>Oxid</span>
                </template> -->
                <template v-else-if="sw === 'Wordpress'">
                  <svg viewBox="0 0 100 100"><path d="M7.11,50A42.88,42.88,0,0,0,31.28,88.6L10.82,32.54A42.76,42.76,0,0,0,7.11,50Z" /><path
                    d="M79,47.83A22.62,22.62,0,0,0,75.42,36c-2.17-3.53-4.21-6.52-4.21-10a7.43,7.43,0,0,1,7.2-7.62c.19,0,.37,0,.56,0a42.89,42.89,0,0,0-64.81,8.07c1,0,2,0,2.76,0,4.49,0,11.43-.54,11.43-.54a1.77,1.77,0,0,1,.28,3.53s-2.32.27-4.91.41L39.34,76.35,48.73,48.2,42,29.89c-2.31-.14-4.49-.41-4.49-.41-2.32-.14-2-3.67.27-3.53,0,0,7.08.54,11.29.54,4.49,0,11.44-.54,11.44-.54a1.77,1.77,0,0,1,.27,3.53s-2.33.27-4.91.41L71.41,76l4.28-14.3C77.55,55.77,79,51.51,79,47.83Z"
                  /><path
                    d="M50.75,53.75,37.88,91.15a43,43,0,0,0,26.36-.69,3.63,3.63,0,0,1-.3-.59Z"
                  /><path
                    d="M87.64,29.42a33.66,33.66,0,0,1,.29,4.41,40.43,40.43,0,0,1-3.27,15.36L71.56,87.08A42.92,42.92,0,0,0,87.64,29.42Z"
                  />
                    <path d="M50,0a50,50,0,1,0,50,50A50.06,50.06,0,0,0,50,0Zm0,97.71A47.71,47.71,0,1,1,97.71,50,47.77,47.77,0,0,1,50,97.71Z" /></svg>
                  <span>Wordpress</span>
                </template>
              </li>
            </ul>
          </div>
          <div v-if="form.preInstall">
            <div
              class="fieldset"
              :class="{'fieldset-error' : $v.form.softwareUser.$error}"
            >
              <label for="softwareUser">Software-Benutzer</label>
              <input
                v-model="$v.form.softwareUser.$model"
                type="text"
                name="softwareUser"
                placeholder="Benutzername"
              >
            </div>
            <div
              class="fieldset"
              :class="{'fieldset-error' : $v.form.softwareEmail.$error}"
            >
              <label for="softwareEmail">E-Mail</label>
              <input
                v-model="$v.form.softwareEmail.$model"
                type="text"
                name="softwareEmail"
                placeholder="E-Mail-Adresse"
              >
            </div>
            <div
              class="fieldset"
              :class="{'fieldset-error' : $v.form.softwarePw.$error}"
            >
              <label for="softwarePw">Passwort</label>
              <input
                v-model="$v.form.softwarePw.$model"
                type="password"
                name="softwarePw"
                placeholder="Passwort"
              >
              <div class="password-instructions">
                <span
                  v-if="!$v.form.softwarePw.lower || !$v.form.softwarePw.upper || !$v.form.softwarePw.digit || !$v.form.softwarePw.minLength"
                  class="text"
                >Mindestens</span>
                <span v-if="!$v.form.softwarePw.lower">1 Kleinbuchstabe</span>
                <span v-if="!$v.form.softwarePw.upper">1 Großbuchstabe</span>
                <span v-if="!$v.form.softwarePw.digit">1 Ziffer</span>
                <span v-if="!$v.form.softwarePw.minLength">{{ $v.form.softwarePw.$params.minLength.min }} Zeichen</span>
              </div>
            </div>
            <div
              class="fieldset"
              :class="{'fieldset-error' : $v.form.repeatPass.$error}"
            >
              <label for="repeat-pass">Passwort wiederholen</label>
              <input
                v-model="$v.form.repeatPass.$model"
                type="password"
                name="repeat-pass"
                placeholder="Passwort wiederholen"
              >
            </div>
          </div>
          <SubmitBtn
            label="Instanz anlegen"
            :submitting="submitting"
          />
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { required, requiredIf, minLength, sameAs, helpers } from 'vuelidate/lib/validators'

// Custom Validators
const lower = helpers.regex('lower', /[a-z]+/)
const upper = helpers.regex('upper', /[A-Z]+/)
const digit = helpers.regex('digit', /[0-9]+/)

export default {
  name: 'Instances',
  components: {
    Modal: () => import('../components/Modal'),
    InstanceList: () => import('../components/instances/InstanceList'),
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  data() {
    return {
      loading: false,
      submitting: false,

      instances: [],
      domains: [],
      form: {
        name: '',
        domain: '',
        aliases: '',
        webTemplate: '',
        proxyTemplate: '',
				caching: false,
				pagespeed: false,
        homeDir: '/',
        preInstall: null,
        ssl: true,
        softwareUser: '',
        softwareEmail: '',
        softwarePw: '',
        repeatPass: ''
      },
      
      software: ['Wordpress', 'Typo3', 'Shopware']
    }
  },

  validations: {
    form: {
      name: {required},
      softwareUser: {
        required: requiredIf(function() {
          return this.form.preInstall
        })
      },
      softwareEmail: {
        required: requiredIf(function() {
          return this.form.preInstall
        })
      },
      softwarePw: {
        required: requiredIf(function() {
          return this.form.preInstall
        }),
        digit, lower, upper, minLength: minLength(8)
      },
      repeatPass: {
        required: requiredIf(function() {
          return this.form.preInstall
        }),
        sameAsPassword: sameAs('softwarePw')
      },
    },
  },

  computed: {
    ...mapGetters([
      'user',
      'box',
      'accountType',
      'id',
      'isAdmin',
      'isCustomerAdmin'
    ]),

    availableDomains() {
      return this.domains.filter(domain => !domain.main && domain.mapped && !domain.name.match(/^bx/) && !domain.instance)
    },

    availableAliases() {
      return this.availableDomains.filter(domain => !!this.form.domain && domain.name !== this.form.domain.name)
    },

		webTemplates () {
			const phpOnly = this.box.server.webTemplates.filter(e => e.includes('PHP-FPM-'))
			let finalList = []
			for (const i in phpOnly) {
				const onlyVersion = phpOnly[i].match(/\d+$/)[0].slice(0, -1) +'.'+phpOnly[i].slice(-1)
				finalList.push(onlyVersion)
			}
			return finalList
		}
  },

  watch: {
    'box': {
      immediate: true,
      handler() {
        this.fetchInstances()
        this.fetchDomains()
      }
    }
  },

  async created() {
    this.loading = true
  },

  methods: {
    openModal() {
      this.$refs.modal.open()
      this.form.softwareEmail = this.user.email
    },

    closeModal() {
      this.$refs.modal.close()
    },

    async fetchInstances() {
      if(this.id && this.box) {
        if(this.accountType === 'organisation') {
          const instances = await this.$axios.get(`/api/organisation/${this.id}/instances`)
          // console.log('ORG', instances)
          this.instances = instances.data
        } else {
          const instances = await this.$axios.get(`/api/user/${this.id}/box/${this.box.id}/instances`).catch(err => console.error(err))
          // console.log('box', instances)
          this.instances = instances.data
        }
        this.loading = false
      }
    },

    async fetchDomains() {
      if(this.id && this.box) {
        const domains = await this.$axios.get(`/api/${this.accountType}/${this.id}/box/${this.box.id}/domains`)
        this.domains = domains.data
      }
    },

    async addInstance() {
      this.$v.$touch()
      if(!this.$v.form.name.required) {
        this.$notify.error(`Instanzname benötigt`)
      } else {
        const formData = {
          name: this.form.name,
          ssl: this.form.ssl,
					modPagespeed: this.form.pagespeed,
					caching: this.form.caching
        }
        if(this.form.domain) formData.domain = this.form.domain.id
        if(this.form.aliases) formData.aliases = this.form.aliases.join()
        if(this.form.preInstall) {
          formData.preInstall = this.form.preInstall
          formData.softwareUser = this.form.softwareUser,
          formData.softwareEmail = this.form.softwareEmail,
          formData.softwarePw = this.form.softwarePw
        }
        if(this.form.webTemplate) formData.webTemplate = 'PHP-FPM-' + this.form.webTemplate.replace('.', '')
        if(this.form.homeDir) formData.homeDir = this.form.homeDir === '/' ? 'default' : this.form.homeDir

        this.submitting = true
        this.closeModal()
        this.$notify.info(`Instanz wird erstellt`)
        setTimeout(() => {
          this.$notify.info(`${this.form.preInstall} wird installiert`)
        },100)
        this.$axios.post(`/api/${this.accountType}/${this.id}/box/${this.box.id}/instance`, formData).then(async () => {
          await this.fetchInstances()
          this.submitting = false
          this.$notify.success('Instanz erstellt')
        }).catch(async error => {
          await this.fetchInstances()
          this.submitting = false
          if (error.response.data.error === 'database limit reached') {
            this.$notify.error('Datenbanklimit erreicht')
          } else {
            this.$notify.error('Installation fehlgeschlagen')
          }
          console.log(error, error.response)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.software {
	display: grid;
	grid-gap: $spacer-sm;
	grid-template-columns: repeat(auto-fit,minmax(100px,1fr));

	.sw {
		cursor: pointer;
		font-weight: $medium;
		text-align: center;
		user-select: none;

		svg {
			width: 50px;
			display: block;
			height: auto;
			margin: 0 auto $spacer-sm auto;
			fill: lighten($color-primary, 25%);
		}

		&:hover {
			svg {
				fill: $color-primary;
			}
		}

		&.selected {
			background: $color-primary;
			color: $color-bg;

			svg {
				fill: $color-bg;
			}
		}
	}
}
</style>