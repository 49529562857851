<template>
  <div class="mail container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Postfächer
        <span v-if="mailboxes && mailboxes.length && box">{{ mailboxes.length }}/{{ box.product.mailboxes }}</span>
      </h1>
      <div
        v-if="accountType === 'user' || (accountType === 'organisation' && isCustomerAdmin) || isAdmin"
        class="actions"
      >
        <button
          v-if="box"
          class="btn"
          :disabled="mailboxes.length >= box.product.mailboxes"
          @click.prevent="openModal"
        >
          <span class="plus">+</span> Neues Konto
        </button>
        <a
          v-if="box"
          class="btn btn-secondary"
          :href="`https://${box.server.url}/webmail`"
          target="_blank"
        >
          Webmail
        </a>
      </div>
    </div>

    <div class="search mb-lg">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Mailkonten filtern"
      >
      <a
        v-if="searchQuery"
        href="#"
        class="icon clear"
        @click.prevent="clearFilter"
      ><span /><span /></a>
    </div>

    <!-- <Mailboxes
      id="id"
      v-if="box && mailboxes"
      :boxid="box.id"
      :user="user"
      :account-type="accountType"
      :mailboxes="mailboxes"
    /> -->

    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <div
        v-if="loading && !mailboxes.length"
        key="loading"
        class="skeleton info"
      >
        Mailkonten werden geladen...
      </div>
      <div
        v-else-if="mailboxes.length"
        key="table"
        class="mailboxes-table table"
      >
        <!-- Head -->
        <div
          key="head"
          class="row head"
          :class="currentSortDir"
        >
          <div
            class="cell"
            @click.prevent="sort('account')"
          >
            Konto
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>
        </div>
        <!-- Body -->
        <Mailbox
          v-for="mailbox in sortedMails"
          :key="mailbox.id"
          :mailbox="mailbox"
          @edit="edit"
        />
        <div
          v-if="mailboxes.length && sortedMails < 1"
          key="no-results"
          class="row no-results"
        >
          <div class="cell">
            Keine Mailkonten gefunden
          </div>
        </div>
      </div>
      <div
        v-else
        key="noresults"
        class="info"
      >
        <template v-if="isAdmin || isCustomerAdmin">
          Es wurden noch keine Mailkonten für diese Box angelegt. Klicke auf "Hinzufügen", um ein erstes Mailkonto zu erstellen.
        </template>
        <template v-else>
          Es wurden noch keine Mailkonten für diese Box angelegt.
        </template>
      </div>
    </transition>

    <Modal ref="modal">
      <template #content>
        <form
          @submit.prevent="createMailbox"
        >
          <h2 class="mb">
            Mailkonto erstellen
          </h2>
          <div
            class="fieldsetgroup address"
          >
            <div
              class="fieldset account"
              :class="{'fieldset-error' : $v.form.account.$error}"
            >
              <label for="account">Account</label>
              <input
                v-model.trim="$v.form.account.$model"
                type="text"
                name="account"
              >
            </div>
            <div class="fieldset at">
              @
            </div>
            <div
              class="fieldset domain"
              :class="{'fieldset-error' : $v.form.mailDomain.$error}"
            >
              <label for="domain">Domain</label>
              <v-select
                v-model.trim="$v.form.mailDomain.$model"
                name="domain"
                label="name"
                :options="availableDomains"
                :placeholder="'Domain wählen...'"
                :clearable="false"
              >
                <template #no-options>
                  <legend>
                    Keine Domains gefunden<br>
                    <router-link to="/domains">
                      Neue Domain anlegen
                    </router-link>
                  </legend>
                </template>
              </v-select>
            </div>
          </div>
          <div class="fieldset alias">
            <label for="alias">Aliase hinzufügen (optional)</label>
            <input
              v-model.trim.lazy="form.alias"
              type="text"
              name="alias"
              placeholder="alias1, alias2, alias3"
            >
          </div>
          <div class="fieldset margin-bot">
            <label for="forward">Weiterleitungen <template v-if="!form.forwardOnly">(Optional)</template></label>
            <input
              v-model.trim="form.forward"
              type="text"
              name="forward"
              autocomplete="off"
              placeholder="mail@domain1.de, mail@domain2.de, usw."
            >
            <legend
              v-if="form.forwardOnly"
              class="mt-sm"
            >
              An welche Adresse(n) sollen Nachrichten weitergeleitet werden.
            </legend>
            <legend
              v-else
              class="mt-sm"
            >
              Legen Sie hier fest, an welche Adresse(n) die empfangenen Nachrichten dieses Postfachs zusätzlich gesendet werden sollen.
            </legend>
          </div>
          <div
            class="fieldset password"
            :class="{'fieldset-error' : $v.form.password.$error}"
          >
            <label for="password">Passwort</label>
            <input
              v-model.trim="$v.form.password.$model"
              type="password"
              autocomplete="new-password"
              name="password"
              placeholder="Passwort"
            >
            <div class="password-instructions">
              <span
                v-if="!$v.form.password.lower || !$v.form.password.upper || !$v.form.password.digit || !$v.form.password.minLength"
                class="text"
              >Mindestens</span>
              <span v-if="!$v.form.password.lower">1 Kleinbuchstabe</span>
              <span v-if="!$v.form.password.upper">1 Großbuchstabe</span>
              <span v-if="!$v.form.password.digit">1 Ziffer</span>
              <span v-if="!$v.form.password.minLength">{{ $v.form.password.$params.minLength.min }} Zeichen</span>
            </div>
          </div>
          <div
            class="fieldset margin-bot"
            :class="{'fieldset-error' : $v.form.repeatPassword.$error}"
          >
            <label for="repeat-password">Passwort wiederholen</label>
            <input
              v-model.trim="$v.form.repeatPassword.$model"
              type="password"
              autocomplete="new-password"
              name="repeat-password"
              placeholder="Passwort wiederholen"
            >
          </div>
          <!-- <div class="fieldset size">
            Postfachgröße
            <input class="size-text" type="number" min="1" max="20" @input="limitSize()" v-model="form.size">
            GB
            <input type="range" min="1" max="20" v-model="form.size" class="rangeslider">
          </div> -->

          <div class="checkbox">
            <label class="switch">
              <span>Nur Weiterleitung</span>
              <input
                v-model="form.forwardOnly"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>

          <div class="checkbox">
            <label class="switch">
              <span>Automatische Antwort</span>
              <input
                v-model="form.isAutoreply"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>

          <div v-show="form.isAutoreply">
            <editor
              v-model="form.autoreply"
              api-key="0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd"
              :init="{
                menubar: false,
                branding: false,
                content_style: 'body { font-size: 13px; }',
                plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright | fullscreen | link image | \ bullist numlist outdent indent | removeformat | help | code'
              }"
            />
          </div>

          <SubmitBtn
            class="mt"
            :label="form.type === 'Weiterleitung' ? 'Weiterleitung anlegen' : 'Postfach anlegen'"
            :submitting="submitting"
            :disabled="$v.form.$invalid"
            @click.native.prevent="createMailbox"
          />
        </form>
      </template>
    </Modal>
    <Modal
      ref="edit"
      @modalClosed="modalClosed"
    >
      <template #content>
        <form
          class="update"
          @submit.prevent="updateMailbox"
        >
          <h2>Mail-Konto bearbeiten</h2>
          <h3 class="mb">
            {{ currentMailbox.name }}
          </h3>
          <div class="fieldset alias">
            <label for="alias">Aliase</label>
            <input
              v-model.trim="update.alias"
              type="text"
              name="alias"
              placeholder="alias1, alias2, alias3"
            >
          </div>
          <div class="fieldset margin-bot">
            <label for="forward">Weiterleitungen</label>
            <!-- <template v-if="!update.forwardOnly">(Optional)</template> -->
            <input
              v-model.trim="update.forward"
              type="text"
              name="forward"
              placeholder="mail@domain1.de, mail@domain2.de, usw."
            >
            <legend
              v-if="update.forwardOnly"
              class="mt-sm"
            >
              An welche Adresse(n) sollen empfangene Nachrichten weitergeleitet werden. (E-Mails werden nicht im eigenen Postfach gespeichert.)
            </legend>
            <legend
              v-else
              class="mt-sm"
            >
              Legen Sie hier fest, an welche Adresse(n) die empfangenen Nachrichten dieses Postfachs zusätzlich gesendet werden sollen.
            </legend>
          </div>
          <div
            class="fieldset password"
            :class="{'fieldset-error' : $v.update.password.$error}"
          >
            <label for="password">Passwort ändern</label>
            <input
              v-model.trim="$v.update.password.$model"
              type="password"
              autocomplete="new-password"
              name="password"
              placeholder="Neues Passwort"
            >
            <div class="password-instructions">
              <span
                v-if="!$v.update.password.lower || !$v.update.password.upper || !$v.update.password.digit || !$v.update.password.minLength"
                class="text"
              >Mindestens</span>
              <span v-if="!$v.update.password.lower">1 Kleinbuchstabe</span>
              <span v-if="!$v.update.password.upper">1 Großbuchstabe</span>
              <span v-if="!$v.update.password.digit">1 Ziffer</span>
              <span v-if="!$v.update.password.minLength">{{ $v.update.password.$params.minLength.min }} Zeichen</span>
            </div>
          </div>
          <div
            class="fieldset margin-bot"
            :class="{'fieldset-error' : $v.update.repeatPassword.$error}"
          >
            <label for="repeat-password">Passwort wiederholen</label>
            <input
              v-model.trim="$v.update.repeatPassword.$model"
              type="password"
              autocomplete="new-password"
              name="repeat-password"
              placeholder="Passwort wiederholen"
            >
          </div>

          <div class="checkbox">
            <label class="switch">
              <span>Nur Weiterleitung</span>
              <input
                v-model="update.forwardOnly"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>

          <div class="checkbox">
            <label class="switch">
              <span>Automatische Antwort</span>
              <input
                v-model="update.isAutoreply"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>
          <div v-show="update.isAutoreply">
            <editor
              v-model="update.autoreply"
              api-key="0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd"
              :init="{
                menubar: false,
                branding: false,
                content_style: 'body { font-size: 13px; }',
                block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
                plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright | fullscreen | link image | \ bullist numlist outdent indent | removeformat | help | code'
              }"
            />
          </div>
          <SubmitBtn
            class="mt"
            label="Änderungen speichern"
            :submitting="submitting"
          />
          <SubmitBtn
            class="mt-sm"
            red
            label="Postfach löschen"
            :submitting="deleting"
            @click.native.prevent="deleteMailbox"
          />
        </form>
      </template>
    </Modal>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters} from 'vuex'
import { required, requiredIf, minLength, sameAs, helpers } from 'vuelidate/lib/validators'
import Editor from '@tinymce/tinymce-vue'

// Custom Validators
const lower = helpers.regex('lower', /[a-z]+/)
const upper = helpers.regex('upper', /[A-Z]+/)
const digit = helpers.regex('digit', /[0-9]+/)

export default {
  name: 'Mail',

  components: {
    Mailbox: () => import('../components/mail/Mailbox'),
    Modal: () => import('../components/Modal'),
    Dialog: () => import('../components/Dialog'),
    SubmitBtn: () => import('../components/SubmitBtn'),
    'editor': Editor
  },

  data() {
    return {
      loading: false,
      submitting: false,
      deleting: false,

      currentMailbox: null,
      mailboxes: [],
      domains: [],

      form: {
        account: '',
        mailDomain: '',
        alias: null,
        forward: null,
        forwardOnly: false,
        isAutoreply: false,
        autoreply: '<h3>Sehr geehrte Damen und Herren,</h3><p>ich bin momenten im Urlaub und wieder am xx.xx.xxxx zurück im Büro.<br /><br />Mit freundlichen Grüßen<br /></p>',
        // size: 0.25,
        password: '',
        repeatPassword: '',
      },

      update: {
        account: '',
        mailDomain: '',
        alias: null,
        forward: null,
        forwardOnly: null,
        isAutoreply: null,
        autoreply: '',
        password: '',
        repeatPassword: '',
      },

      currentSort: 'name',
      currentSortDir: 'asc',
      searchQuery: ''
    }
  },

  validations: {
    form: {
      account: {required},
      mailDomain: {required},
      // size: {required},
      password: {required, digit, lower, upper, minLength: minLength(8)},
      repeatPassword: {required, sameAsPassword: sameAs('password')},
      forward: {
        required: requiredIf(function() {
          return this.form.forwardOnly
        })
      },
    },
    update: {
      password: {digit, lower, upper, minLength: minLength(8)},
      repeatPassword: {
        required: requiredIf(function() {
          return this.update.password
        }),
        sameAsPassword: sameAs('password')
      },
      forward: {
        required: requiredIf(function() {
          return this.update.forwardOnly
        })
      }
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'customer',
      'box',
      'accountType',
      'id',
      'isAdmin',
      'isCustomerAdmin'
    ]),

    sortedMails() {
      if(this.mailboxes) {
        var filteredMails = this.mailboxes.filter(e => {
          return e.account ? e.account.toLowerCase().includes(this.searchQuery.toLowerCase()) : null
        })
        return _.orderBy(filteredMails, this.currentSort, this.currentSortDir) 
      } else {
        return []
      }
    },

    availableDomains() {
      // if(this.domains.length) {
      return this.domains.filter(domain => !domain.main && domain.mapped && !domain.name.match(/^bx/))
      // }
    },

    processedAlias() {
      if(this.form.alias) {
        return this.form.alias.includes(',') ? this.form.alias.split(',').map(e => e.trim()) : [this.form.alias]
      } else {
        return []
      }
    },

    processedRedirects() {
      if(this.form.forward) {
        return this.form.forward.includes(',') ? this.form.forward.split(',').map(e => e.trim()) : [this.form.forward]
      } else {
        return []
      }
    },

    processedRedirectsUpdate() {
      if(this.update.forward) {
        return this.update.forward.includes(',') ? this.update.forward.split(',').map(e => e.trim()) : [this.update.forward]
      } else {
        return []
      }
    },

    processedAliasUpdate() {
      if(this.update.alias) {
        return this.update.alias.includes(',') ? this.update.alias.split(',').map(e => e.trim()) : [this.update.alias]
      } else {
        return []
      }
    },
  },

  watch: {
    'box': {
      immediate: true,
      handler() {
        this.fetchMailboxes()
        this.fetchDomains()
      }
    }
  },

  async created() {
    this.loading = true
  },

  methods: {
    async fetchMailboxes() {
      if(this.id && this.box) {
        const mailboxes = await this.$axios.get(`/api/user/${this.id}/box/${this.box.id}/mailboxes`)
        this.mailboxes = mailboxes.data
        this.loading = false
      }
    },

    async fetchDomains() {
      if(this.id && this.box) {
        const domains = await this.$axios.get(`/api/${this.accountType}/${this.id}/box/${this.box.id}/domains`)
        this.domains = domains.data
      }
    },

    openModal() {
      if(this.$refs && this.$refs.modal) {
        this.$refs.modal.open()
      }
    },

    closeModal() {
      this.$refs.modal.close()
      this.$refs.edit.close()
    },

    modalClosed() {
      setTimeout(() => {
        this.currentMailbox = null
        this.update.forward = ''
        this.update.alias = ''
        this.update.packageId = ''
        this.update.account = ''
        this.update.forwardOnly = null
        this.update.mailDomain = ''
        this.update.autoreply = ''
      }, 500)
    },

    edit(mailbox) {
      this.$refs.edit.open()
      this.currentMailbox = mailbox
      this.update.alias = mailbox.config.aliases.join(', ')
      this.update.forward = mailbox.config.forwards.join(', ')
      this.update.forwardOnly = mailbox.config.forwardonly
      this.update.isAutoreply = mailbox.config.autoreply.active
      this.update.autoreply = mailbox.config.autoreply.message
    },

    async createMailbox() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify.error('Eingaben prüfen')
      } else {
        this.submitting = true
        const formData = {
          account: this.form.account,
          domain: this.form.mailDomain.id,
          password: this.form.password,
          config: {
            aliases: this.processedAlias,
            autoreply: {
              active: this.form.isAutoreply ? true : false,
              message: this.form.autoreply
            },
            forwardonly: this.form.forwardOnly ? true : false,
            forwards: this.processedRedirects
          }
        }
        
        await this.$axios.post(`/api/user/${this.id}/box/${this.box.id}/mailbox`, formData).then(async () => {
          await this.fetchMailboxes()
          this.$notify.success('Mailkonto erstellt')
          this.closeModal()
          this.submitting = false
        }).catch(error => {
          console.log(error)
          this.submitting = false
          if(error.response.data.error.includes('already exists')) this.$notify.error('Konto existiert bereits')
        })
      }
    },

    async updateMailbox() {
      this.$v.update.$touch()
      if (this.$v.update.$invalid) {
        this.$notify.error('Eingaben prüfen')
      } else {
        let formData = {
          password: this.update.password ? this.update.password : null,
          config: {
            aliases: this.processedAliasUpdate,
            autoreply: {
              active: this.update.isAutoreply ? true : false,
              message: this.update.autoreply
            },
            forwardonly: this.update.forwardOnly ? true : false,
            forwards: this.processedRedirectsUpdate
          }
        }

        this.submitting = true

        await this.$axios.put(`/api/user/${this.id}/box/${this.box.id}/mailbox/${this.currentMailbox.id}`, formData).then(async () => {
          await this.fetchMailboxes()
          this.$notify.success('Änderungen gespeichert')
          this.submitting = false
        }).catch(err => {
          console.log(err)
          this.submitting = false
        })
      }
    },

    async deleteMailbox() {
      const confirmation = await this.$refs.dialog.open(`
				<h3>Postfach löschen</h3>
				Soll das Postfach wirklich gelöscht werden?
			`)
      if (confirmation === 'ok') {
        this.deleting = true
        await this.$axios.delete(`/api/user/${this.id}/box/${this.box.id}/mailbox/${this.currentMailbox.id}`).then(async () => {
          await this.fetchMailboxes()
          this.deleting = false
          this.$notify.success('Postfach gelöscht')
          this.closeModal()
        }).catch(error => {
          this.deleting = false
          console.log(error)
          this.$notify.error('Fehlgeschlagen')
        })
      }
    },

    sort(s) {
      if(s === this.currentSort) this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      this.currentSort = s
    },

    clearFilter() {
      this.searchQuery = ''
      this.currentSort = 'account'
      this.currentSortDir = 'asc'
    },
  }
}
</script>

<style lang="scss" scoped>
.page-head .actions {
  display: flex;

  .btn:last-child {
    margin-left: $spacer-sm;
  }
}

.mailboxes-table {
  .row {
    grid-template-columns: 3fr 1fr;
  }
}

.fieldsetgroup.address {
  .fieldset {
    margin-right: 10px;
    width: auto;

    &.account {
      max-width: 130px;
    }

    &.at {
      color: $color-gray;
      max-width: 12px;
      font-weight: 500;
      max-width: 12px;
      padding-top: 24px;
    }

    &.domain {
      flex-shrink: 0;
      flex-grow: 1;
      margin-right: 0;
    }
  }
}
</style>
