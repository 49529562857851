var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings container" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.compiling
          ? _c("div", { staticClass: "compiling" }, [
              _c("h2", { staticClass: "mb" }, [
                _vm._v(
                  "\n          Farbeinstellungen werden übernommen\n        "
                )
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Applikation wird rekompiliert")])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "mb" }, [_vm._v("\n      Einstellungen\n    ")]),
      _vm._v(" "),
      _vm.mounted && _vm.config
        ? _c(
            "tabs",
            { key: _vm.tabsKey },
            [
              _c("tab", { attrs: { title: "Allgemein" } }, [
                _c("form", [
                  _c(
                    "div",
                    { staticClass: "general grid justify-space-between" },
                    [
                      _c("div", { staticClass: "logo col-12 col-sm-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "upload card",
                            class: [
                              { dragenter: _vm.dragging === "logo" },
                              { uploading: _vm.uploadingLogo }
                            ],
                            attrs: { for: "logo" }
                          },
                          [
                            _vm.config.logoName
                              ? _c("img", {
                                  key: _vm.config.logoName,
                                  attrs: {
                                    src:
                                      _vm.baseUrl +
                                      "/images/" +
                                      _vm.config.logoName,
                                    alt: "logo"
                                  }
                                })
                              : _c("div", { staticClass: "no-logo" }, [
                                  _vm._v(
                                    "\n                  Kein Logo\n                "
                                  )
                                ]),
                            _vm._v(" "),
                            _c("input", {
                              ref: "input",
                              attrs: {
                                name: "logo",
                                type: "file",
                                accept:
                                  "image/png, image/gif, image/jpeg, image/svg+xml"
                              },
                              on: {
                                change: _vm.uploadLogo,
                                drop: function($event) {
                                  $event.preventDefault()
                                  return _vm.uploadLogo($event)
                                },
                                dragover: function($event) {
                                  $event.preventDefault()
                                },
                                dragenter: function($event) {
                                  $event.preventDefault()
                                  _vm.dragging = "logo"
                                },
                                dragend: function($event) {
                                  $event.preventDefault()
                                  _vm.dragging = false
                                },
                                dragleave: function($event) {
                                  $event.preventDefault()
                                  _vm.dragging = false
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("transition", { attrs: { name: "fade" } }, [
                              _vm.uploadingLogo
                                ? _c("span", { staticClass: "uploading" }, [
                                    _vm._v("Logo wird hochgeladen...")
                                  ])
                                : _vm.dragging === "logo"
                                ? _c("span", { staticClass: "hot" }, [
                                    _vm._v(
                                      "\n                    Drop it like it's hot!\n                  "
                                    )
                                  ])
                                : _c("span", [
                                    _c("b", [_vm._v("Logo auswählen")]),
                                    _c("br"),
                                    _vm._v(
                                      "\n                    oder per Drag'n'Drop hier ablegen\n                  "
                                    )
                                  ])
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.config.logoName
                          ? _c(
                              "a",
                              {
                                staticClass: "delete-logo mt",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.deleteLogo($event)
                                  }
                                }
                              },
                              [_vm._v("Logo löschen")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-7" }, [
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "company" } }, [
                            _vm._v("Firmenname")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.company,
                                expression: "form.company"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "company",
                              placeholder: "Mein Firmenname"
                            },
                            domProps: { value: _vm.form.company },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "company",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "name" } }, [
                            _vm._v("E-Mail")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.email,
                                expression: "form.email"
                              }
                            ],
                            attrs: {
                              type: "email",
                              name: "email",
                              placeholder: "meine@email.tld"
                            },
                            domProps: { value: _vm.form.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "email", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "phone" } }, [
                            _vm._v("Telefon")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.phone,
                                expression: "form.phone"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "phone",
                              placeholder: "+49123456789"
                            },
                            domProps: { value: _vm.form.phone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "phone", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "diskWarning" } }, [
                            _vm._v("Server-Auslastung Warnungsgrenze")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.diskWarning,
                                expression: "form.diskWarning"
                              }
                            ],
                            attrs: {
                              type: "number",
                              name: "diskWarning",
                              placeholder: "70"
                            },
                            domProps: { value: _vm.form.diskWarning },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "diskWarning",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("legend", { staticClass: "mt-sm" }, [
                            _vm._v(
                              "\n                  Ab wieviel Prozent Auslastung der Server (RAM/CPU/Disk) eine E-Mail-Benachrichtigung verschickt werden soll.\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "vat" } }, [
                            _vm._v("Steuersatz für Tarife")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.vat,
                                expression: "form.vat"
                              }
                            ],
                            attrs: {
                              type: "number",
                              name: "vat",
                              placeholder: "1.19",
                              step: ".01"
                            },
                            domProps: { value: _vm.form.vat },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "vat", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "license" } }, [
                            _vm._v("Ciab.io Lizenz-Schlüssel")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.license,
                                expression: "form.license"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "license",
                              placeholder: "Mein Ciab.io Lizenz-Schlüssel"
                            },
                            domProps: { value: _vm.form.license },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "license",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "mollieKey" } }, [
                            _vm._v("Mollie API-Schlüssel")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.mollieKey,
                                expression: "form.mollieKey"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "mollieKey",
                              placeholder: "Mein Mollie API-Schlüssel"
                            },
                            domProps: { value: _vm.form.mollieKey },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "mollieKey",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "twilioAccountSid" } }, [
                            _vm._v("Twilio Account Sid")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.twilioAccountSid,
                                expression: "form.twilioAccountSid"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "twilioAccountSid",
                              placeholder: "Twilio AccountSid"
                            },
                            domProps: { value: _vm.form.twilioAccountSid },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "twilioAccountSid",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", { attrs: { for: "twilioAuthToken" } }, [
                            _vm._v("Twilio Auth Token")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.twilioAuthToken,
                                expression: "form.twilioAuthToken"
                              }
                            ],
                            attrs: {
                              type: "password",
                              name: "twilioAuthToken",
                              placeholder: "Mein Mollie API-Schlüssel"
                            },
                            domProps: { value: _vm.form.twilioAuthToken },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "twilioAuthToken",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldset" }, [
                          _c("label", [_vm._v("Automatische Updates")]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _vm.form.autoUpdate
                              ? _c("span", [_vm._v("Aktiviert")])
                              : _c("span", [_vm._v("Deaktiviert")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.autoUpdate,
                                  expression: "form.autoUpdate"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.form.autoUpdate)
                                  ? _vm._i(_vm.form.autoUpdate, null) > -1
                                  : _vm.form.autoUpdate
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.form.autoUpdate,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.form,
                                          "autoUpdate",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.form,
                                          "autoUpdate",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.form, "autoUpdate", $$c)
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider" })
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tab", { attrs: { title: "Theme" } }, [
                _c("form", [
                  _c("div", { staticClass: "grid" }, [
                    _c("div", { staticClass: "fieldset col-12 col-sm-6" }, [
                      _c("label", { attrs: { for: "primary" } }, [
                        _vm._v("Primäre Farbe")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.primaryColor,
                            expression: "form.primaryColor"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "primary",
                          placeholder: "#000000"
                        },
                        domProps: { value: _vm.form.primaryColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "primaryColor",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.primaryColor,
                            expression: "form.primaryColor"
                          }
                        ],
                        attrs: { type: "color" },
                        domProps: { value: _vm.form.primaryColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "primaryColor",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-12 col-sm-6" }, [
                      _c("label", { attrs: { for: "secondary" } }, [
                        _vm._v("Sekundäre Farbe")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.secondaryColor,
                            expression: "form.secondaryColor"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "secondary",
                          placeholder: "#000000"
                        },
                        domProps: { value: _vm.form.secondaryColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "secondaryColor",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.secondaryColor,
                            expression: "form.secondaryColor"
                          }
                        ],
                        attrs: { type: "color" },
                        domProps: { value: _vm.form.secondaryColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "secondaryColor",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-12 col-sm-6" }, [
                      _c("label", { attrs: { for: "textColor" } }, [
                        _vm._v("Text-Farbe")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.textColor,
                            expression: "form.textColor"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "textColor",
                          placeholder: "#000000"
                        },
                        domProps: { value: _vm.form.textColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "textColor", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.textColor,
                            expression: "form.textColor"
                          }
                        ],
                        attrs: { type: "color" },
                        domProps: { value: _vm.form.textColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "textColor", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-12 col-sm-6" }, [
                      _c("label", { attrs: { for: "borderColor" } }, [
                        _vm._v("Rahmen-Farbe")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.borderColor,
                            expression: "form.borderColor"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "borderColor",
                          placeholder: "#000000"
                        },
                        domProps: { value: _vm.form.borderColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "borderColor",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.borderColor,
                            expression: "form.borderColor"
                          }
                        ],
                        attrs: { type: "color" },
                        domProps: { value: _vm.form.borderColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "borderColor",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset col-12 col-sm-6" }, [
                      _c("label", { attrs: { for: "bgColor" } }, [
                        _vm._v("Hintergrund-Farbe")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.bgColor,
                            expression: "form.bgColor"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "bgColor",
                          placeholder: "#000000"
                        },
                        domProps: { value: _vm.form.bgColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "bgColor", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.bgColor,
                            expression: "form.bgColor"
                          }
                        ],
                        attrs: { type: "color" },
                        domProps: { value: _vm.form.bgColor },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "bgColor", $event.target.value)
                          }
                        }
                      })
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tab", { attrs: { title: "E-Mails" } }, [
                _c("form", [
                  _c("div", { staticClass: "card mb" }, [
                    _c("h3", { staticClass: "mb" }, [
                      _vm._v("\n              S/MIME\n            ")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c(
                        "label",
                        {
                          staticClass: "upload cert",
                          class: [
                            { dragenter: _vm.dragging === "cert" },
                            { uploading: _vm.uploadingCert },
                            { exists: _vm.config.mailCert }
                          ],
                          attrs: { for: "cert" }
                        },
                        [
                          _c("input", {
                            ref: "certInput",
                            attrs: {
                              name: "cert",
                              type: "file",
                              accept: ".crt"
                            },
                            on: {
                              change: _vm.uploadCert,
                              drop: function($event) {
                                $event.preventDefault()
                                return _vm.uploadCert($event)
                              },
                              dragover: function($event) {
                                $event.preventDefault()
                              },
                              dragenter: function($event) {
                                $event.preventDefault()
                                _vm.dragging = "cert"
                              },
                              dragend: function($event) {
                                $event.preventDefault()
                                _vm.dragging = false
                              },
                              dragleave: function($event) {
                                $event.preventDefault()
                                _vm.dragging = false
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("transition", { attrs: { name: "fade" } }, [
                            _vm.uploadingCert
                              ? _c("span", { staticClass: "uploading" }, [
                                  _vm._v("Zertifikat wird hochgeladen...")
                                ])
                              : _vm.dragging === "cert"
                              ? _c("span", { staticClass: "hot" }, [
                                  _vm._v(
                                    "\n                    Drop it like it's hot!\n                  "
                                  )
                                ])
                              : _c(
                                  "span",
                                  { staticClass: "inner" },
                                  [
                                    _vm.config.mailCert
                                      ? [
                                          _c("span", [
                                            _vm._v("sMime Zertifikat vorhanden")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "change" },
                                            [_vm._v("Ändern")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "delete",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.deleteCert($event)
                                                }
                                              }
                                            },
                                            [_vm._v("Löschen")]
                                          )
                                        ]
                                      : [
                                          _c("span", [
                                            _vm._v(
                                              "Zertifikat auswählen oder hier ablegen"
                                            )
                                          ])
                                        ]
                                  ],
                                  2
                                )
                          ])
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c(
                        "label",
                        {
                          staticClass: "upload key",
                          class: [
                            { dragenter: _vm.dragging === "key" },
                            { uploading: _vm.uploadingKey },
                            { exists: _vm.config.mailKey }
                          ],
                          attrs: { for: "key" }
                        },
                        [
                          _c("input", {
                            ref: "keyInput",
                            attrs: {
                              name: "key",
                              type: "file",
                              accept: ".key"
                            },
                            on: {
                              change: _vm.uploadKey,
                              drop: function($event) {
                                $event.preventDefault()
                                return _vm.uploadKey($event)
                              },
                              dragover: function($event) {
                                $event.preventDefault()
                              },
                              dragenter: function($event) {
                                $event.preventDefault()
                                _vm.dragging = "key"
                              },
                              dragend: function($event) {
                                $event.preventDefault()
                                _vm.dragging = false
                              },
                              dragleave: function($event) {
                                $event.preventDefault()
                                _vm.dragging = false
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("transition", { attrs: { name: "fade" } }, [
                            _vm.uploadingKey
                              ? _c("span", { staticClass: "uploading" }, [
                                  _vm._v("Key wird hochgeladen...")
                                ])
                              : _vm.dragging === "key"
                              ? _c("span", { staticClass: "hot" }, [
                                  _vm._v(
                                    "\n                    Drop it like it's hot!\n                  "
                                  )
                                ])
                              : _c(
                                  "span",
                                  { staticClass: "inner" },
                                  [
                                    _vm.config.mailKey
                                      ? [
                                          _c("span", [
                                            _vm._v("sMime Key vorhanden")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "change" },
                                            [_vm._v("Ändern")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "delete",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.deleteKey($event)
                                                }
                                              }
                                            },
                                            [_vm._v("Löschen")]
                                          )
                                        ]
                                      : [
                                          _c("span", [
                                            _vm._v(
                                              "Key auswählen oder hier ablegen"
                                            )
                                          ])
                                        ]
                                  ],
                                  2
                                )
                          ])
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "smimePassword" } }, [
                        _vm._v("sMime-Passwort")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.smimePassword,
                            expression: "form.smimePassword"
                          }
                        ],
                        attrs: {
                          type: "password",
                          name: "smimePassword",
                          placeholder: "Password",
                          autocomplete: "new-password"
                        },
                        domProps: { value: _vm.form.smimePassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "smimePassword",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card" }, [
                    _c("h3", { staticClass: "mb" }, [
                      _vm._v("\n              Systemmails\n            ")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "welcomeMail" } }, [
                        _vm._v("Benutzerregistrierung")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.welcomeMail,
                            expression: "form.welcomeMail"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "welcomeMail",
                          placeholder: "Betreff"
                        },
                        domProps: { value: _vm.form.welcomeMail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "welcomeMail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("editor", {
                          attrs: {
                            "api-key":
                              "0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd",
                            init: {
                              menubar: false,
                              branding: false,
                              content_style: "body { font-size: 13px; }",
                              plugins: _vm.tinyMcePlugins,
                              toolbar: _vm.tinyMceToolbar
                            }
                          },
                          model: {
                            value: _vm.form.welcomeMailContent,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "welcomeMailContent", $$v)
                            },
                            expression: "form.welcomeMailContent"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c(
                        "label",
                        { attrs: { for: "welcomeMailCreatedUser" } },
                        [_vm._v("Benutzererstellung")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.welcomeMailCreatedUser,
                            expression: "form.welcomeMailCreatedUser"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "welcomeMailCreatedUser",
                          placeholder: "Willkommen..."
                        },
                        domProps: { value: _vm.form.welcomeMailCreatedUser },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "welcomeMailCreatedUser",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("editor", {
                          attrs: {
                            "api-key":
                              "0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd",
                            init: {
                              menubar: false,
                              branding: false,
                              plugins: _vm.tinyMcePlugins,
                              toolbar: _vm.tinyMceToolbar
                            }
                          },
                          model: {
                            value: _vm.form.welcomeMailCreatedUserContent,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "welcomeMailCreatedUserContent",
                                $$v
                              )
                            },
                            expression: "form.welcomeMailCreatedUserContent"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c(
                        "label",
                        { attrs: { for: "welcomeMailCreatedOrga" } },
                        [_vm._v("Organisationserstellung")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.welcomeMailCreatedOrga,
                            expression: "form.welcomeMailCreatedOrga"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "welcomeMailCreatedOrga",
                          placeholder: "Willkommen..."
                        },
                        domProps: { value: _vm.form.welcomeMailCreatedOrga },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "welcomeMailCreatedOrga",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("editor", {
                          attrs: {
                            "api-key":
                              "0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd",
                            init: {
                              menubar: false,
                              branding: false,
                              plugins: _vm.tinyMcePlugins,
                              toolbar: _vm.tinyMceToolbar
                            }
                          },
                          model: {
                            value: _vm.form.welcomeMailCreatedOrgaContent,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "welcomeMailCreatedOrgaContent",
                                $$v
                              )
                            },
                            expression: "form.welcomeMailCreatedOrgaContent"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "resetMail" } }, [
                        _vm._v("Passwort zurücksetzen")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.resetMail,
                            expression: "form.resetMail"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "resetMail",
                          placeholder: "Passwort zurücksetzen..."
                        },
                        domProps: { value: _vm.form.resetMail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "resetMail", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("editor", {
                          attrs: {
                            "api-key":
                              "0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd",
                            init: {
                              menubar: false,
                              branding: false,
                              plugins: _vm.tinyMcePlugins,
                              toolbar: _vm.tinyMceToolbar
                            }
                          },
                          model: {
                            value: _vm.form.resetMailContent,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "resetMailContent", $$v)
                            },
                            expression: "form.resetMailContent"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "resetSuccessMail" } }, [
                        _vm._v("Passwort erfolgreich geändert")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.resetSuccessMail,
                            expression: "form.resetSuccessMail"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "resetSuccessMail",
                          placeholder: "Passwort geändert..."
                        },
                        domProps: { value: _vm.form.resetSuccessMail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "resetSuccessMail",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("editor", {
                          attrs: {
                            "api-key":
                              "0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd",
                            init: {
                              menubar: false,
                              branding: false,
                              plugins: _vm.tinyMcePlugins,
                              toolbar: _vm.tinyMceToolbar
                            }
                          },
                          model: {
                            value: _vm.form.resetSuccessMailContent,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "resetSuccessMailContent", $$v)
                            },
                            expression: "form.resetSuccessMailContent"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tab", { attrs: { title: "Partner-Gate" } }, [
                _c("form", [
                  _c("div", { staticClass: "fieldset" }, [
                    _c("label", { attrs: { for: "partnerGateUser" } }, [
                      _vm._v("Benutzer")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.partnerGateUser,
                          expression: "form.partnerGateUser"
                        }
                      ],
                      attrs: {
                        type: "text",
                        name: "partnerGateUser",
                        placeholder: "my_user/soap",
                        autocomplete: "off"
                      },
                      domProps: { value: _vm.form.partnerGateUser },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "partnerGateUser",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "fieldset" }, [
                    _c("label", { attrs: { for: "partnerGatePassword" } }, [
                      _vm._v("Passwort")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.partnerGatePassword,
                          expression: "form.partnerGatePassword"
                        }
                      ],
                      attrs: {
                        type: "password",
                        name: "partnerGatePassword",
                        placeholder: "Password",
                        autocomplete: "new-password"
                      },
                      domProps: { value: _vm.form.partnerGatePassword },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "partnerGatePassword",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "fieldset surcharge" }, [
                    _c("label", { attrs: { for: "domainSurcharge" } }, [
                      _vm._v("Aufschlag Domains")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.domainSurcharge,
                          expression: "form.domainSurcharge"
                        }
                      ],
                      attrs: {
                        type: "number",
                        name: "domainSurcharge",
                        placeholder: "1",
                        step: ".01"
                      },
                      domProps: { value: _vm.form.domainSurcharge },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "domainSurcharge",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.surchargePercent))])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tab",
                { attrs: { title: "Roadmap" } },
                [
                  _c("h2", [_vm._v("Github Issues")]),
                  _vm._v(" "),
                  _vm._l(_vm.githubIssues, function(issue) {
                    return _c("ul", { key: issue.id }, [
                      _c(
                        "li",
                        [_c("GitHubIssueItem", { attrs: { issue: issue } })],
                        1
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("tab", { attrs: { title: "Login Screen" } }, [
                _c("div", { staticClass: "fieldsetgroup" }, [
                  _c("div", { staticClass: "fieldset" }, [
                    _c(
                      "label",
                      {
                        staticClass: "upload card",
                        class: [
                          { dragenter: _vm.dragging === "logo" },
                          { uploading: _vm.uploadingLoginIcon }
                        ],
                        attrs: { for: "loginicon" }
                      },
                      [
                        _vm.config.loginIcon
                          ? _c("img", {
                              key: _vm.config.loginIcon,
                              attrs: {
                                src:
                                  _vm.baseUrl +
                                  "/images/" +
                                  _vm.config.loginIcon,
                                alt: "icon"
                              }
                            })
                          : _c("div", { staticClass: "no-logo" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tNoch kein Icon hochgeladen\n\t\t\t\t\t\t\t"
                              )
                            ]),
                        _vm._v(" "),
                        _c("input", {
                          ref: "input",
                          attrs: {
                            name: "loginicon",
                            type: "file",
                            accept:
                              "image/png, image/gif, image/jpeg, image/svg+xml"
                          },
                          on: {
                            change: _vm.uploadLoginIcon,
                            drop: function($event) {
                              $event.preventDefault()
                              return _vm.uploadLoginIcon($event)
                            },
                            dragover: function($event) {
                              $event.preventDefault()
                            },
                            dragenter: function($event) {
                              $event.preventDefault()
                              _vm.dragging = "loginicon"
                            },
                            dragend: function($event) {
                              $event.preventDefault()
                              _vm.dragging = false
                            },
                            dragleave: function($event) {
                              $event.preventDefault()
                              _vm.dragging = false
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.uploadingLoginIcon
                            ? _c("span", { staticClass: "uploading" }, [
                                _vm._v("Login-Icon wird hochgeladen...")
                              ])
                            : _vm.dragging === "loginicon"
                            ? _c("span", { staticClass: "hot" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\tDrop it like it's hot!\n\t\t\t\t\t\t\t\t"
                                )
                              ])
                            : _c("span", [
                                _c("b", [_vm._v("Login-Icon auswählen")]),
                                _c("br"),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\toder per Drag'n'Drop hier ablegen\n\t\t\t\t\t\t\t\t"
                                )
                              ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.config.loginIcon
                      ? _c(
                          "a",
                          {
                            staticClass: "delete-logo mt",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.deleteLoginIcon($event)
                              }
                            }
                          },
                          [_vm._v("Login-Icon löschen")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "fieldset" }, [
                    _c(
                      "label",
                      {
                        staticClass: "upload card",
                        class: [
                          { dragenter: _vm.dragging === "loginbglef" },
                          { uploading: _vm.uploadingLoginBgLeft }
                        ],
                        attrs: { for: "loginbgleft" }
                      },
                      [
                        _vm.config.loginBgLeft
                          ? _c("img", {
                              key: _vm.config.loginBgLeft,
                              attrs: {
                                src:
                                  _vm.baseUrl +
                                  "/images/" +
                                  _vm.config.loginBgLeft,
                                alt: "icon"
                              }
                            })
                          : _c("div", { staticClass: "no-logo" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tNoch keinen Hintergrund hochgeladen\n\t\t\t\t\t\t\t"
                              )
                            ]),
                        _vm._v(" "),
                        _c("input", {
                          ref: "input",
                          attrs: {
                            name: "loginbgleft",
                            type: "file",
                            accept:
                              "image/png, image/gif, image/jpeg, image/svg+xml"
                          },
                          on: {
                            change: _vm.uploadLoginBgLeft,
                            drop: function($event) {
                              $event.preventDefault()
                              return _vm.uploadLoginBgLeft($event)
                            },
                            dragover: function($event) {
                              $event.preventDefault()
                            },
                            dragenter: function($event) {
                              $event.preventDefault()
                              _vm.dragging = "loginbgleft"
                            },
                            dragend: function($event) {
                              $event.preventDefault()
                              _vm.dragging = false
                            },
                            dragleave: function($event) {
                              $event.preventDefault()
                              _vm.dragging = false
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.uploadingLoginBgLeft
                            ? _c("span", { staticClass: "uploading" }, [
                                _vm._v("Hintergrund wird hochgeladen...")
                              ])
                            : _vm.dragging === "loginbgleft"
                            ? _c("span", { staticClass: "hot" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\tDrop it like it's hot!\n\t\t\t\t\t\t\t\t"
                                )
                              ])
                            : _c("span", [
                                _c("b", [_vm._v("Hintergrund auswählen")]),
                                _c("br"),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\toder per Drag'n'Drop hier ablegen\n\t\t\t\t\t\t\t\t"
                                )
                              ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.config.loginBgLeft
                      ? _c(
                          "a",
                          {
                            staticClass: "delete-logo mt",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.deleteLoginBgLeft($event)
                              }
                            }
                          },
                          [_vm._v("Hintergrund löschen")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "fieldset" }, [
                    _c(
                      "label",
                      {
                        staticClass: "upload card",
                        class: [
                          { dragenter: _vm.dragging === "loginbglef" },
                          { uploading: _vm.uploadingLoginBgRight }
                        ],
                        attrs: { for: "loginbgright" }
                      },
                      [
                        _vm.config.loginBgRight
                          ? _c("img", {
                              key: _vm.config.loginBgRight,
                              attrs: {
                                src:
                                  _vm.baseUrl +
                                  "/images/" +
                                  _vm.config.loginBgRight,
                                alt: "icon"
                              }
                            })
                          : _c("div", { staticClass: "no-logo" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tNoch keinen Hintergrund hochgeladen\n\t\t\t\t\t\t\t"
                              )
                            ]),
                        _vm._v(" "),
                        _c("input", {
                          ref: "input",
                          attrs: {
                            name: "loginbgright",
                            type: "file",
                            accept:
                              "image/png, image/gif, image/jpeg, image/svg+xml"
                          },
                          on: {
                            change: _vm.uploadLoginBgRight,
                            drop: function($event) {
                              $event.preventDefault()
                              return _vm.uploadLoginBgRight($event)
                            },
                            dragover: function($event) {
                              $event.preventDefault()
                            },
                            dragenter: function($event) {
                              $event.preventDefault()
                              _vm.dragging = "loginbgright"
                            },
                            dragend: function($event) {
                              $event.preventDefault()
                              _vm.dragging = false
                            },
                            dragleave: function($event) {
                              $event.preventDefault()
                              _vm.dragging = false
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.uploadingLoginBgRight
                            ? _c("span", { staticClass: "uploading" }, [
                                _vm._v("Hintergrund wird hochgeladen...")
                              ])
                            : _vm.dragging === "loginbgright"
                            ? _c("span", { staticClass: "hot" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\tDrop it like it's hot!\n\t\t\t\t\t\t\t\t"
                                )
                              ])
                            : _c("span", [
                                _c("b", [_vm._v("Hintergrund auswählen")]),
                                _c("br"),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\toder per Drag'n'Drop hier ablegen\n\t\t\t\t\t\t\t\t"
                                )
                              ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.config.loginBgRight
                      ? _c(
                          "a",
                          {
                            staticClass: "delete-logo mt",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.deleteLoginBgRight($event)
                              }
                            }
                          },
                          [_vm._v("Hintergrund löschen")]
                        )
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "fieldset" }, [
                  _c("label", { attrs: { for: "logintext" } }, [
                    _vm._v("Text unter Icon")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.loginText,
                        expression: "form.loginText"
                      }
                    ],
                    attrs: { type: "text", name: "logintext" },
                    domProps: { value: _vm.form.loginText },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "loginText", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "fieldset" }, [
                  _c(
                    "label",
                    {
                      staticClass: "upload card",
                      class: [
                        { dragenter: _vm.dragging === "bottomicon" },
                        { uploading: _vm.uploadingBottomIcon }
                      ],
                      attrs: { for: "bottomicon" }
                    },
                    [
                      _vm.config.bottomIcon
                        ? _c("img", {
                            key: _vm.config.bottomIcon,
                            attrs: {
                              src:
                                _vm.baseUrl +
                                "/images/" +
                                _vm.config.bottomIcon,
                              alt: "icon"
                            }
                          })
                        : _c("div", { staticClass: "no-logo" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tNoch kein Icon hochgeladen\n\t\t\t\t\t\t"
                            )
                          ]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "input",
                        attrs: {
                          name: "bottomicon",
                          type: "file",
                          accept:
                            "image/png, image/gif, image/jpeg, image/svg+xml"
                        },
                        on: {
                          change: _vm.uploadBottomIcon,
                          drop: function($event) {
                            $event.preventDefault()
                            return _vm.uploadBottomIcon($event)
                          },
                          dragover: function($event) {
                            $event.preventDefault()
                          },
                          dragenter: function($event) {
                            $event.preventDefault()
                            _vm.dragging = "bottomicon"
                          },
                          dragend: function($event) {
                            $event.preventDefault()
                            _vm.dragging = false
                          },
                          dragleave: function($event) {
                            $event.preventDefault()
                            _vm.dragging = false
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.uploadingBottomIcon
                          ? _c("span", { staticClass: "uploading" }, [
                              _vm._v("Icon wird hochgeladen...")
                            ])
                          : _vm.dragging === "bottomicon"
                          ? _c("span", { staticClass: "hot" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tDrop it like it's hot!\n\t\t\t\t\t\t\t"
                              )
                            ])
                          : _c("span", [
                              _c("b", [_vm._v("Icon auswählen")]),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\toder per Drag'n'Drop hier ablegen\n\t\t\t\t\t\t\t"
                              )
                            ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.config.bottomIcon
                    ? _c(
                        "a",
                        {
                          staticClass: "delete-logo mt",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.deleteBottomIcon($event)
                            }
                          }
                        },
                        [_vm._v("Icon löschen")]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "fieldset" }, [
                  _c("label", { attrs: { for: "bgcolor" } }, [
                    _vm._v("Hintergrundfarbe")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.loginBgColor,
                        expression: "form.loginBgColor"
                      }
                    ],
                    attrs: {
                      type: "text",
                      name: "bgcolor",
                      placeholder: "Farbcode"
                    },
                    domProps: { value: _vm.form.loginBgColor },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "loginBgColor", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SubmitBtn", {
        staticClass: "mt",
        attrs: {
          label: "Einstellungen speichern",
          submitting: _vm.submitting,
          inline: ""
        },
        nativeOn: {
          click: function($event) {
            $event.preventDefault()
            return _vm.updateConfig($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }