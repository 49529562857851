var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "products container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Tarife\n      "),
          _vm.products.length
            ? _c("span", [_vm._v(_vm._s(_vm.products.length))])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openModal($event)
                }
              }
            },
            [
              _c("span", { staticClass: "plus" }, [_vm._v("+")]),
              _vm._v(" Hinzufügen\n      ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.loading
            ? _c("div", { staticClass: "skeleton" }, [
                _vm._v("\n      Tarife werden geladen\n    ")
              ])
            : _c("ProductList", {
                attrs: { products: _vm.products },
                on: { fetch: _vm.fetch }
              })
        ],
        1
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("h2", { staticClass: "mb" }, [
                  _vm._v("\n        Tarif hinzufügen\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addProduct($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "grid" }, [
                      _c("div", { staticClass: "fieldset col-12" }, [
                        _c("label", { attrs: { for: "name" } }, [
                          _vm._v("Name")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "name",
                            placeholder: "Name des Tarifs",
                            required: ""
                          },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "name", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-6" }, [
                        _c("label", { attrs: { for: "mailboxes" } }, [
                          _vm._v("Mailkonten")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.mailboxes,
                              expression: "form.mailboxes"
                            }
                          ],
                          attrs: {
                            type: "number",
                            name: "mailboxes",
                            placeholder: "Anzahl Mailkonten"
                          },
                          domProps: { value: _vm.form.mailboxes },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "mailboxes",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-6" }, [
                        _c("label", { attrs: { for: "databases" } }, [
                          _vm._v("Datenbanken")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.databases,
                              expression: "form.databases"
                            }
                          ],
                          attrs: {
                            type: "number",
                            name: "databases",
                            placeholder: "Anzahl Datenbanken"
                          },
                          domProps: { value: _vm.form.databases },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "databases",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-6" }, [
                        _c("label", { attrs: { for: "cronjobs" } }, [
                          _vm._v("Cronjobs")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.cronjobs,
                              expression: "form.cronjobs"
                            }
                          ],
                          attrs: {
                            type: "number",
                            name: "cronjobs",
                            placeholder: "Anzahl Cronjobs"
                          },
                          domProps: { value: _vm.form.cronjobs },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "cronjobs",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-6" }, [
                        _c("label", { attrs: { for: "backups" } }, [
                          _vm._v("Backups")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.backups,
                              expression: "form.backups"
                            }
                          ],
                          attrs: {
                            type: "number",
                            name: "backups",
                            placeholder: "Anzahl Backups"
                          },
                          domProps: { value: _vm.form.backups },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "backups", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-6" }, [
                        _c("label", { attrs: { for: "space" } }, [
                          _vm._v("Diskspace")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.space,
                              expression: "form.space"
                            }
                          ],
                          attrs: {
                            type: "number",
                            name: "space",
                            placeholder: "Diskspace in GB"
                          },
                          domProps: { value: _vm.form.space },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "space", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-6" }, [
                        _c("label", { attrs: { for: "price" } }, [
                          _vm._v("Preis")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.price,
                              expression: "form.price"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "price",
                            placeholder: "Monatlicher Netto Preis"
                          },
                          domProps: { value: _vm.form.price },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "price", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-12" }, [
                        _c("label", [_vm._v("SSH")]),
                        _vm._v(" "),
                        _c("label", { staticClass: "switch" }, [
                          _c("span", [_vm._v("Aktiviert")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.ssh,
                                expression: "form.ssh"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.form.ssh)
                                ? _vm._i(_vm.form.ssh, null) > -1
                                : _vm.form.ssh
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.form.ssh,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "ssh",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "ssh",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "ssh", $$c)
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "slider" })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-12" }, [
                        _c("label", { attrs: { for: "ns1" } }, [
                          _vm._v("Nameserver 1")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.ns1,
                              expression: "form.ns1"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "ns1",
                            placeholder: "ns1.domain.tld"
                          },
                          domProps: { value: _vm.form.ns1 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "ns1", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fieldset col-12" }, [
                        _c("label", { attrs: { for: "ns2" } }, [
                          _vm._v("Nameserver 2")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.ns2,
                              expression: "form.ns2"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "ns2",
                            placeholder: "ns2.domain.tld"
                          },
                          domProps: { value: _vm.form.ns2 },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "ns2", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      staticClass: "mt",
                      attrs: {
                        label: "Tarif anlegen",
                        submitting: _vm.submitting
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }