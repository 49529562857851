<template>
  <div class="settings container">
    <transition name="fade">
      <div
        v-if="compiling"
        class="compiling"
      >
        <h2 class="mb">
          Farbeinstellungen werden übernommen
        </h2>
        <p>Applikation wird rekompiliert</p>
      </div>
    </transition>
    <h1 class="mb">
      Einstellungen
    </h1>
    <tabs
      v-if="mounted && config"
      :key="tabsKey"
    >
      <tab title="Allgemein">
        <form>
          <div class="general grid justify-space-between">
            <div class="logo col-12 col-sm-4">
              <label
                for="logo"
                class="upload card"
                :class="[{'dragenter' : dragging==='logo'}, {'uploading' : uploadingLogo}]"
              >
                <img
                  v-if="config.logoName"
                  :key="config.logoName"
                  :src="`${baseUrl}/images/${config.logoName}`"
                  alt="logo"
                >
                <div
                  v-else
                  class="no-logo"
                >
                  Kein Logo
                </div>
                <input
                  ref="input"
                  name="logo"
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/svg+xml"
                  @change="uploadLogo"
                  @drop.prevent="uploadLogo"
                  @dragover.prevent
                  @dragenter.prevent="dragging='logo'"
                  @dragend.prevent="dragging=false"
                  @dragleave.prevent="dragging=false"
                >
                <transition name="fade">
                  <span
                    v-if="uploadingLogo"
                    class="uploading"
                  >Logo wird hochgeladen...</span>
                  <span
                    v-else-if="dragging==='logo'"
                    class="hot"
                  >
                    Drop it like it's hot!
                  </span>
                  <span v-else>
                    <b>Logo auswählen</b><br>
                    oder per Drag'n'Drop hier ablegen
                  </span>
                </transition>
              </label>
              <a
                v-if="config.logoName"
                class="delete-logo mt"
                href="#"
                @click.prevent="deleteLogo"
              >Logo löschen</a>
            </div>
            <div class="col-12 col-sm-7">
              <div class="fieldset">
                <label for="company">Firmenname</label>
                <input
                  v-model="form.company"
                  type="text"
                  name="company"
                  placeholder="Mein Firmenname"
                >
              </div>
              <div class="fieldset">
                <label for="name">E-Mail</label>
                <input
                  v-model="form.email"
                  type="email"
                  name="email"
                  placeholder="meine@email.tld"
                >
              </div>
              <div class="fieldset">
                <label for="phone">Telefon</label>
                <input
                  v-model="form.phone"
                  type="text"
                  name="phone"
                  placeholder="+49123456789"
                >
              </div>
              <div class="fieldset">
                <label for="diskWarning">Server-Auslastung Warnungsgrenze</label>
                <input
                  v-model="form.diskWarning"
                  type="number"
                  name="diskWarning"
                  placeholder="70"
                >
                <legend class="mt-sm">
                  Ab wieviel Prozent Auslastung der Server (RAM/CPU/Disk) eine E-Mail-Benachrichtigung verschickt werden soll.
                </legend>
              </div>
              <div class="fieldset">
                <label for="vat">Steuersatz für Tarife</label>
                <input
                  v-model="form.vat"
                  type="number"
                  name="vat"
                  placeholder="1.19"
                  step=".01"
                >
              </div>
              <div class="fieldset">
                <label for="license">Ciab.io Lizenz-Schlüssel</label>
                <input
                  v-model="form.license"
                  type="text"
                  name="license"
                  placeholder="Mein Ciab.io Lizenz-Schlüssel"
                >
              </div>
              <div class="fieldset">
                <label for="mollieKey">Mollie API-Schlüssel</label>
                <input
                  v-model="form.mollieKey"
                  type="text"
                  name="mollieKey"
                  placeholder="Mein Mollie API-Schlüssel"
                >
              </div>
              <div class="fieldset">
                <label for="twilioAccountSid">Twilio Account Sid</label>
                <input
                  v-model="form.twilioAccountSid"
                  type="text"
                  name="twilioAccountSid"
                  placeholder="Twilio AccountSid"
                >
              </div>
              <div class="fieldset">
                <label for="twilioAuthToken">Twilio Auth Token</label>
                <input
                  v-model="form.twilioAuthToken"
                  type="password"
                  name="twilioAuthToken"
                  placeholder="Mein Mollie API-Schlüssel"
                >
              </div>
              <div class="fieldset">
                <label>Automatische Updates</label>
                <label class="switch">
                  <span v-if="form.autoUpdate">Aktiviert</span>
                  <span v-else>Deaktiviert</span>
                  <input
                    v-model="form.autoUpdate"
                    type="checkbox"
                  >
                  <span class="slider" />
                </label>
              </div>
            </div>
          </div>
        </form>
      </tab>
      <tab title="Theme">
        <form>
          <div class="grid">
            <div class="fieldset col-12 col-sm-6">
              <label for="primary">Primäre Farbe</label>
              <input
                v-model="form.primaryColor"
                type="text"
                name="primary"
                placeholder="#000000"
              >
              <input
                v-model="form.primaryColor"
                type="color"
              >
            </div>
            <div class="fieldset col-12 col-sm-6">
              <label for="secondary">Sekundäre Farbe</label>
              <input
                v-model="form.secondaryColor"
                type="text"
                name="secondary"
                placeholder="#000000"
              >
              <input
                v-model="form.secondaryColor"
                type="color"
              >
            </div>
            <div class="fieldset col-12 col-sm-6">
              <label for="textColor">Text-Farbe</label>
              <input
                v-model="form.textColor"
                type="text"
                name="textColor"
                placeholder="#000000"
              >
              <input
                v-model="form.textColor"
                type="color"
              >
            </div>
            <div class="fieldset col-12 col-sm-6">
              <label for="borderColor">Rahmen-Farbe</label>
              <input
                v-model="form.borderColor"
                type="text"
                name="borderColor"
                placeholder="#000000"
              >
              <input
                v-model="form.borderColor"
                type="color"
              >
            </div>
            <div class="fieldset col-12 col-sm-6">
              <label for="bgColor">Hintergrund-Farbe</label>
              <input
                v-model="form.bgColor"
                type="text"
                name="bgColor"
                placeholder="#000000"
              >
              <input
                v-model="form.bgColor"
                type="color"
              >
            </div>
          </div>
        </form>
      </tab>
      <tab title="E-Mails">
        <form>
          <div class="card mb">
            <h3 class="mb">
              S/MIME
            </h3>
            <div class="fieldset">
              <label
                for="cert"
                class="upload cert"
                :class="[{'dragenter' : dragging==='cert'}, {'uploading' : uploadingCert}, {'exists' : config.mailCert}]"
              >
                <input
                  ref="certInput"
                  name="cert"
                  type="file"
                  accept=".crt"
                  @change="uploadCert"
                  @drop.prevent="uploadCert"
                  @dragover.prevent
                  @dragenter.prevent="dragging='cert'"
                  @dragend.prevent="dragging=false"
                  @dragleave.prevent="dragging=false"
                >
                <transition name="fade">
                  <span
                    v-if="uploadingCert"
                    class="uploading"
                  >Zertifikat wird hochgeladen...</span>
                  <span
                    v-else-if="dragging==='cert'"
                    class="hot"
                  >
                    Drop it like it's hot!
                  </span>
                  <span
                    v-else
                    class="inner"
                  >
                    <template v-if="config.mailCert">
                      <span>sMime Zertifikat vorhanden</span>
                      <span class="change">Ändern</span>
                      <a
                        href="#"
                        class="delete"
                        @click.prevent="deleteCert"
                      >Löschen</a>
                    </template>
                    <template v-else>
                      <span>Zertifikat auswählen oder hier ablegen</span>
                    </template>
                  </span>
                </transition>
              </label>
            </div>
            <div class="fieldset">
              <label
                for="key"
                class="upload key"
                :class="[{'dragenter' : dragging==='key'}, {'uploading' : uploadingKey}, {'exists' : config.mailKey}]"
              >
                <input
                  ref="keyInput"
                  name="key"
                  type="file"
                  accept=".key"
                  @change="uploadKey"
                  @drop.prevent="uploadKey"
                  @dragover.prevent
                  @dragenter.prevent="dragging='key'"
                  @dragend.prevent="dragging=false"
                  @dragleave.prevent="dragging=false"
                >
                <transition name="fade">
                  <span
                    v-if="uploadingKey"
                    class="uploading"
                  >Key wird hochgeladen...</span>
                  <span
                    v-else-if="dragging==='key'"
                    class="hot"
                  >
                    Drop it like it's hot!
                  </span>
                  <span
                    v-else
                    class="inner"
                  >
                    <template v-if="config.mailKey">
                      <span>sMime Key vorhanden</span>
                      <span class="change">Ändern</span>
                      <a
                        href="#"
                        class="delete"
                        @click.prevent="deleteKey"
                      >Löschen</a>
                    </template>
                    <template v-else>
                      <span>Key auswählen oder hier ablegen</span>
                    </template>
                  </span>
                </transition>
              </label>
            </div>
            <div class="fieldset">
              <label for="smimePassword">sMime-Passwort</label>
              <input
                v-model="form.smimePassword"
                type="password"
                name="smimePassword"
                placeholder="Password"
                autocomplete="new-password"
              >
            </div>
          </div>
          <div class="card">
            <h3 class="mb">
              Systemmails
            </h3>
            <div class="fieldset">
              <label for="welcomeMail">Benutzerregistrierung</label>
              <input
                v-model="form.welcomeMail"
                type="text"
                name="welcomeMail"
                placeholder="Betreff"
              >
            </div>
            <div class="fieldset">
              <editor
                v-model="form.welcomeMailContent"
                api-key="0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd"
                :init="{
                  menubar: false,
                  branding: false,
                  content_style: 'body { font-size: 13px; }',
                  plugins: tinyMcePlugins,
                  toolbar: tinyMceToolbar
                }"
              />
            </div>
            <div class="fieldset">
              <label for="welcomeMailCreatedUser">Benutzererstellung</label>
              <input
                v-model="form.welcomeMailCreatedUser"
                type="text"
                name="welcomeMailCreatedUser"
                placeholder="Willkommen..."
              >
            </div>
            <div class="fieldset">
              <editor
                v-model="form.welcomeMailCreatedUserContent"
                api-key="0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd"
                :init="{
                  menubar: false,
                  branding: false,
                  plugins: tinyMcePlugins,
                  toolbar: tinyMceToolbar
                }"
              />
            </div>
            <div class="fieldset">
              <label for="welcomeMailCreatedOrga">Organisationserstellung</label>
              <input
                v-model="form.welcomeMailCreatedOrga"
                type="text"
                name="welcomeMailCreatedOrga"
                placeholder="Willkommen..."
              >
            </div>
            <div class="fieldset">
              <editor
                v-model="form.welcomeMailCreatedOrgaContent"
                api-key="0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd"
                :init="{
                  menubar: false,
                  branding: false,
                  plugins: tinyMcePlugins,
                  toolbar: tinyMceToolbar
                }"
              />
            </div>
            <div class="fieldset">
              <label for="resetMail">Passwort zurücksetzen</label>
              <input
                v-model="form.resetMail"
                type="text"
                name="resetMail"
                placeholder="Passwort zurücksetzen..."
              >
            </div>
            <div class="fieldset">
              <editor
                v-model="form.resetMailContent"
                api-key="0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd"
                :init="{
                  menubar: false,
                  branding: false,
                  plugins: tinyMcePlugins,
                  toolbar: tinyMceToolbar
                }"
              />
            </div>
            <div class="fieldset">
              <label for="resetSuccessMail">Passwort erfolgreich geändert</label>
              <input
                v-model="form.resetSuccessMail"
                type="text"
                name="resetSuccessMail"
                placeholder="Passwort geändert..."
              >
            </div>
            <div class="fieldset">
              <editor
                v-model="form.resetSuccessMailContent"
                api-key="0c2yygbpcb12y9wf0dzuct8w0wkvfsduy37xepkga44izafd"
                :init="{
                  menubar: false,
                  branding: false,
                  plugins: tinyMcePlugins,
                  toolbar: tinyMceToolbar
                }"
              />
            </div>
          </div>
        </form>
      </tab>
      <tab title="Partner-Gate">
        <form>
          <div class="fieldset">
            <label for="partnerGateUser">Benutzer</label>
            <input
              v-model="form.partnerGateUser"
              type="text"
              name="partnerGateUser"
              placeholder="my_user/soap"
              autocomplete="off"
            >
          </div>
          <div class="fieldset">
            <label for="partnerGatePassword">Passwort</label>
            <input
              v-model="form.partnerGatePassword"
              type="password"
              name="partnerGatePassword"
              placeholder="Password"
              autocomplete="new-password"
            >
          </div>
          <div class="fieldset surcharge">
            <label for="domainSurcharge">Aufschlag Domains</label>
            <input
              v-model="form.domainSurcharge"
              type="number"
              name="domainSurcharge"
              placeholder="1"
              step=".01"
            >
            <span>{{ surchargePercent }}</span>
          </div>
        </form>
      </tab>
      <tab title="Roadmap">
        <h2>Github Issues</h2>
        <ul
          v-for="issue in githubIssues"
          :key="issue.id"
        >
          <li><GitHubIssueItem :issue="issue" /></li>
        </ul>
      </tab>
			<tab title="Login Screen">
        <div class="fieldsetgroup">
        	<div class="fieldset">
	        	<label
							for="loginicon"
							class="upload card"
							:class="[{'dragenter' : dragging==='logo'}, {'uploading' : uploadingLoginIcon}]"
						>
							<img
								v-if="config.loginIcon"
								:key="config.loginIcon"
								:src="`${baseUrl}/images/${config.loginIcon}`"
								alt="icon"
							>
							<div
								v-else
								class="no-logo"
							>
								Noch kein Icon hochgeladen
							</div>
							<input
								ref="input"
								name="loginicon"
								type="file"
								accept="image/png, image/gif, image/jpeg, image/svg+xml"
								@change="uploadLoginIcon"
								@drop.prevent="uploadLoginIcon"
								@dragover.prevent
								@dragenter.prevent="dragging='loginicon'"
								@dragend.prevent="dragging=false"
								@dragleave.prevent="dragging=false"
							>
							<transition name="fade">
								<span
									v-if="uploadingLoginIcon"
									class="uploading"
								>Login-Icon wird hochgeladen...</span>
								<span
									v-else-if="dragging==='loginicon'"
									class="hot"
								>
									Drop it like it's hot!
								</span>
								<span v-else>
									<b>Login-Icon auswählen</b><br>
									oder per Drag'n'Drop hier ablegen
								</span>
							</transition>
						</label>
						<a
							v-if="config.loginIcon"
							class="delete-logo mt"
							href="#"
							@click.prevent="deleteLoginIcon"
						>Login-Icon löschen</a>
	        </div>
					<div class="fieldset">
						<label
							for="loginbgleft"
							class="upload card"
							:class="[{'dragenter' : dragging==='loginbglef'}, {'uploading' : uploadingLoginBgLeft}]"
						>
							<img
								v-if="config.loginBgLeft"
								:key="config.loginBgLeft"
								:src="`${baseUrl}/images/${config.loginBgLeft}`"
								alt="icon"
							>
							<div
								v-else
								class="no-logo"
							>
								Noch keinen Hintergrund hochgeladen
							</div>
							<input
								ref="input"
								name="loginbgleft"
								type="file"
								accept="image/png, image/gif, image/jpeg, image/svg+xml"
								@change="uploadLoginBgLeft"
								@drop.prevent="uploadLoginBgLeft"
								@dragover.prevent
								@dragenter.prevent="dragging='loginbgleft'"
								@dragend.prevent="dragging=false"
								@dragleave.prevent="dragging=false"
							>
							<transition name="fade">
								<span
									v-if="uploadingLoginBgLeft"
									class="uploading"
								>Hintergrund wird hochgeladen...</span>
								<span
									v-else-if="dragging==='loginbgleft'"
									class="hot"
								>
									Drop it like it's hot!
								</span>
								<span v-else>
									<b>Hintergrund auswählen</b><br>
									oder per Drag'n'Drop hier ablegen
								</span>
							</transition>
						</label>
						<a
							v-if="config.loginBgLeft"
							class="delete-logo mt"
							href="#"
							@click.prevent="deleteLoginBgLeft"
						>Hintergrund löschen</a>
					</div>
            <div class="fieldset">
						<label
							for="loginbgright"
							class="upload card"
							:class="[{'dragenter' : dragging==='loginbglef'}, {'uploading' : uploadingLoginBgRight}]"
						>
							<img
								v-if="config.loginBgRight"
								:key="config.loginBgRight"
								:src="`${baseUrl}/images/${config.loginBgRight}`"
								alt="icon"
							>
							<div
								v-else
								class="no-logo"
							>
								Noch keinen Hintergrund hochgeladen
							</div>
							<input
								ref="input"
								name="loginbgright"
								type="file"
								accept="image/png, image/gif, image/jpeg, image/svg+xml"
								@change="uploadLoginBgRight"
								@drop.prevent="uploadLoginBgRight"
								@dragover.prevent
								@dragenter.prevent="dragging='loginbgright'"
								@dragend.prevent="dragging=false"
								@dragleave.prevent="dragging=false"
							>
							<transition name="fade">
								<span
									v-if="uploadingLoginBgRight"
									class="uploading"
								>Hintergrund wird hochgeladen...</span>
								<span
									v-else-if="dragging==='loginbgright'"
									class="hot"
								>
									Drop it like it's hot!
								</span>
								<span v-else>
									<b>Hintergrund auswählen</b><br>
									oder per Drag'n'Drop hier ablegen
								</span>
							</transition>
						</label>
						<a
							v-if="config.loginBgRight"
							class="delete-logo mt"
							href="#"
							@click.prevent="deleteLoginBgRight"
						>Hintergrund löschen</a>
					</div>
        </div>
				<div class="fieldset">
					<label for="logintext">Text unter Icon</label>
					<input
						v-model="form.loginText"
						type="text"
						name="logintext"
					>
				</div>
				<div class="fieldset">
					<label
						for="bottomicon"
						class="upload card"
						:class="[{'dragenter' : dragging==='bottomicon'}, {'uploading' : uploadingBottomIcon}]"
					>
						<img
							v-if="config.bottomIcon"
							:key="config.bottomIcon"
							:src="`${baseUrl}/images/${config.bottomIcon}`"
							alt="icon"
						>
						<div
							v-else
							class="no-logo"
						>
							Noch kein Icon hochgeladen
						</div>
						<input
							ref="input"
							name="bottomicon"
							type="file"
							accept="image/png, image/gif, image/jpeg, image/svg+xml"
							@change="uploadBottomIcon"
							@drop.prevent="uploadBottomIcon"
							@dragover.prevent
							@dragenter.prevent="dragging='bottomicon'"
							@dragend.prevent="dragging=false"
							@dragleave.prevent="dragging=false"
						>
						<transition name="fade">
							<span
								v-if="uploadingBottomIcon"
								class="uploading"
							>Icon wird hochgeladen...</span>
							<span
								v-else-if="dragging==='bottomicon'"
								class="hot"
							>
								Drop it like it's hot!
							</span>
							<span v-else>
								<b>Icon auswählen</b><br>
								oder per Drag'n'Drop hier ablegen
							</span>
						</transition>
					</label>
					<a
						v-if="config.bottomIcon"
						class="delete-logo mt"
						href="#"
						@click.prevent="deleteBottomIcon"
					>Icon löschen</a>
				</div>
				<div class="fieldset">
					<label for="bgcolor">Hintergrundfarbe</label>
					<input
						v-model="form.loginBgColor"
						type="text"
						name="bgcolor"
						placeholder="Farbcode"
					>
				</div>
      </tab>
    </tabs>
    <SubmitBtn
      class="mt"
      label="Einstellungen speichern"
      :submitting="submitting"
      inline
      @click.prevent.native="updateConfig"
    />
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'Settings',

  components: {
    SubmitBtn: () => import('../components/SubmitBtn'),
    Tab: () => import('../components/Tab'),
    Tabs: () => import('../components/Tabs'),
    GitHubIssueItem: () => import('../components/gitIssues/GitHubIssueItem'),
    'editor': Editor

  },

  data() {
    return {
      baseUrl: process.env.BASE_URL,
      githubIssues: [],
      githubRepos: [],
      form: {
        primaryColor: '#765691',
        secondaryColor: '#ffce00',
        borderColor: '#e6dde9',
        bgColor: '#f8f7f8',
        textColor: '#16051a',
        company: '',
        email: '',
        phone: '',
        diskWarning: 70,
        welcomeMail: 'Willkommen...',
        welcomeMailContent: '',
        resetMail: 'Passwort zurücksetzen',
        resetMailContent: '',
        resetSuccessMail: 'Passwort geändert',
        resetSuccessMailContent: '',
        welcomeMailCreatedUser: 'Willkommen...',
        welcomeMailCreatedUserContent: '',
        welcomeMailCreatedOrga: 'Willkommen...',
        welcomeMailCreatedOrgaContent: '',
        vat: 1.19,
        partnerGateUser: '',
        partnerGatePassword: '',
        domainSurcharge: 1,
        smimePassword: '',
        license: '',
        mollieKey: '',
        autoUpdate: false,
        twilioAuthToken: '',
        twilioAccountSid: '',
        loginText: '',
				loginBgColor: '#765691'
      },

      mounted: false,
      currentcolors: {},
      tabsKey: 0,
      formChanged: false,
      submitting: false,
      compiling: false,

      dragging: '',
      uploadingLogo: false,
      uploadingCert: false,
      uploadingKey: false,
      uploadingLoginIcon: false,
			uploadingBottomIcon: false,
      uploadingLoginBgLeft: false,
      uploadingLoginBgRight: false,
      logo: null,
      cert: null,
      key: null,
      loginIcon: null,
			bottomIcon: null,
      loginBgLeft: null,
      loginBgRight: null,

      tinyMcePlugins: ['advlist autolink lists link charmap preview anchor', 'searchreplace visualblocks code fullscreen', 'table paste code'],
      tinyMceToolbar: 'undo redo | formatselect | bold italic | fullscreen | link | bullist numlist | removeformat | code'
    }
  },

  computed: {
    ...mapGetters([
      'config'
    ]),

    surchargePercent () {
      return isNaN(parseFloat(this.form.domainSurcharge)) ? '' : 100 * parseFloat(this.form.domainSurcharge) - 100 + '%'
    }
  },

  watch: {
    'config': {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal && !oldVal) {
          this.form = newVal
          this.currentcolors = {
            primaryColor: newVal.primaryColor,
            secondaryColor: newVal.secondaryColor,
            borderColor: newVal.borderColor,
            bgColor: newVal.bgColor,
            textColor: newVal.textColor
          }
        }
      }
    },

    // form: {
    //   immediate: false,
    //   handler(newVal, oldVal) {
    //     if(newVal !== oldVal) {
    //       console.log('form changed')
    //     }
    //   }
    // }
  },

  async created() {
    //await this.loadGithubIssues()
  },

  mounted () {
    this.mounted = true
  },

  methods: {
    ...mapMutations({
      setConfig: 'setConfig'
    }),

    async updateConfig () {
      this.submitting = true
      this.$axios.post('/api/config', this.form).then(async (res) => {
        if (this.currentcolors.primaryColor !== res.data.primaryColor || this.currentcolors.secondaryColor !== res.data.secondaryColor || this.currentcolors.borderColor !== res.data.borderColor || this.currentcolors.bgColor !== res.data.bgColor || this.currentcolors.textColor !== res.data.textColor) {
          this.compiling = true
          await this.$axios.post('/api/config/compile', {
            primaryColor: res.data.primaryColor,
            secondaryColor: res.data.secondaryColor,
            borderColor: res.data.borderColor,
            bgColor: res.data.bgColor,
            textColor: res.data.textColor
          })
          this.compiling = false
          this.$router.go(0)
        }
        this.submitting = false
        this.setConfig(res.data)
        this.$notify.success('Einstellungen aktualisiert')
      }).catch((error) => {
        console.log(error)
        this.submitting = false
        this.$notify.error('Ein Fehler ist aufgetreten')
      })
    },

    //async loadGithubIssues(){
    //  try{
    //    axios.defaults.withCredentials = false
    //    delete axios.defaults.headers.common["Fingerprint"];
    //    var response =  await axios.get('https://api.github.com/repos/Dennis-Acker-Consulting/CIABV2/issues',{
    //      headers: {
    //        'content-type' : 'application/json',
    //        'Authorization' : 'token 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'
    //      }
    //    })
    //    this.githubIssues = response.data
    //  }catch(error){
    //    console.error(error)
    //  }
    //},

    async uploadLogo(e) {
      this.dragging = ''
      this.logo = e.target.files[0] || e.dataTransfer.files[0]
      const formData = new FormData()
      formData.append('files', this.logo)
      this.uploadingLogo = true
      this.$refs.input.value = null // .value nur >=IE11

      this.$axios.post('/api/config/logo', formData).then(res => {
        this.uploadingLogo = false
        this.setConfig(res.data)
        this.$notify.success('Logo hochgeladen')
      }).catch(err => {
        console.log(err)
        this.uploadingLogo = false
        this.$notify.error('Ein Fehler ist aufgetreten')
      })
    },

    async uploadCert(e) {
      this.dragging = ''
      this.cert = e.target.files[0] || e.dataTransfer.files[0]
      const formData = new FormData()
      formData.append('files', this.cert)
      this.uploadingCert = true
      this.$refs.certInput.value = null // .value nur >=IE11

      this.$axios.post('/api/config/cert', formData).then(res => {
        this.uploadingCert = false
        this.setConfig(res.data)
        this.$notify.success('Zertifikat hochgeladen')
      }).catch(err => {
        console.log(err)
        this.uploadingCert = false
        this.$notify.error('Ein Fehler ist aufgetreten')
      })
    },

    async uploadKey(e) {
      this.dragging = ''
      this.cert = e.target.files[0] || e.dataTransfer.files[0]
      const formData = new FormData()
      formData.append('files', this.cert)
      this.uploadingKey = true
      this.$refs.keyInput.value = null // .value nur >=IE11

      this.$axios.post('/api/config/key', formData).then(res => {
        this.uploadingKey = false
        this.setConfig(res.data)
        this.$notify.success('Key hochgeladen')
      }).catch(err => {
        console.log(err)
        this.uploadingKey = false
        this.$notify.error('Ein Fehler ist aufgetreten')
      })
    },

    async uploadLoginIcon(e) {
      this.dragging = ''
      this.loginIcon = e.target.files[0] || e.dataTransfer.files[0]
      const formData = new FormData()
      formData.append('files', this.loginIcon)
      this.uploadingLoginIcon = true
      this.$refs.input.value = null // .value nur >=IE11

      this.$axios.post('/api/config/loginIcon', formData).then(res => {
        this.uploadingLoginIcon = false
        this.setConfig(res.data)
        this.$notify.success('Icon hochgeladen')
      }).catch(err => {
        console.log(err)
        this.uploadingLoginIcon = false
        this.$notify.error('Ein Fehler ist aufgetreten')
      })
    },

		async uploadBottomIcon(e) {
      this.dragging = ''
      this.bottomIcon = e.target.files[0] || e.dataTransfer.files[0]
      const formData = new FormData()
      formData.append('files', this.bottomIcon)
      this.uploadingBottomIcon = true
      this.$refs.input.value = null // .value nur >=IE11

      this.$axios.post('/api/config/bottomIcon', formData).then(res => {
        this.uploadingBottomIcon = false
        this.setConfig(res.data)
        this.$notify.success('Icon hochgeladen')
      }).catch(err => {
        console.log(err)
        this.uploadingBottomIcon = false
        this.$notify.error('Ein Fehler ist aufgetreten')
      })
    },

    async uploadLoginBgLeft(e) {
      this.dragging = ''
      this.loginBgLeft = e.target.files[0] || e.dataTransfer.files[0]
      const formData = new FormData()
      formData.append('files', this.loginBgLeft)
      this.uploadingLoginBgLeft = true
      this.$refs.input.value = null // .value nur >=IE11

      this.$axios.post('/api/config/loginBgLeft', formData).then(res => {
        this.uploadingLoginBgLeft = false
        this.setConfig(res.data)
        this.$notify.success('Hintergrund hochgeladen')
      }).catch(err => {
        console.log(err)
        this.uploadingLoginBgLeft = false
        this.$notify.error('Ein Fehler ist aufgetreten')
      })
    },

    async uploadLoginBgRight(e) {
      this.dragging = ''
      this.loginBgRight = e.target.files[0] || e.dataTransfer.files[0]
      const formData = new FormData()
      formData.append('files', this.loginBgRight)
      this.uploadingLoginBgRight = true
      this.$refs.input.value = null // .value nur >=IE11

      this.$axios.post('/api/config/loginBgRight', formData).then(res => {
        this.uploadingLoginBgRight = false
        this.setConfig(res.data)
        this.$notify.success('Hintergrund hochgeladen')
      }).catch(err => {
        console.log(err)
        this.uploadingLoginBgRight = false
        this.$notify.error('Ein Fehler ist aufgetreten')
      })
    },

    deleteCert () {
      this.$axios.delete('/api/config/cert').then((res) => {
        this.$notify.success('Zertifikat gelöscht')
        this.setConfig(res.data)
      }).catch((err) => {
        console.log(err.response)
      })
    },

    deleteKey () {
      this.$axios.delete('/api/config/key').then((res) => {
        this.$notify.success('Key gelöscht')
        this.setConfig(res.data)
      }).catch((err) => {
        console.log(err.response)
      })
    },

    deleteLogo () {
      this.$axios.delete('/api/config/logo').then((res) => {
        this.$notify.success('Logo gelöscht')
        this.setConfig(res.data)
      }).catch((err) => {
        console.log(err.response)
      })
    },

    deleteLoginIcon () {
      this.$axios.delete('/api/config/loginIcon').then((res) => {
        this.$notify.success('Icon gelöscht')
        this.setConfig(res.data)
      }).catch((err) => {
        console.log(err.response)
      })
    },

		deleteBottomIcon () {
      this.$axios.delete('/api/config/bottomIcon').then((res) => {
        this.$notify.success('Icon gelöscht')
        this.setConfig(res.data)
      }).catch((err) => {
        console.log(err.response)
      })
    },

    deleteLoginBgLeft () {
      this.$axios.delete('/api/config/loginBgLeft').then((res) => {
        this.$notify.success('Hintergrund gelöscht')
        this.setConfig(res.data)
      }).catch((err) => {
        console.log(err.response)
      })
      },

        deleteLoginBgRight () {
      this.$axios.delete('/api/config/loginBgRight').then((res) => {
        this.$notify.success('Hintergrund gelöscht')
        this.setConfig(res.data)
      }).catch((err) => {
        console.log(err.response)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.general .logo {
	@include sm() {
		order: 1;
	}
}

.compiling {
  display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.75);
  z-index: 9999;

  * {
    color: $color-bg;
  }
}

.delete-logo {
	margin-top: $spacer-sm;
	display: block;
	text-align: center;
	font-weight: $medium;
	font-size: $fontsize-sm;
	color: $color-error;
}

.fieldset {
  input[type="text"] {
    padding-right: 50px;
  }

  input[type="color"] {
    position: absolute;
    right: $spacer;
    bottom: 4px;
    padding: 0;
    border: 0;
    background: $color-bg;
    width: 40px;
  }

  &.surcharge {
    > span {
      position: absolute;
      line-height: 1;
      right: 0;
      bottom: 10px;
      color: $color-gray;
    }
  }
}

.upload {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: darken($color-primary, 50%);
    transition: all .3s;
    position: relative;
		border-radius: 4px;

    &.cert,
    &.key {
      min-height: 0;
      padding: $spacer-sm;
      text-align: left;
      align-items: flex-start;
      border: 0;
      background: $color-error-light;
      margin-top: $spacer-sm;

      &.exists {
        background: $color-success-light;
      }

      > span {
        margin-top: 0;

        &.inner {
          display: flex;
          width: 100%;

          span.change {
            margin-left: auto;
            margin-right: $spacer-sm;
          }

          .delete {
            z-index: 1;
            color: $color-error;
          }
        }
      }
    }

    > span {
      margin-top: $spacer;
      line-height: 1.4;
    }

    .no-logo {
      background: $color-border;
			color: $color-gray;
      width: 100%;
			height: 100px;
			text-align: center;
			display: flex;
			text-align: center;
			justify-content: center;
			align-items: center;
    }

    input[type="file"] {
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input:focus + label {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }

    &:hover {
      border-color: $color-primary;
    }

    &.dragenter {
      border-color: $color-primary;
    }

    &.uploading {
      border-color: $color-success;
      pointer-events: none;
    }
  }
</style>