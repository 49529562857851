var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-box container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.loading
            ? _c("div", { staticClass: "skeleton container" }, [
                _vm._v("\n      Tarife werden geladen\n    ")
              ])
            : _c("ProductList", { attrs: { products: _vm.products } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-head mb-lg" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("\n      Tarif wählen\n    ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }