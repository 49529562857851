<template>
  <div class="cronjobs container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Cronjobs
        <span v-if="cronjobs.length && box">{{ sortedCronjobs.length }}/{{ box.product.cronjobs }}</span>
      </h1>
      <!-- <div class="search">
				<input
					v-model="searchQuery"
					class="search"
					type="text"
					placeholder="Jobs filtern"
				>
				<a
					v-if="searchQuery"
					href="#"
					class="icon clear"
					@click.prevent="clearFilter"
				><span /><span /></a>
			</div> -->
      <div
        v-if="accountType === 'user' || (accountType === 'organisation' && isCustomerAdmin) || isAdmin"
        class="actions"
      >
        <button
          class="btn"
          @click.prevent="newCronjob"
        >
          <span class="plus">+</span> Erstellen
        </button>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <div
        v-if="loading && !cronjobs.length"
        key="loading"
        class="skeleton info"
      >
        Cronjobs werden geladen...
      </div>
      <div
        v-else-if="cronjobs.length"
        key="table"
        class="cronjobs-table table"
      >
        <!-- Head -->
        <div
          key="head"
          class="row head"
          :class="currentSortDir"
        >
          <div
            class="cell"
            @click.prevent="sort('name')"
          >
            Jobbezeichnung
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>
          <div
            class="cell"
          >
            Ausführung
          </div>
          <div
            class="cell"
            @click.prevent="sort('active')"
          >
            Status
            <span class="icon sort"><svg viewBox="0 0 20 10"><path d="M1,9 L10,1 L19,9" /></svg></span>
          </div>
        </div>
        <!-- Body -->
        <Cronjob
          v-for="cronjob in sortedCronjobs"
          :key="cronjob.id"
          :cronjob="cronjob"
          @fetchCronjobs="fetchCronjobs"
          @edit="editCronjob"
        />
        <div
          v-if="cronjobs.length && sortedCronjobs < 1"
          key="no-results"
          class="row no-results"
        >
          <div class="cell">
            Keine Cronjobs gefunden
          </div>
        </div>
      </div>
      <div
        v-else
        key="noresults"
        class="info"
      >
        <template v-if="isAdmin || isCustomerAdmin">
          Es wurden noch keine Cronjobs für diese Box angelegt. Klicke auf "Hinzufügen", um einen ersten Cronjob zu erstellen.
        </template>
        <template v-else>
          Es wurden noch keine Cronjobs für diese Box angelegt.
        </template>
      </div>
    </transition>
		
    <Modal ref="modal">
      <template #content>
        <h2
          v-if="!currentCronjob"
          class="mb"
        >
          Cronjob erstellen
        </h2>
        <h2
          v-else
          class="mb"
        >
          Cronjob bearbeiten
        </h2>
        <form @submit.prevent="addCronjob">
          <div class="fieldset">
            <label>Jobtyp</label>
            <v-select
              v-model="form.type"
              :options="['webhook','Serverseitiger Aufruf']"
              :clearable="false"
            />
            <legend
              v-if="form.type === 'webhook'"
              class="mt-sm"
            >
              Lege eine URL fest, die automatisiert aufgerufen wird.
            </legend>
          </div>
          <div class="fieldset mt-lg">
            <label class="switch">
              <span v-if="form.active">Cronjob aktiv</span>
              <span v-else>Cronjob inaktiv</span>
              <input
                v-model="form.active"
                type="checkbox"
              >
              <span class="slider" />
            </label>
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.name.$error}"
          >
            <label for="name">Bezeichnung</label>
            <input
              v-model="$v.form.name.$model"
              type="text"
              name="name"
              placeholder="Jobname"
            >
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.cmd.$error}"
          >
            <label
              v-if="form.type === 'webhook'"
              for="cmd"
            >URL</label>
            <label
              v-else
              for="cmd"
            >Kommando(s)</label>
            <input
              v-model.trim="$v.form.cmd.$model"
              type="text"
              name="cmd"
              :placeholder="form.type === 'webhook' ? 'domain.ltd/script' : 'Kommando'"
            >
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.interval.$error}"
          >
            <label>Intervall</label>
            <v-select
              v-model.trim="$v.form.interval.$model"
              :options="expressions"
              label="label"
              :placeholder="'Intervall wählen...'"
              :searchable="false"
              :clearable="false"
              append-to-body
              :calculate-position="withPopper"
            />
          </div>
          <div
            v-if="form.interval && form.interval.label === 'Benutzerdefiniert'"
            class="fieldset"
            :class="{'fieldset-error' : $v.form.customExpression.$error}"
          >
            <label for="customExpression">Cron-Expression</label>
            <input
              v-model.trim="$v.form.customExpression.$model"
              type="text"
              name="customExpression"
              autocomplete="false"
              @input="parse()"
            >
            <legend class="parsed-exp mt-sm">
              {{ parsedExpression }}
            </legend>
          </div>
          <SubmitBtn
            v-if="!currentCronjob"
            label="Cronjob erstellen"
            :submitting="submitting"
            :disabled="$v.form.$invalid"
          />
          <SubmitBtn
            v-else
            label="Cronjob aktualisieren"
            :submitting="submitting"
            :disabled="$v.form.$invalid"
          />
          <SubmitBtn
            v-if="currentCronjob"
            class="mt-sm"
            label="Cronjob löschen"
            :submitting="deleting"
            red
            @click.native.prevent="deleteCronjob"
          />
        </form>
      </template>
    </Modal>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import _ from 'lodash'
import { required, requiredIf, helpers } from 'vuelidate/lib/validators'
import cronstrue from 'cronstrue/i18n'
import { createPopper } from '@popperjs/core'

const validCron = helpers.regex('cronexp', /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/)

export default {
  name: 'Cronjobs',

  components: {
    Modal: () => import('../components/Modal'),
    Dialog: () => import('../components/Dialog'),
    SubmitBtn: () => import('../components/SubmitBtn'),
    Cronjob: () => import('../components/cronjobs/Cronjob')
  },

  data() {
    return {
      loading: true,
      submitting: false,
      deleting: false,

      currentCronjob: null,
      cronjobs: [],      
      form: {
        name: '',
        type: 'webhook',
        active: true,
        interval: '',
        cmd: '',
        customExpression: '*/5 * * * *'
      },

      parsedExpression: '',
      expressions: [
        {
          label: 'Alle 5 Minuten',
          expression: '*/5 * * * *'
        },
        {
          label: 'Alle 15 Minuten',
          expression: '*/15 * * * *'
        },
        {
          label: 'Alle 30 Minuten',
          expression: '*/30 * * * *'
        },
        {
          label: 'Jede Stunde',
          expression: '0 * * * *'
        },
        {
          label: 'Bei Minute 0, alle 2 Stunden',
          expression: '0 */2 * * *'
        },
        {
          label: 'Bei Minute 0, alle 4 Stunden',
          expression: '0 */4 * * *'
        },
        {
          label: 'Um 02:00, nur jeden Sonntag',
          expression: '0 2 * * 7'
        },
        {
          label: 'Benutzerdefiniert'
        }
      ],

      currentSort: 'name',
      currentSortDir: 'asc',
      searchQuery: '',

      vSelectPlacement: 'bottom'
    }
  },

  validations: {
    form: {
      name: {required},
      cmd: {required},
      interval: {required},
      customExpression: {
        required: requiredIf(function() {
          return this.form.interval.label === 'Benutzerdefiniert'
        }),
        validCron
      }
    }
  },

  computed: {
    ...mapGetters([
      'box',
      'accountType',
      'id',
      'isAdmin',
      'isCustomerAdmin'
    ]),

    sortedCronjobs() {
      var filteredCronjobs = this.cronjobs.filter(job => {
        if(job) {
          return job.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        } else {
          return null
        }
      })
      return _.orderBy(filteredCronjobs, this.currentSort, this.currentSortDir) 
    }
  },

  watch: {
    'box': {
      immediate: true,
      handler() {
        this.fetchCronjobs()
      }
    },

    'form.interval'(newVal, oldVal) {
      if(oldVal.label !== newVal.label && oldVal.label === 'Benutzerdefiniert') {
        this.form.customExpression = '*/5 * * * *'
        this.parse()
      }
    }
  },

  mounted() {
    this.parsedExpression = cronstrue.toString(this.form.customExpression, {locale: "de"})
  },

  methods: {
    async fetchCronjobs() {
      if(this.id && this.box) {
        const cronjobs = await this.$axios.get(`/api/${this.accountType}/${this.id}/box/${this.box.id}/cronjobs`)
        this.cronjobs = cronjobs.data
        this.loading = false
      }
    },

    editCronjob(job) {
      this.currentCronjob = job
      this.form.type = job.type
      this.form.active = job.active
      this.form.name = job.name
      this.form.cmd = job.command
      this.form.interval = {
        'label': cronstrue.toString(job.executionTime, {locale: "de"}),
        'expression': job.executionTime
      }
      this.showModal()
    },

    newCronjob() {
      if(this.currentCronjob) {
        this.currentCronjob = null
        this.clearForm()
      }
      this.showModal()
    },

    showModal() {
      if(this.$refs && this.$refs.modal) {
        this.$refs.modal.open()
      }
    },

    closeModal() {
      this.$refs.modal.close()
    },

    clearForm() {
      this.form.type = 'webhook'
      this.form.active = false
      this.form.name = ''
      this.form.cmd = ''
      this.form.interval = ''
      this.form.customExpression = '*/5 * * * *'
      this.submitting = false
      this.$v.$reset()
    },

    async addCronjob() {
      this.$v.form.$touch()
      let formData = {
        name: this.form.name,
        type: this.form.type,
        cmd: this.form.cmd,
        interval: this.form.interval.label === 'Benutzerdefiniert' ? this.form.customExpression : this.form.interval.expression,
        active: this.form.active
      }

      if(!this.$v.form.name.required || !this.$v.form.interval.required || !this.$v.form.customExpression.required) {
        this.$toast.error('Benötigte Felder ausfüllen')
      } else if(!this.$v.form.customExpression.validCron) {
        this.$toast.error('Expression ungültig')
      } else {
        this.submitting = true

        if(this.currentCronjob) {
          await this.$axios.put(`/api/${this.accountType}/${this.id}/box/${this.box.id}/cronjob/${this.currentCronjob.id}`, formData).then(async () => {
            await this.fetchCronjobs()
            this.$notify.success('Cronjob aktualisiert')
            this.submitting = false
          }).catch(err => {
            console.log(err)
            this.submitting = false
          })
        } else {
          this.$axios.post(`/api/${this.accountType}/${this.id}/box/${this.box.id}/cronjob`, formData).then(async () => {
            await this.fetchCronjobs()
            this.$notify.success('Cronjob erstellt')
            this.closeModal()
            this.submitting = false
          }).catch(error => {
            console.log(error.response)
            this.submitting = false
          })
        }
      }
    },

    async deleteCronjob() {
      const confirmation = await this.$refs.dialog.open(`
        <h3 class="mb">Cronjob löschen</h3>
        <p>Soll der Cronjob <strong>${this.currentCronjob.name}</strong> wirklich gelöscht werden?</p>
      `)
      if (confirmation === 'ok') {
        this.deleting = true
        this.$axios.delete(`/api/${this.accountType}/${this.id}/box/${this.box.id}/cronjob/${this.currentCronjob.id}`).then(() => {
          this.$notify.success('Cronjob gelöscht')
          this.closeModal()
          this.fetchCronjobs()
          this.deleting = false
        }).catch(error => {
          console.log(error.response)
          this.deleting = false
        })
      }
    },

    sort(s) {
      if(s === this.currentSort) this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      this.currentSort = s
    },

    clearFilter() {
      this.searchQuery = ''
      this.currentSort = 'name'
      this.currentSortDir = 'asc'
    },

    parse() {
      try {
        this.parsedExpression = cronstrue.toString(this.form.customExpression, {locale: "de"})
      } catch(err) {
        this.parsedExpression = err
      }
    },

    withPopper(dropdownList, component, {width}) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.vSelectPlacement,
        modifiers: [
          { name: 'offset', options: { offset: [0, -2] } },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({state}) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }]
      })
      return () => popper.destroy()
    },
  }
}
</script>

<style lang="scss" scoped>
.cronjobs-table {
	.row {
		grid-template-columns: 2fr 3fr 80px;
	}
}
</style>