var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "domains container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Domains\n      "),
          _vm.sortedDomains && _vm.sortedDomains.length
            ? _c("span", [_vm._v(_vm._s(_vm.sortedDomains.length))])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.accountType === "user" ||
        (_vm.accountType === "organisation" && _vm.isCustomerAdmin) ||
        _vm.isAdmin
          ? _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.openModal($event)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "plus" }, [_vm._v("+")]),
                  _vm._v(" Hinzufügen\n      ")
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search mb-lg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchQuery,
              expression: "searchQuery"
            }
          ],
          attrs: { type: "text", placeholder: "Domains filtern" },
          domProps: { value: _vm.searchQuery },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchQuery = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.searchQuery
          ? _c(
              "a",
              {
                staticClass: "icon clear",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clearFilter($event)
                  }
                }
              },
              [_c("span"), _c("span")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [
          _vm.loading && !_vm.domains.length
            ? _c("div", { key: "loading", staticClass: "skeleton info" }, [
                _vm._v("\n      Domains werden geladen...\n    ")
              ])
            : _vm.domains.length
            ? _c(
                "div",
                { key: "table", staticClass: "domains-table table" },
                [
                  _c(
                    "div",
                    {
                      key: "head",
                      staticClass: "row head",
                      class: _vm.currentSortDir
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.sort("name")
                            }
                          }
                        },
                        [
                          _vm._v("\n          Domain\n          "),
                          _c("span", { staticClass: "icon sort" }, [
                            _c("svg", { attrs: { viewBox: "0 0 20 10" } }, [
                              _c("path", { attrs: { d: "M1,9 L10,1 L19,9" } })
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.sortedDomains, function(domain) {
                    return _c("Domain", {
                      key: domain.id,
                      attrs: { domain: domain },
                      on: {
                        edit: _vm.edit,
                        fetchDomains: _vm.fetchDomains,
                        checkMapped: _vm.checkMapped
                      }
                    })
                  }),
                  _vm._v(" "),
                  _vm.domains.length && _vm.sortedDomains < 1
                    ? _c(
                        "div",
                        { key: "no-results", staticClass: "row no-results" },
                        [
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              "\n          Keine Domains gefunden\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _c(
                "div",
                { key: "noresults", staticClass: "info" },
                [
                  _vm.isAdmin || _vm.isCustomerAdmin
                    ? [
                        _vm._v(
                          '\n        Es wurden noch keine Domains für diese Box angelegt. Klicke auf "Hinzufügen", um eine erste Domain anzulegen.\n      '
                        )
                      ]
                    : [
                        _vm._v(
                          "\n        Es wurden noch keine Domains für diese Box angelegt.\n      "
                        )
                      ]
                ],
                2
              )
        ]
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "add",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("h2", { staticClass: "mb" }, [
                  _vm._v("\n        Domain anlegen\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        _vm.form.vHost === "vHost" ||
                        _vm.form.domainAvailable ||
                        _vm.form.authCode !== ""
                          ? _vm.addDomain()
                          : _vm.checkDomain()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "fieldset domain" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.selectedDomain,
                            expression: "form.selectedDomain"
                          }
                        ],
                        attrs: {
                          type: "text",
                          name: "domain",
                          placeholder: "domain.tld"
                        },
                        domProps: { value: _vm.form.selectedDomain },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "selectedDomain",
                                $event.target.value
                              )
                            },
                            _vm.onChange
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset checkbox" }, [
                      _c("label", [
                        _vm._v(
                          "\n            Domainregistrierung / Providerwechsel\n            "
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.vHost,
                              expression: "form.vHost"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            value: "register",
                            name: "register"
                          },
                          domProps: {
                            checked: _vm._q(_vm.form.vHost, "register")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "vHost", "register")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset checkbox" }, [
                      _c("label", [
                        _vm._v(
                          "\n            Virtual Host Eintrag (keine Registrierung)\n            "
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.vHost,
                              expression: "form.vHost"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            value: "vHost",
                            name: "vhost"
                          },
                          domProps: {
                            checked: _vm._q(_vm.form.vHost, "vHost")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "vHost", "vHost")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" })
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.form.vHost !== "vHost" &&
                    _vm.form.checked &&
                    _vm.form.domainAvailable
                      ? _c("div", { staticClass: "info mt" }, [
                          _c("h4", { staticClass: "mb-sm" }, [
                            _vm._v("\n            Domain verfügbar\n          ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "price mb-sm" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.form.setup.toLocaleString("de-DE", {
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 2
                                  })
                                ) +
                                " einmalige Einrichtungsgebühr"
                            ),
                            _c("br"),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.form.price.toLocaleString("de-DE", {
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 2
                                  })
                                ) +
                                " monatl. zzgl. 19% MwSt.\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n            Mir ist bekannt, dass die Mindestvertragslaufzeit für Domains 12 Monate beträgt. Ebenso habe ich die AGB zur Kenntnis genommen und akzeptiere diese in vollem Umfang."
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              "\n            Ich bestätige, dass ich der Inhaber der Domain bin, oder vom Inhaber zum Transfer der Domain beauftragt wurde und bevollmächtigte hiermit " +
                                _vm._s(_vm.company) +
                                " alle erforderlichen Schritte für den Domainumzug zu unternehmen.\n          "
                            )
                          ])
                        ])
                      : _vm.form.vHost !== "vHost" &&
                        _vm.form.checked &&
                        !_vm.form.domainAvailable
                      ? _c("div", { staticClass: "mt" }, [
                          _c("div", { staticClass: "fieldset" }, [
                            _c("label", { attrs: { for: "authcode" } }, [
                              _vm._v("Authcode/AuthInfo")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.authCode,
                                  expression: "form.authCode"
                                }
                              ],
                              attrs: {
                                type: "text",
                                name: "authcode",
                                placeholder: "Authcode"
                              },
                              domProps: { value: _vm.form.authCode },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "authCode",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      staticClass: "mt",
                      attrs: {
                        label:
                          !_vm.form.domainAvailable && !_vm.form.authCode
                            ? _vm.form.vHost === "vHost"
                              ? "vHost anlegen"
                              : "Domain prüfen"
                            : !_vm.form.domainAvailable &&
                              _vm.form.authCode &&
                              _vm.form.vHost !== "vHost"
                            ? "Domain transferieren"
                            : _vm.form.vHost !== "vHost"
                            ? "Domain registrieren"
                            : "vHost anlegen",
                        submitting: _vm.adding,
                        disabled: !_vm.form.selectedDomain
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Modal", {
        ref: "edit",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("h2", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.currentDomain.name) + "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "mb-lg ip" }, [
                  _vm._v(
                    "\n        IP: " + _vm._s(_vm.box.server.ip) + "\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "mb-xl",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.updateDomain($event)
                      }
                    }
                  },
                  [
                    _c("h3", { staticClass: "mb" }, [
                      _vm._v("\n          Domain-Einstellungen\n        ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fieldset" },
                      [
                        _c("label", [_vm._v("Neuer Box zuweisen")]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            options: _vm.boxes,
                            placeholder: "Box wählen",
                            clearable: true,
                            label: "name",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.editForm.newBox,
                            callback: function($$v) {
                              _vm.$set(_vm.editForm, "newBox", $$v)
                            },
                            expression: "editForm.newBox"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", { attrs: { for: "name" } }, [_vm._v("TTL")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editForm.ttl,
                            expression: "editForm.ttl"
                          }
                        ],
                        attrs: {
                          type: "number",
                          name: "ttl",
                          placeholder: "600"
                        },
                        domProps: { value: _vm.editForm.ttl },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.editForm, "ttl", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldset" }, [
                      _c("label", [_vm._v("Auto-Renew")]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _vm.editForm.autoRenew
                          ? _c("span", [_vm._v("Aktiviert")])
                          : _c("span", [_vm._v("Deaktiviert")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.editForm.autoRenew,
                              expression: "editForm.autoRenew"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.editForm.autoRenew)
                              ? _vm._i(_vm.editForm.autoRenew, null) > -1
                              : _vm.editForm.autoRenew
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.editForm.autoRenew,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.editForm,
                                      "autoRenew",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.editForm,
                                      "autoRenew",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.editForm, "autoRenew", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      attrs: {
                        label: "Änderungen speichern",
                        submitting: _vm.updating
                      }
                    }),
                    _vm._v(" "),
                    _c("SubmitBtn", {
                      staticClass: "mt-sm",
                      attrs: {
                        label: "Domain löschen",
                        submitting: _vm.deleting,
                        red: ""
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.deleteDomain($event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("Subdomains", {
                  attrs: {
                    subdomains: _vm.subdomains,
                    domainid: _vm.currentDomain.id,
                    userid: _vm.id,
                    boxid: _vm.box.id
                  },
                  on: { fetch: _vm.fetchDomains }
                }),
                _vm._v(" "),
                _c("Dns", {
                  attrs: {
                    records: _vm.currentDomain.dNSRecords,
                    userid: _vm.id,
                    boxid: _vm.box.id,
                    domainid: _vm.currentDomain.id
                  },
                  on: { fetch: _vm.fetchDomains }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Dialog", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }