<template>
  <div
    v-if="box"
    class="box-settings container"
  >
    <div class="page-head mb">
      <h1 class="title">
        {{ box.name }}
      </h1>
      <div
        v-if="accountType === 'user' || (accountType === 'organisation' && isCustomerAdmin) || isAdmin"
        class="actions"
      >
        <button
          class="btn"
          @click.prevent="openModal"
        >
          Einstellungen
        </button>
      </div>
    </div>
    <ul class="product-info mb-lg">
      <li><span>IP</span> {{ box.server.ip }}</li>
      <li><span>NS1</span> {{ box.product.ns1 }}</li>
      <li><span>NS2</span> {{ box.product.ns2 }}</li>
      <li><span>Status</span> {{ box.isLocked ? 'Gesperrt' : 'Aktiv' }}</li>
    </ul>
    <Quota :box="box" />
    <UpgradeBox
      v-if="accountType === 'user' || (accountType === 'organisation' && isCustomerAdmin) || isAdmin"
      :box="box"
      :userid="id"
      :accounttype="accountType"
    />

    <Modal ref="modal">
      <template #content>
        <h2 class="mb">
          Box-Einstellungen
        </h2>
        <form>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.name.$error}"
          >
            <label for="name">Boxname</label>
            <input
              v-model="$v.form.name.$model"
              type="text"
              name="name"
              placeholder="Bezeichnung der Box"
              autocomplete="off"
            >
          </div>
          <div class="fieldset">
            <label>Standard-Box</label>
            <label class="switch">
              <span v-if="form.isDefault">Favorisiert</span>
              <span v-else>Nicht favorisiert</span>
              <input
                v-model="form.isDefault"
                type="checkbox"
              >
              <span class="slider" />
            </label>
            <!-- <legend class="mt-sm">
              Wenn favorisiert, ist diese Box immer 
            </legend> -->
          </div>
          <div
            class="fieldset"
            :class="{'fieldset-error' : $v.form.password.$error}"
          >
            <label for="password">Box-Passwort (FTP/SSH)</label>
            <input
              v-model="$v.form.password.$model"
              type="password"
              autocomplete="new-password"
              name="password"
              placeholder="Globales Passwort für FTP/SSH"
            >
            <div class="password-instructions">
              <span
                v-if="!$v.form.password.lower || !$v.form.password.upper || !$v.form.password.digit || !$v.form.password.minLength"
                class="text"
              >Mindestens</span>
              <span v-if="!$v.form.password.lower">1 Kleinbuchstabe</span>
              <span v-if="!$v.form.password.upper">1 Großbuchstabe</span>
              <span v-if="!$v.form.password.digit">1 Ziffer</span>
              <span v-if="!$v.form.password.minLength">{{ $v.form.password.$params.minLength.min }} Zeichen</span>
            </div>
          </div>


          <SubmitBtn
            label="Änderungen speichern"
            :submitting="submitting"
            @click.native.prevent="updateBox"
          />
          <a
            class="btn mt-sm"
            :href="`https://${box.server.url}/phpmyadmin`"
            target="_blank"
          >phpMyAdmin</a>
          <SubmitBtn
            v-if="isAdmin"
            class="mt-sm"
            label="Box löschen"
            :submitting="deleting"
            red
            @click.native.prevent="deleteBox"
          />
        </form>
      </template>
    </Modal>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { required, minLength, helpers } from 'vuelidate/lib/validators'
// Custom Validators
const lower = helpers.regex('lower', /[a-z]+/)
const upper = helpers.regex('upper', /[A-Z]+/)
const digit = helpers.regex('digit', /[0-9]+/)

export default {
  name: 'Box',

  components: {
    SubmitBtn: () => import('../components/SubmitBtn'),
    Dialog: () => import('../components/Dialog'),
    Quota: () => import('../components/box/Quota'),
    Modal: () => import('../components/Modal'),
    UpgradeBox: () => import('../components/box/UpgradeBox')
  },

  data() {
    console.log('DATA', this)
    console.log('DATA' , this.box)
    console.log('DATA' , this.isAdmin)

    return {
      form: {
        name: '',
        isDefault: '',
        password: '',
        //paymentType: this.box.paymentType
      },
      submitting: false,
      deleting: false
    }
  },

  validations: {
    form: {
      name: {required},
      password: {digit, lower, upper, minLength: minLength(8)},
    },
  },

  computed: {
    ...mapGetters([
      'isAdmin',
      'isCustomerAdmin',
      'box',
      'accountType',
      'id'
    ])
  },

  watch: {
    'box': {
      immediate: true,
      handler(newVal) {
        console.log('newVal', newVal)
        console.log('newVal', this.box)

        if(newVal) {
          this.form.name = this.box.name
          this.form.isDefault = this.box.isDefault

        }
      }
    }
  },

  created(){
    console.log('[DEBUG]', 'THIS', this)
    console.log('[DEBUG]', 'BOX', this.box)

  },

  mounted(){
    console.log('[DEBUG]', 'THIS MOUNTED', this)

    console.log('[DEBUG]', 'BOX MOUNTED', this.box)

  },

  methods: {
    async openModal() {
      this.$refs.modal.open()
    },

    closeModal() {
      this.$refs.modal.close()
    },

    updateBox() {
      this.$v.$touch()
      if(!this.$v.form.name.required) {
        this.$notify.error(`Boxname benötigt`)
      } else if(this.$v.form.password.$error) {
        this.$notify.error(`Ungültiges Passwort`)
      } else {
        const formData = {
          name: this.form.name,
          default: this.form.isDefault
        }
        if(this.form.password) formData.password = this.form.password
        this.submitting = true
        this.$axios.put(`/api/${this.accountType}/${this.id}/box/${this.box.id}`, formData).then(() => {
          this.submitting = false
          this.$eventBus.$emit('getBoxes')
          this.$notify.success('Box aktualisiert')
        }).catch(error => {
          console.log(error.response)
          this.submitting = false
        })
      }
    },
    
    async deleteBox() {
      const confirmation = await this.$refs.dialog.open(`
        <h3 class="mb">Box löschen</h3>
        <p>Soll die Box <strong>${this.box.name}</strong> wirklich gelöscht werden?</p>
      `)
      if (confirmation === 'ok') {
        this.deleting = true
        this.$notify.info('Box wird gelöscht...')
        this.$axios.delete(`/api/${this.accountType}/${this.id}/box/${this.box.id}`).then(async () => {
          await this.$eventBus.$emit('getBoxes')
          this.closeModal()
          this.deleting = false
          this.$notify.success('Box gelöscht')
        }).catch((error) => {
          if (!error.response) {
            setTimeout(async () => {
              await this.$eventBus.$emit('getBoxes')
              this.closeModal()
              this.deleting = false
              this.$notify.success('Box gelöscht')
            }, 200)
          } else {
            console.log(error.response)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-info {
  display: flex;
  flex-wrap: wrap;
  font-weight: $medium;

  li {
    &:not(:last-child) {
      margin-right: $spacer;
    }

    span {
      margin-right: $spacer-xs;
      color: $color-gray;
    }
  }
}
</style>