var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container login" }, [
    _c("div", { staticClass: "grid" }, [
      _c(
        "div",
        {
          staticClass: "col-sm-6 bg",
          style:
            "background: " +
            ((_vm.config && _vm.config.loginBgColor) || "#001c31") +
            ";"
        },
        [
          _c("div", { staticClass: "inner" }, [
            _vm.config && _vm.config.loginIcon
              ? _c("img", {
                  staticClass: "loginIcon",
                  attrs: {
                    src: "/images/" + _vm.config.loginIcon,
                    alt: _vm.config.company + "-logo"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config && _vm.config.loginText
              ? _c("div", { staticClass: "login-text" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.config.loginText) + "\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.config && _vm.config.bottomIcon
              ? _c("img", {
                  staticClass: "bottomIcon",
                  attrs: {
                    src: "/images/" + _vm.config.bottomIcon,
                    alt: _vm.config.company + "-logo"
                  }
                })
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.config && _vm.config.loginBgLeft
            ? _c("img", {
                staticClass: "loginBgLeft",
                attrs: {
                  src: "/images/" + _vm.config.loginBgLeft,
                  alt: _vm.config.company
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.config && _vm.config.loginBgRight
            ? _c("img", {
                staticClass: "loginBgRight",
                attrs: {
                  src: "/images/" + _vm.config.loginBgRight,
                  alt: _vm.config.company
                }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 form" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.login($event)
              }
            }
          },
          [
            _vm.error
              ? _c(
                  "div",
                  { staticClass: "info error mb" },
                  [
                    _vm.error ===
                    "company account not active please activate account with OTP admin activation key or contact support"
                      ? [
                          _c("h3", { staticClass: "mb-sm" }, [
                            _vm._v(
                              "\n              Unternehmens-Account inaktiv\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n              Der dir zugewiesene Unternehmens-Account wurde wurde noch nicht aktiviert. Bei Fragen erreichst du unseren Support unter "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:" + _vm.companyMail,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(_vm.companyMail))]
                            ),
                            _vm._v(", oder per Telefon unter der "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "tel:" + _vm.companyPhone,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(_vm.companyPhone))]
                            )
                          ])
                        ]
                      : _vm.error === "email or password is wrong."
                      ? [_c("p", [_vm._v("E-Mail oder Passwort fehlerhaft.")])]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("h1", { staticClass: "mb-sm" }, [
              _vm._v("\n          Login\n        ")
            ]),
            _vm._v(" "),
            _c(
              "p",
              [
                _vm._v("\n          Du hast keinen Account? "),
                _c("router-link", { attrs: { to: "/register" } }, [
                  _vm._v("\n            Jetzt registrieren\n          ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "fieldset mt-lg",
                class: { "fieldset-error": _vm.$v.form.email.$error }
              },
              [
                _c("label", { attrs: { for: "email" } }, [_vm._v("E-Mail")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.form.email.$model,
                      expression: "$v.form.email.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: {
                    id: "email",
                    type: "email",
                    required: "",
                    autofocus: "",
                    autocomplete: "username",
                    placeholder: "beispiel@email.com"
                  },
                  domProps: { value: _vm.$v.form.email.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.form.email,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "fieldset",
                class: { "fieldset-error": _vm.$v.form.password.$error }
              },
              [
                _c("label", { attrs: { for: "current-password" } }, [
                  _vm._v("Passwort")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.form.password.$model,
                      expression: "$v.form.password.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  ref: "password",
                  attrs: {
                    id: "current-password",
                    type: [_vm.showPassword ? "text" : "password"],
                    autocomplete: "current-password",
                    required: "",
                    placeholder: "Gib dein Passwort ein"
                  },
                  domProps: { value: _vm.$v.form.password.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.form.password,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "toggle-visibility",
                    on: {
                      click: function($event) {
                        _vm.showPassword = !_vm.showPassword
                      }
                    }
                  },
                  [
                    !_vm.showPassword
                      ? _c(
                          "svg",
                          {
                            attrs: {
                              "enable-background": "new 0 0 32 32",
                              viewBox: "0 0 32 32",
                              "xml:space": "preserve",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                id: "XMLID_320_",
                                d:
                                  "M29.946,15.675C27.954,9.888,22.35,6,16,6S4.046,9.888,2.054,15.675c-0.072,0.21-0.072,0.44,0,0.65  C4.046,22.112,9.65,26,16,26s11.954-3.888,13.946-9.675C30.018,16.115,30.018,15.885,29.946,15.675z M16,22c-3.309,0-6-2.691-6-6  s2.691-6,6-6s6,2.691,6,6S19.309,22,16,22z"
                              }
                            })
                          ]
                        )
                      : _c(
                          "svg",
                          {
                            attrs: {
                              "enable-background": "new 0 0 32 32",
                              viewBox: "0 0 32 32",
                              "xml:space": "preserve",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                id: "XMLID_323_",
                                d:
                                  "M20.722,24.964c0.096,0.096,0.057,0.264-0.073,0.306c-7.7,2.466-16.032-1.503-18.594-8.942  c-0.072-0.21-0.072-0.444,0-0.655c0.743-2.157,1.99-4.047,3.588-5.573c0.061-0.058,0.158-0.056,0.217,0.003l4.302,4.302  c0.03,0.03,0.041,0.072,0.031,0.113c-1.116,4.345,2.948,8.395,7.276,7.294c0.049-0.013,0.095-0.004,0.131,0.032  C17.958,22.201,20.045,24.287,20.722,24.964z"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                id: "XMLID_325_",
                                d:
                                  "M24.68,23.266c2.406-1.692,4.281-4.079,5.266-6.941c0.072-0.21,0.072-0.44,0-0.65  C27.954,9.888,22.35,6,16,6c-2.479,0-4.841,0.597-6.921,1.665L3.707,2.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414  l26,26c0.391,0.391,1.023,0.391,1.414,0c0.391-0.391,0.391-1.023,0-1.414L24.68,23.266z M16,10c3.309,0,6,2.691,6,6  c0,1.294-0.416,2.49-1.115,3.471l-8.356-8.356C13.51,10.416,14.706,10,16,10z"
                              }
                            })
                          ]
                        )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("SubmitBtn", {
              staticClass: "mb-sm",
              attrs: { label: "Anmelden", submitting: _vm.submitting }
            }),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "forgot-pw", attrs: { to: "/forgot" } },
              [_vm._v("\n          Passwort vergessen?\n        ")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }