<template>
  <div class="app">
    <!-- <transition
      name="sidebar"
      appear
    > -->
    <Sidebar v-if="!$route.meta.guest" />
    <!-- </transition> -->
    <div class="content">
      <transition
        name="page"
        mode="out-in"
        :css="false"
        @enter="enter"
        @afterEnter="afterEnter"
        @leave="leave"
        @afterLeave="afterLeave"
      >
        <keep-alive include="instances">
          <router-view />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    Sidebar: () => import('./components/Sidebar')
  },

	data() {
		return {
			route: ''
		}
	},

	computed: {
    ...mapGetters([
      'config'
    ])
	},

	watch: {
		'$route': {
      immediate: true,
      handler(to) {
        if (to.meta.guest) {
					this.route ='/public/config'
				} else {
					this.route = '/api/config'
				}
			}
    },

		'route': {
			immediate: true,
			async handler(newVal, oldVal) {
				if (newVal === oldVal) return
				const config = await this.$axios.get(this.route)
				this.setConfig(config.data)
			}
		}
	},

  methods: {
		...mapMutations({
      setConfig: 'setConfig'
    }),

    enter(el, done) {
      this.$gsap.fromTo(el, {x: -6, opacity: 0}, {duration: 0.3, ease:'power1.out', x: 0, opacity: 1, onComplete: done})
    },

    afterEnter(el) {
      el.style.removeProperty('transform')
    },

    leave(el, done) {
      this.$gsap.fromTo(el, {x: 0, opacity: 1}, {duration: 0.3, ease:'power1.out', x: 6, opacity: 0, onComplete: done})
    },

    afterLeave(el) {
      el.style.removeProperty('transform')
    }
  }
}
</script>