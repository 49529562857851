import Vue from 'vue'
import Router from 'vue-router'
// import store from '../store'

import Login from '../views/Login'
import Register from '../views/Register'
import Forgot from '../views/Forgot'
import Reset from '../views/Reset'

import Servers from '../views/Servers'
import Server from '../views/Server'
import Users from '../views/Users'
import User from '../views/User'
import Organisations from '../views/Organisations'
import Organisation from '../views/Organisation'
import Products from '../views/Products'
import Settings from '../views/Settings'

import Dashboard from '../views/Dashboard'
import NewBox from '../views/NewBox'
import Box from '../views/Box'
import Instances from '../views/Instances'
import Domains from '../views/Domains'
import Backups from '../views/Backups'
import Databases from '../views/Databases'
import Cronjobs from '../views/Cronjobs'
import Mail from '../views/Mail'
import Support from '../views/Support'
import Profile from '../views/Profile'

import Bills from '../views/Bills'



Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        guest: true
      }
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: Forgot,
      meta: {
        guest: true
      }
    },
    {
      path: '/reset',
      name: 'reset',
      component: Reset,
      meta: {
        guest: true
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        auth: true
      }
    },
    {
      path: '/boxes/new',
      name: 'boxes-new',
      component: NewBox,
      meta: {
        auth: true
      }
    },
    {
      path: '/box',
      name: 'box',
      component: Box,
      meta: {
        auth: true
      }
    },
    {
      path: '/instances',
      name: 'instances',
      component: Instances,
      meta: {
        auth: true
      }
    },
    {
      path: '/domains',
      name: 'domains',
      component: Domains,
      meta: {
        auth: true
      }
    },
    {
      path: '/databases',
      name: 'databases',
      component: Databases,
      meta: {
        auth: true
      }
    },
    {
      path: '/cronjobs',
      name: 'cronjobs',
      component: Cronjobs,
      meta: {
        auth: true
      }
    },
    {
      path: '/mail',
      name: 'mail',
      component: Mail,
      meta: {
        auth: true
      }
    },
    {
      path: '/backups',
      name: 'backups',
      component: Backups,
      meta: {
        auth: true
      }
    },
    {
      path: '/servers',
      name: 'servers',
      component: Servers,
      meta: {
        auth: true,
        admin: true
      }
    },
    {
      path: '/bills',
      name: 'bills',
      component: Bills,
      meta: {
        auth: true,
        admin: true
      }
    },
    {
      path: '/servers/:id',
      name: 'servers-id',
      props: true,
      component: Server,
      meta: {
        auth: true,
        admin: true
      }
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: {
        auth: true,
        admin: true
      }
    },
    {
      path: '/users/:id',
      name: 'users-id',
      props: true,
      component: User,
      meta: {
        auth: true,
        admin: true,
        customerAdmin: true
      }
    },
    {
      path: '/organisations',
      name: 'organisations',
      component: Organisations,
      meta: {
        auth: true,
        admin: true
      }
    },
    {
      path: '/organisations/:id',
      name: 'organisations-id',
      props: true,
      component: Organisation,
      meta: {
        auth: true,
        admin: true,
        customerAdmin: true
      }
    },
    {
      path: '/products',
      name: 'products',
      component: Products,
      meta: {
        auth: true,
        admin: true
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        auth: true,
        admin: true
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        auth: true
      }
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      meta: {
        auth: true
      }
    },
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '*',
      redirect: '/'
    },
  ]
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.auth)) {
    if(localStorage.getItem('user-token') == null) {
      next('/login')
    } else {
      if(to.matched.some(record => record.meta.admin)) {
        if(localStorage.getItem('role') === 'admin'){
          next()
        }
        else if(to.matched.some(record => record.meta.customerAdmin) && localStorage.getItem('role') === 'customer_admin') {
          next()
        } else {
          next('/boxes/new')
        }
      } else {
        next()
      }
    }
  } else if(to.matched.some(record => record.meta.guest)) {
    if(localStorage.getItem('user-token') == null){
      next()
    }
    else {
      next('/boxes/new')
    }
  } else {
    next()
  }
})

export default router