var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "databases container" },
    [
      _c("div", { staticClass: "page-head mb-lg" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Datenbanken\n      "),
          _vm.dbs && _vm.dbs.length && _vm.box
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.dbs.length) +
                    "/" +
                    _vm._s(_vm.box.product.mysqlDatabases)
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.accountType === "user" ||
        (_vm.accountType === "organisation" && _vm.isCustomerAdmin) ||
        _vm.isAdmin
          ? _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: {
                    disabled: _vm.dbs.length >= _vm.box.product.mysqlDatabases
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.add($event)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "plus" }, [_vm._v("+")]),
                  _vm._v(" Hinzufügen\n      ")
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search mb-lg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchQuery,
              expression: "searchQuery"
            }
          ],
          attrs: { type: "text", placeholder: "Datenbanken filtern" },
          domProps: { value: _vm.searchQuery },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchQuery = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.searchQuery
          ? _c(
              "a",
              {
                staticClass: "icon clear",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.clearFilter($event)
                  }
                }
              },
              [_c("span"), _c("span")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in", appear: "" } },
        [
          _vm.loading && !_vm.dbs.length
            ? _c("div", { key: "loading", staticClass: "skeleton" }, [
                _vm._v("\n      Datenbanken werden geladen\n    ")
              ])
            : _vm.dbs.length
            ? _c(
                "div",
                { key: "dbtable", staticClass: "databases-table table" },
                [
                  _c(
                    "div",
                    {
                      key: "head",
                      staticClass: "row head",
                      class: _vm.currentSortDir
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.sort("name")
                            }
                          }
                        },
                        [
                          _vm._v("\n          Name\n          "),
                          _c("span", { staticClass: "icon sort" }, [
                            _c("svg", { attrs: { viewBox: "0 0 20 10" } }, [
                              _c("path", { attrs: { d: "M1,9 L10,1 L19,9" } })
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.sort("dbUser")
                            }
                          }
                        },
                        [
                          _vm._v("\n          User\n          "),
                          _c("span", { staticClass: "icon sort" }, [
                            _c("svg", { attrs: { viewBox: "0 0 20 10" } }, [
                              _c("path", { attrs: { d: "M1,9 L10,1 L19,9" } })
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.sortedDbs, function(db) {
                    return _c("Database", {
                      key: db.id,
                      attrs: { db: db, prefix: _vm.dbPrefix },
                      on: { update: _vm.update }
                    })
                  }),
                  _vm._v(" "),
                  _vm.sortedDbs < 1
                    ? _c(
                        "div",
                        { key: "no-results", staticClass: "row no-results" },
                        [
                          _c("div", { staticClass: "cell" }, [
                            _vm._v(
                              "\n          Keine Datenbanken gefunden\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _c(
                "div",
                { key: "noresults", staticClass: "info" },
                [
                  _vm.isAdmin || _vm.isCustomerAdmin
                    ? [
                        _vm._v(
                          '\n        Es wurden noch keine Datenbanken für diese Box angelegt. Klicke auf "Hinzufügen", um eine erste Datenbanken zu erstellen.\n      '
                        )
                      ]
                    : [
                        _vm._v(
                          "\n        Es wurden noch keine Datenbanken für diese Box angelegt.\n      "
                        )
                      ]
                ],
                2
              )
        ]
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                !_vm.currentDb
                  ? _c("h2", { staticClass: "mb" }, [
                      _vm._v("\n        Datenbank hinzufügen\n      ")
                    ])
                  : _c("h2", { staticClass: "mb" }, [
                      _vm._v("\n        Datenbank bearbeiten\n      ")
                    ]),
                _vm._v(" "),
                _c(
                  "form",
                  [
                    !_vm.currentDb
                      ? _c(
                          "div",
                          {
                            staticClass: "fieldset",
                            class: { "fieldset-error": _vm.$v.form.name.$error }
                          },
                          [
                            _c("label", { attrs: { for: "name" } }, [
                              _vm._v("Name")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.form.name.$model,
                                  expression: "$v.form.name.$model"
                                }
                              ],
                              attrs: {
                                type: "text",
                                name: "name",
                                placeholder: "Name"
                              },
                              domProps: { value: _vm.$v.form.name.$model },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.form.name,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset",
                        class: { "fieldset-error": _vm.$v.form.dbUser.$error }
                      },
                      [
                        _c("label", { attrs: { for: "user" } }, [
                          _vm._v("\n            User\n            "),
                          _vm.charsLeft(_vm.user.id, _vm.box.id) > 0
                            ? _c("span", { staticClass: "counter" }, [
                                _vm._v(
                                  "\n              (Noch " +
                                    _vm._s(
                                      _vm.charsLeft(_vm.user.id, _vm.box.id)
                                    ) +
                                    " Zeichen)\n            "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("span", { ref: "prefix", staticClass: "prefix" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.prefix(_vm.user.id, _vm.box.id)) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.form.dbUser.$model,
                              expression: "$v.form.dbUser.$model"
                            }
                          ],
                          staticClass: "has-prefix",
                          attrs: {
                            maxlength:
                              16 -
                              ("U" + _vm.user.id + "B" + _vm.box.id + "_")
                                .length,
                            type: "text",
                            name: "user",
                            placeholder: "Name"
                          },
                          domProps: { value: _vm.$v.form.dbUser.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.dbUser,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset password",
                        class: { "fieldset-error": _vm.$v.form.password.$error }
                      },
                      [
                        _c("label", { attrs: { for: "password" } }, [
                          _vm._v("Passwort")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.form.password.$model,
                              expression: "$v.form.password.$model",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "password",
                            autocomplete: "new-password",
                            name: "password",
                            placeholder: "Passwort"
                          },
                          domProps: { value: _vm.$v.form.password.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.password,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "password-instructions" }, [
                          !_vm.$v.form.password.lower ||
                          !_vm.$v.form.password.upper ||
                          !_vm.$v.form.password.digit ||
                          !_vm.$v.form.password.minLength
                            ? _c("span", { staticClass: "text" }, [
                                _vm._v("Mindestens")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.lower
                            ? _c("span", [_vm._v("1 Kleinbuchstabe")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.upper
                            ? _c("span", [_vm._v("1 Großbuchstabe")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.digit
                            ? _c("span", [_vm._v("1 Ziffer")])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.form.password.minLength
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$v.form.password.$params.minLength.min
                                  ) + " Zeichen"
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fieldset",
                        class: {
                          "fieldset-error": _vm.$v.form.repeatPassword.$error
                        }
                      },
                      [
                        _c("label", { attrs: { for: "repeat-password" } }, [
                          _vm._v("Passwort wiederholen")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.form.repeatPassword.$model,
                              expression: "$v.form.repeatPassword.$model"
                            }
                          ],
                          attrs: {
                            type: "password",
                            autocomplete: "new-password",
                            name: "repeat-password",
                            placeholder: "Passwort wiederholen"
                          },
                          domProps: {
                            value: _vm.$v.form.repeatPassword.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.form.repeatPassword,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.currentDb
                      ? _c("SubmitBtn", {
                          attrs: {
                            label: "Datenbank anlegen",
                            submitting: _vm.submitting,
                            disabled: _vm.$v.form.$invalid
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addDb($event)
                            }
                          }
                        })
                      : _c("SubmitBtn", {
                          attrs: {
                            label: "Datenbank aktualisieren",
                            submitting: _vm.submitting,
                            disabled: _vm.$v.form.$invalid
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.updateDb($event)
                            }
                          }
                        }),
                    _vm._v(" "),
                    _vm.currentDb
                      ? _c(
                          "a",
                          {
                            staticClass: "btn mt-sm",
                            attrs: {
                              href:
                                "https://" +
                                _vm.currentDb.host.url +
                                "/phpmyadmin",
                              target: "_blank"
                            }
                          },
                          [_vm._v("\n          phpMyAdmin\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentDb
                      ? _c("SubmitBtn", {
                          staticClass: "mt-sm",
                          attrs: {
                            label: "Datenbank löschen",
                            submitting: _vm.deleting,
                            red: ""
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.deleteDb($event)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Dialog", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }