<template>
  <div class="products container">
    <div class="page-head mb-lg">
      <h1 class="title">
        Tarife
        <span v-if="products.length">{{ products.length }}</span>
      </h1>
      <div class="actions">
        <button
          class="btn"
          @click.prevent="openModal"
        >
          <span class="plus">+</span> Hinzufügen
        </button>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="loading"
        class="skeleton"
      >
        Tarife werden geladen
      </div>
      <ProductList
        v-else
        :products="products"
        @fetch="fetch"
      />
    </transition>

    <Modal ref="modal">
      <template #content>
        <h2 class="mb">
          Tarif hinzufügen
        </h2>
        <form @submit.prevent="addProduct">
          <div class="grid">
            <div class="fieldset col-12">
              <label for="name">Name</label>
              <input
                v-model="form.name"
                type="text"
                name="name"
                placeholder="Name des Tarifs"
                required
              >
            </div>
            <div class="fieldset col-6">
              <label for="mailboxes">Mailkonten</label>
              <input
                v-model="form.mailboxes"
                type="number"
                name="mailboxes"
                placeholder="Anzahl Mailkonten"
              >
            </div>
            <div class="fieldset col-6">
              <label for="databases">Datenbanken</label>
              <input
                v-model="form.databases"
                type="number"
                name="databases"
                placeholder="Anzahl Datenbanken"
              >
            </div>
            <div class="fieldset col-6">
              <label for="cronjobs">Cronjobs</label>
              <input
                v-model="form.cronjobs"
                type="number"
                name="cronjobs"
                placeholder="Anzahl Cronjobs"
              >
            </div>
            <div class="fieldset col-6">
              <label for="backups">Backups</label>
              <input
                v-model="form.backups"
                type="number"
                name="backups"
                placeholder="Anzahl Backups"
              >
            </div>
            <div class="fieldset col-6">
              <label for="space">Diskspace</label>
              <input
                v-model="form.space"
                type="number"
                name="space"
                placeholder="Diskspace in GB"
              >
            </div>
            <div class="fieldset col-6">
              <label for="price">Preis</label>
              <input
                v-model="form.price"
                type="text"
                name="price"
                placeholder="Monatlicher Netto Preis"
              >
            </div>
            <div class="fieldset col-12">
              <label>SSH</label>
              <label class="switch">
                <span>Aktiviert</span>
                <input
                  v-model="form.ssh"
                  type="checkbox"
                >
                <span class="slider" />
              </label>
            </div>
            <div class="fieldset col-12">
              <label for="ns1">Nameserver 1</label>
              <input
                v-model="form.ns1"
                type="text"
                name="ns1"
                placeholder="ns1.domain.tld"
              >
            </div>
            <div class="fieldset col-12">
              <label for="ns2">Nameserver 2</label>
              <input
                v-model="form.ns2"
                type="text"
                name="ns2"
                placeholder="ns2.domain.tld"
              >
            </div>
          </div>
          <SubmitBtn
            class="mt"
            label="Tarif anlegen"
            :submitting="submitting"
          />
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'Products',
  components: {
    Modal: () => import('../components/Modal'),
    ProductList: () => import('../components/ProductList'),
    SubmitBtn: () => import('../components/SubmitBtn'),
  },

  data() {
    return {
      loading: false,
      products: [],
      form: {
        name: '',
        ssh: false,
        mailboxes: '',
        databases: '',
        cronjobs: '',
        space: '',
        backups: '',
        price: '',
        ns1: '',
        ns2: ''
      },
      submitting: false
    }
  },

  async created() {
    this.loading = true
    this.fetch()
  },

  methods: {
    openModal() {
      this.$refs.modal.open()
    },

    closeModal() {
      this.$refs.modal.close()
    },

    async fetch() {
      const products = await this.$axios.get('/api/products')
      this.products = products.data
      this.loading = false
    },

    async addProduct() {
      this.submitting = true
      this.$axios.post('/api/product', {
        name: this.form.name,
        ssh: this.form.ssh,
        mailboxes: parseInt(this.form.mailboxes),
        databases: parseInt(this.form.databases),
        cronjobs: parseInt(this.form.cronjobs),
        space: (parseInt(this.form.space) * 1000),
        backups: parseInt(this.form.backups),
        price: parseFloat(this.form.price.replace(',', '.')),
        ns1: this.form.ns1,
        ns2: this.form.ns2
      }).then(async () => {
        this.closeModal()
        await this.fetch()
        this.submitting = false
        this.$notify.success('Tarif erstellt')
      }).catch((error) => {
        this.submitting = false
        console.log(error.response)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .product

    {
        cursor: pointer;
    }
    }
</style>